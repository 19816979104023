import styled, { css } from 'styled-components';
import { MediaQueryUtil } from '@axiom/ui';

export const OuterSpacer = styled.div<{ width: string }>`
  ${({ width }) => ({ width })};
  ${MediaQueryUtil.largeScreenOnly(css`
    margin: 64px auto;
  `)}
  ${MediaQueryUtil.tabletOnly(css`
    width: 66%;
    margin: 48px auto;
  `)}
  ${MediaQueryUtil.mobileOnly(css`
    width: calc(100% - 48px);
    margin: 24px;
  `)}
`;
