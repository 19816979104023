import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const OtherActivityIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 88 88"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <circle fill="#75b73b" cx="39" cy="39" r="39" />
    <path
      d="M-8555.8-12529.86V-12536h-.2a6.007,6.007,0,0,1-6-6v-15a6.007,6.007,0,0,1,6-6h29a6.006,6.006,0,0,1,6,6v15a6.006,6.006,0,0,1-6,6h-23.067l-5.732,6.137Zm23.3-22.14a2.5,2.5,0,0,0-2.5,2.5,2.5,2.5,0,0,0,2.5,2.5,2.5,2.5,0,0,0,2.5-2.5A2.5,2.5,0,0,0-8532.5-12552Zm-9,0a2.5,2.5,0,0,0-2.5,2.5,2.5,2.5,0,0,0,2.5,2.5,2.5,2.5,0,0,0,2.5-2.5A2.5,2.5,0,0,0-8541.5-12552Zm-9,0a2.5,2.5,0,0,0-2.5,2.5,2.5,2.5,0,0,0,2.5,2.5,2.5,2.5,0,0,0,2.5-2.5A2.5,2.5,0,0,0-8550.5-12552Z"
      fill="#fff"
      transform="translate(8581 12588)"
    />
  </svg>
);

OtherActivityIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

OtherActivityIcon.defaultProps = {
  alt: null,
  className: null,
  height: 88,
  name: 'OTHER_ACTIVITY_ICON',
  width: 88,
};
