import { Enum } from 'enumify';

class YearsOfExperience extends Enum {}
YearsOfExperience.initEnum({
  zeroToThree: {
    v: '0 - 3',
    f: {
      filters: {
        yearsOfExperience: { start: 0, end: 3 },
      },
    },
  },
  fourToSeven: {
    v: '4 - 7',
    f: {
      filters: {
        yearsOfExperience: { start: 4, end: 7 },
      },
    },
  },
  eightToEleven: {
    v: '8 - 11',
    f: {
      filters: {
        yearsOfExperience: { start: 8, end: 11 },
      },
    },
  },
  twelveToFifteen: {
    v: '12 - 15',
    f: {
      filters: {
        yearsOfExperience: { start: 12, end: 15 },
      },
    },
  },
  sixteenToNineteen: {
    v: '16 - 19',
    f: {
      filters: {
        yearsOfExperience: { start: 16, end: 19 },
      },
    },
  },
  twentyUp: {
    v: '20+',
    f: {
      filters: {
        yearsOfExperience: { start: 20 },
      },
    },
  },
});
YearsOfExperience.v = v => YearsOfExperience.enumValues.find(x => x.v === v);
YearsOfExperience.f = v => YearsOfExperience.enumValues.find(x => x.v === v);
YearsOfExperience.o = v =>
  YearsOfExperience.enumValues.find(x => x.ordinal === v);

export const YearsOfExperienceRangeConst = {
  YearsOfExperience,
};
