import styled from 'styled-components';

export const SectionWrapper = styled.label`
  display: block;
  margin-bottom: ${props => (props.margin ? '20px' : '0px')} !important;
`;

export const DivLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DivLabelRightWrapper = styled.div``;
export const DivFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const DivFooterLeftWrapper = styled.div``;
export const DivFooterRightWrapper = styled.div``;
