import has from 'lodash/has';
import get from 'lodash/get';
import set from 'lodash/set';

export const GenericDto = class GenericDto {
  constructor(body = {}, allowedProps) {
    this.data = {};

    allowedProps.forEach(ap => {
      if (has(body, ap)) {
        if (body[ap] === undefined) {
          set(this.data, ap, get(body, ap));
        } else {
          this.data[ap] = body[ap];
        }
      }
    });
  }

  export() {
    return this.data;
  }
};
