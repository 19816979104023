import styled from 'styled-components';
import { Input as RSInput } from 'reactstrap';

import { PALETTE } from '../../themes/constants';

export const FFInputErrorWrapper = styled.div`
  display: flex;
`;

export const FFInputStyled = styled(RSInput)`
  height: 36px;
  font-size: 1rem;
  padding: 0 10px;
  color: #000000;

  &:focus {
    border: 1px solid #000000;
    box-shadow: none;
    color: #000000;
  }

  /* Remove red X icon from Bootstrap */
  &.form-control.is-invalid,
  .was-validated &.form-control:invalid {
    background-image: none;
    padding-right: 17px;
  }

  &.form-control.is-invalid:focus {
    box-shadow: none;
  }

  &.is-invalid {
    border-color: ${PALETTE.theme.danger};
  }
`;
