import React from 'react';
import styled from 'styled-components';
import { Grid as SGrid } from 'semantic-ui-react';

import {
  ResponsiveConfig,
  ViewportName,
} from '../../../configs/responsive-config';

type ElementType = 'div' | 'span';
type ColumnsOption = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'equal';
type TextAlignOption = 'left' | 'center' | 'right' | 'justified';
type VerticalAlignOption = 'top' | 'middle' | 'bottom';
type GridPropReversed = string | ViewportName;

export interface GridProps {
  as?: ElementType;
  centered?: boolean;
  children?: React.ReactNode;
  className?: string;
  columns?: ColumnsOption;
  name?: string;
  stackable?: boolean;
  stretched?: boolean;
  textAlign?: TextAlignOption;
  verticalAlign?: VerticalAlignOption;
  reversed?: GridPropReversed;
}

interface StyledGridProps {
  stretched?: boolean;
  className?: string;
  dataTest?: string;
  name?: string;
}

const StyledGrid = styled.div<StyledGridProps>`
  ${({ stretched }) =>
    stretched &&
    `
      height: 100%;

      > .ui.grid {
        height: 100%;
      }
    `}
`;
export const Grid = ({
  as = 'div',
  centered = false,
  children,
  className,
  columns,
  name = '',
  stackable,
  stretched,
  textAlign,
  verticalAlign,
  reversed,
}: GridProps) => {
  /**
   * Semantic Reversed prop doesn't take desktop and large screen like it does for
   * other breakpoint props, so we need to translate to computer
   */
  const reversedPropValue = reversed
    ? ResponsiveConfig.convertOnlyToSemanticOnly(reversed)
        .replace('large screen', 'computer')
        .replace('widescreen', 'computer')
        .split(' ')
        .filter(Boolean)
        .map(breakpoint => {
          if (reversed.includes('vertically')) {
            return `${breakpoint} vertically`;
          }
          return breakpoint;
        })
        .join(' ')
        .trim()
    : undefined;

  return (
    <StyledGrid
      className={className}
      stretched={stretched}
      data-test={name || 'grid_container'}
    >
      <SGrid
        as={as}
        centered={centered}
        className={className}
        columns={columns}
        stackable={stackable}
        stretched={stretched}
        textAlign={textAlign}
        verticalAlign={verticalAlign}
        reversed={reversedPropValue}
      >
        {children}
      </SGrid>
    </StyledGrid>
  );
};
