import { groupBy } from 'lodash';

import { StringUtil } from './string-util';

const LanguageConst = {
  LANGUAGE_PROFICIENCIES: {
    basic: 'Conversational',
    proficient: 'Business Proficient',
    fluent: 'Native or Bilingual',
  },
  LANGUAGE_SKILLS: {
    speaking: 'Speaking',
    reading: 'Reading',
    writing: 'Writing',
  },
  LANGUAGE_CEFR_LEVELS: {
    A1: 'A1',
    A2: 'A2',
    B1: 'B1',
    B2: 'B2',
    C1: 'C1',
    C2: 'C2',
  },
};

export const LanguageUtil = {
  groupByCategories(languages) {
    const groupedByName = groupBy(languages, lang => lang.name);

    Object.keys(groupedByName).forEach(langName => {
      groupedByName[langName] = groupBy(
        groupedByName[langName],
        lang =>
          `n:${lang.name}:p:${lang.languageProficiency}:c:${lang.languageCEFR}`
      );
    });

    return groupedByName;
  },
  buildLanguageDisplayBars(languages) {
    return [
      StringUtil.capitalizeString(
        LanguageConst.LANGUAGE_PROFICIENCIES[languages[0].languageProficiency]
      ),
      languages
        .map(l => StringUtil.capitalizeString(l.languageSkill))
        .join(', '),
      languages[0].languageCEFR,
    ].reduce((resultStr, currentValue) =>
      currentValue ? `${resultStr} | ${currentValue}` : resultStr
    );
  },
};
