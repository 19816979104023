import React from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { AxiomLogo, Button, Label } from '@axiom/ui';
import { useNavigate, useLocation } from 'react-router-dom';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { Welcome } from '../Welcome/Welcome';
import { FormLoginStore } from '../../stores/form-login-store';
import { FFInput } from '../FFInput/FFInput';
import { WindowUtil } from '../../utils/window-util';

import {
  LFooterButtonsWrapper,
  LTextBold,
  LAxiomLogoWrapper,
  LPanelWrapperLeft,
  LPanelWrapperRight,
  LWrapper,
  LEEmailWrapper,
  LEForm,
} from './LoginStyles';

const LoginEmail = ({ emailFormState }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const relayState = encodeURIComponent(location.search)
    ? qs.parse(WindowUtil.getFullRedirect(location), {
        ignoreQueryPrefix: true,
      }).RelayState
    : '';

  return (
    <StoreStateTreatment
      storeState={emailFormState}
      renderLoadedView={({ data }) => (
        <LWrapper>
          <LPanelWrapperLeft className="section email">
            <LAxiomLogoWrapper>
              <AxiomLogo width="240px" height="104px" />
            </LAxiomLogoWrapper>
            <Form
              onSubmit={formData =>
                FormLoginStore.submit(formData).then(() => {
                  if (relayState) {
                    navigate(
                      `/login?RelayState=${encodeURIComponent(relayState)}`
                    );
                  } else {
                    navigate('/login');
                  }
                })
              }
              initialValues={data}
              validate={formData => FormLoginStore.validate(formData)}
              render={({ handleSubmit }) => (
                <LEForm onSubmit={handleSubmit} data-test="LOGIN_FORM">
                  <LTextBold>Sign in to Axiom below.</LTextBold>
                  <LEEmailWrapper>
                    <Label>Email Address</Label>
                    <Field name="email" component={FFInput} data-test="EMAIL" />
                  </LEEmailWrapper>
                  <LFooterButtonsWrapper>
                    <Button
                      type="submit"
                      name="SUBMIT"
                      className="gtm-login-sign-in"
                    >
                      Continue
                    </Button>
                  </LFooterButtonsWrapper>
                </LEForm>
              )}
            />
          </LPanelWrapperLeft>
          <LPanelWrapperRight className="section">
            <Welcome />
          </LPanelWrapperRight>
        </LWrapper>
      )}
    />
  );
};

LoginEmail.propTypes = {
  emailFormState: FormLoginStore.getStateShape().isRequired,
};

export default connect(state => ({
  emailFormState: FormLoginStore.select(state),
}))(LoginEmail);
