import React from 'react';
import styled from 'styled-components';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

type ElementType = 'p' | 'div' | 'span';

export interface CondensedMediumProps {
  as?: ElementType;
  children?: React.ReactNode;
  className?: string;
  name?: string;
  maxLines?: number;
}

interface DivProps {
  as: ElementType;
  children: React.ReactNode;
  dataTest: string;
}

const Div = ({ as, children, dataTest }: DivProps) =>
  React.createElement(as, { dataTest }, children);

const StyledDiv = styled(Div)``;

export const CondensedMedium = ({
  as = 'div',
  children = null,
  className = '',
  name = null,
  maxLines = null,
}: CondensedMediumProps) => (
  <StyledDiv
    as={as}
    className={AttrsHelper.formatClassname(
      'condensed-medium',
      maxLines && `max-lines-${maxLines}`,
      className
    )}
    data-test={name}
  >
    {children}
  </StyledDiv>
);
