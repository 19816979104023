import React from 'react';
import { Spinner } from 'reactstrap';

import {
  LoadingCurtainCoverWrapper,
  LoadingCurtainSpinnerWrapper,
} from './LoadingCurtainStyles';

export const LoadingCurtain = () => (
  <LoadingCurtainCoverWrapper>
    <LoadingCurtainSpinnerWrapper>
      <Spinner color="primary" />
    </LoadingCurtainSpinnerWrapper>
  </LoadingCurtainCoverWrapper>
);

LoadingCurtain.propTypes = {};
