import moment from 'moment';
import { PositionsConst } from '@axiom/const';

import { DateUtils } from './date';

const { getTodayDateString } = DateUtils;
const { EndDateStatuses } = PositionsConst;

// position is "ending soon" if it is in progress and end date is confirmed & in the next 30 days
export const isEndingSoon = (
  { startDate, endDate, endDateStatus } = {},
  {
    today = getTodayDateString(),
    thirtyDaysFromToday = moment.utc().add(30, 'days').format('YYYY-MM-DD'),
  } = {}
) => {
  if (!startDate || !endDate) {
    return false;
  }
  return (
    endDateStatus === EndDateStatuses.Confirmed &&
    moment(startDate).isSameOrBefore(today, 'day') &&
    moment(endDate).isSameOrAfter(today, 'day') &&
    moment(endDate).isSameOrBefore(thirtyDaysFromToday, 'day')
  );
};
