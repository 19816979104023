import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const InPersonIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 88 88"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <circle fill="#75b73b" cx="39" cy="39" r="39" />
    <g fill="#fff">
      <path d="M48.95 26.081c5.451.058 6.734 3.681 6.734 8.221s-3.015 8.221-6.734 8.221-6.734-3.684-6.734-8.224 1.283-8.279 6.734-8.218zM45.305 43.161s-5.2.938-6.955 2.722a5.821 5.821 0 00-1.673 3.965v6.333a23.159 23.159 0 0012.881 3.307c7.737-.232 12.072-3.541 12.072-3.541v-6.1a7.834 7.834 0 00-2.023-3.965c-1.668-1.634-7-2.722-7-2.722s-.974 1.357-3.732 1.357-3.57-1.356-3.57-1.356z" />
      <path d="M32.239 18.004c6.769.072 8.361 4.57 8.361 10.207s-3.743 10.205-8.361 10.205-8.361-4.57-8.361-10.212 1.593-10.275 8.361-10.2z" />
      <path d="M32.085 60.498a31.375 31.375 0 01-11.318-2.129c-2.71-1.06-4.268-2.123-4.333-2.167l-.433-.299v-8.375c-.007-.322-.001-3.234 2.365-5.637 1.022-1.04 2.893-1.974 5.56-2.778 1.918-.577 3.543-.873 3.61-.885l.72-.13.31.589c.098.133.968 1.21 3.586 1.21 2.708 0 3.726-1.154 3.827-1.275l.367-.558.64.168c.07.014 1.725.355 3.663.954 2.691.833 4.55 1.738 5.523 2.691 2.284 2.234 2.781 5.355 2.801 5.487l.011.075v8.144l-.393.3c-.06.045-1.478 1.117-4.113 2.218-2.401 1.002-6.337 2.228-11.453 2.383-.312.01-.628.014-.94.014z" />
    </g>
    <path
      fill="#75b73b"
      d="M32.085 59.498c.3 0 .603-.005.91-.014 9.607-.29 14.99-4.396 14.99-4.396v-7.573s-.441-2.897-2.512-4.923c-2.071-2.028-8.687-3.38-8.687-3.38s-1.21 1.686-4.634 1.686c-3.423 0-4.438-1.686-4.438-1.686s-6.457 1.165-8.635 3.38C16.899 44.806 17 47.515 17 47.515v7.863s5.987 4.12 15.084 4.12m0 2c-9.608 0-15.953-4.29-16.218-4.473l-.866-.595v-8.888c-.012-.545.039-3.698 2.652-6.353 1.158-1.176 3.115-2.168 5.984-3.033 1.973-.593 3.652-.9 3.722-.912L28.717 37l.665 1.106c.12.13.825.793 2.77.793 2.1 0 2.928-.78 3.047-.905l.718-1 1.27.259c.07.015 1.773.365 3.757.979 2.885.892 4.825 1.851 5.928 2.932 2.477 2.423 3.034 5.687 3.09 6.05l.022.15v8.715l-.786.6c-.24.183-6.01 4.5-16.142 4.805-.323.01-.649.015-.971.015z"
    />
  </svg>
);

InPersonIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

InPersonIcon.defaultProps = {
  alt: null,
  className: null,
  height: 88,
  name: 'IN_PERSON_ICON',
  width: 88,
};
