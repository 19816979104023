import React from 'react';
import styled from 'styled-components';
import { Dimmer, Loader } from 'semantic-ui-react';

import { AxiomLogo } from '../AxiomLogo/AxiomLogo';
import { Gutter } from '../../layout/Gutter/Gutter';

export const LoaderLogoRoot = styled.div`
  min-height: 200px;
  .loader-container {
    position: relative;
  }
`;

export const LoaderLogo = () => (
  <LoaderLogoRoot>
    <Dimmer active>
      <Gutter bottom="2rem" horizontal="auto">
        <AxiomLogo />
      </Gutter>
      <div className="loader-container">
        <Loader indeterminate />
      </div>
    </Dimmer>
  </LoaderLogoRoot>
);
