import React from 'react';

import { CertificationShape } from '../../../models/certification-model';
import { Header4 } from '../../content/Header4/Header4';
import { Text } from '../../content/Text/Text';
import { DataTest } from '../../general/DataTest/DataTest';

export const Certification = ({ certification }) => (
  <DataTest value="certification_item">
    <div style={{ breakInside: 'avoid' }}>
      <Header4 name="awardName">{certification.award}</Header4>
      <Text name="awardDetails">
        {certification.institution}, {certification.year}
      </Text>
    </div>
  </DataTest>
);

Certification.defaultProps = {};
Certification.propTypes = {
  certification: CertificationShape.isRequired,
};
