import styled from 'styled-components';

export const RSCardWrapper = styled.div`
  border-radius: 0;
  border-top-left-radius: 1.5rem;
  background-color: #fff;
  margin-bottom: 0;
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 40%);

  position: relative;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
`;
export const RSCardTitleWrapper = styled.div`
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  border-radius: 1.5rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  font-weight: 700;

  line-height: 1.875rem;
  padding: 1.75rem 2.5rem 1.25rem 2.5rem;
  color: #fff;
  background-color: #1c222e;
`;
export const RSCardBodyWrapper = styled.div`
  flex: 1 1 auto;
  border: none !important;
  border-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 40px;
`;
