import { css } from 'styled-components';

import { ResponsiveConfig } from '../configs/responsive-config';

export const MediaQueryUtil = {
  mobileOnly(cssStyles) {
    return css`
      @media (max-width: ${ResponsiveConfig.breakpoints.tablet - 1}px) {
        ${cssStyles}
      }
    `;
  },
  tabletOnly(cssStyles) {
    return css`
      @media (min-width: ${ResponsiveConfig.breakpoints
          .tablet}px) and (max-width: ${ResponsiveConfig.breakpoints.desktop -
        1}px) {
        ${cssStyles}
      }
    `;
  },
  desktopOnly(cssStyles) {
    return css`
      @media (min-width: ${ResponsiveConfig.breakpoints
          .desktop}px) and (max-width: ${ResponsiveConfig.breakpoints
          .widescreen - 1}px) {
        ${cssStyles}
      }
    `;
  },
  widescreenOnly(cssStyles) {
    return css`
      @media (min-width: ${ResponsiveConfig.breakpoints.widescreen}px) {
        ${cssStyles}
      }
    `;
  },
  largeScreenOnly(cssStyles) {
    return css`
      @media (min-width: ${ResponsiveConfig.breakpoints.desktop}px) {
        ${cssStyles}
      }
    `;
  },
  smallScreenOnly(cssStyles) {
    return css`
      @media (max-width: ${ResponsiveConfig.breakpoints.desktop - 1}px) {
        ${cssStyles}
      }
    `;
  },
};
