import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import {
  Button,
  Gutter,
  KeyIcon,
  Label,
  MovingMailIcon,
  Text,
} from '@axiom/ui';
import { useNavigate, useLocation } from 'react-router-dom';

import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import CommonCard from '../CommonCard/CommonCard';
import CommonCardConfirmation from '../CommonCardConfirmation/CommonCardConfirmation';
import { RequestPasswordStore } from '../../stores/request-password-store';
import { formatDataTestId } from '../../utils/dataTest';

import { RPWrapper, RPText, RPButtonWrapper } from './RequestPasswordStyles';

const RequestPassword = ({ requestPasswordState }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email: userEmail, RelayState } = encodeURIComponent(location.search)
    ? qs.parse(location.search, { ignoreQueryPrefix: true })
    : { email: '', RelayState: '' };

  const [isSuccess, setSuccessful] = useState(false);

  useEffect(() => {
    RequestPasswordStore.load(userEmail);
  }, [userEmail]);

  return (
    <RPWrapper data-test={formatDataTestId('REQUEST_PASSWORD')}>
      {isSuccess ? (
        <CommonCardConfirmation
          title="Check your mail"
          icon={<MovingMailIcon />}
        >
          We've sent a link to set your Axiom password. If you don't receive
          that email within an hour, please check your spam folder before
          reaching out to{' '}
          <a href="mailto:client.answers@axiomlaw.com">
            Axiom Answers for Clients
          </a>{' '}
          or <a href="mailto:answers@axiomlaw.com">Axiom Answers for Talent</a>{' '}
          for assistance.
        </CommonCardConfirmation>
      ) : (
        <StoreStateTreatment
          storeState={requestPasswordState}
          renderLoadedView={({ data }) => (
            <CommonCard
              title="Set or reset your password"
              footer={
                <div>
                  <Gutter bottom="10px">
                    <Text size="large">
                      If your company isn't working with Axiom,{' '}
                      <a
                        href="https://www.axiomlaw.com/contact-us"
                        data-test={formatDataTestId('CONTACT_US')}
                      >
                        Contact us
                      </a>
                      .
                    </Text>
                  </Gutter>
                  <Text size="large">
                    If you haven't applied to Axiom, head over to{' '}
                    <a
                      href="https://www.axiomlaw.com/careers/lawyers"
                      data-test={formatDataTestId('CAREERS')}
                    >
                      Careers
                    </a>
                    .
                  </Text>
                </div>
              }
              icon={<KeyIcon />}
              goBackText="SIGN-IN PAGE"
              handleGoBack={() =>
                RequestPasswordStore.cancel(data.email).then(() => {
                  if (RelayState) {
                    navigate(`/login?RelayState=${RelayState}`);
                  } else {
                    navigate('/login');
                  }
                })
              }
            >
              <RPText>
                If we can locate your Axiom account we'll email instructions you
                can use to set or reset your password.
                <br />
                <br />
                Didn't mean to do this? Go back to the sign-in page.
              </RPText>

              <Gutter top="32px" bottom="18px">
                <Label>Email Address</Label>
                <Gutter left="17px" top="8px">
                  {data.email}
                </Gutter>
              </Gutter>
              <RPButtonWrapper>
                <Button
                  type="submit"
                  name="SEND_INSTRUCTIONS"
                  onClick={() => {
                    RequestPasswordStore.submit(data.email).then(() => {
                      setSuccessful(true);
                    });
                  }}
                >
                  Send Instructions
                </Button>
              </RPButtonWrapper>
            </CommonCard>
          )}
        />
      )}
    </RPWrapper>
  );
};

RequestPassword.propTypes = {
  requestPasswordState: RequestPasswordStore.getStateShape().isRequired,
};

export default connect(state => ({
  requestPasswordState: RequestPasswordStore.select(state),
}))(RequestPassword);
