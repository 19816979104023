/* eslint-disable no-empty */
/* eslint-disable no-console */

import atob from 'atob';

const CookieHandler = {
  getAll() {
    let str;
    try {
      str = document.cookie;
    } catch (err) {
      if (
        typeof console !== 'undefined' &&
        // Super old code that needs a refactor

        typeof console.error === 'function'
      ) {
        // Super old code that needs a refactor

        console.error(err.stack || err);
      }
      return {};
    }
    return CookieHandler.parse(str);
  },
  parse(str) {
    const obj = {};
    const pairs = str.split(/ *; */);
    let pair;
    if (pairs[0] === '') return obj;
    for (const pair_ of pairs) {
      pair = pair_.split('=');
      obj[CookieHandler.decode(pair[0])] = CookieHandler.decode(pair[1]);
    }
    return obj;
  },
  encode(value) {
    try {
      return encodeURIComponent(value);
    } catch {
      // debug('error `encode(%o)` - %o', value, e)
    }

    return null;
  },
  decode(value) {
    try {
      return decodeURIComponent(value);
    } catch {
      // debug('error `decode(%o)` - %o', value, e)
    }

    return null;
  },
  decodeJWT(token) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');

      return JSON.parse(atob(base64));
    } catch {
      return null;
    }
  },
};

export const CookieUtil = {
  set(name, value, options) {
    options = options || {};
    let str = `${CookieHandler.encode(name)}=${CookieHandler.encode(value)}`;

    if (value == null) options.maxage = -1;

    if (options.maxage) {
      options.expires = new Date(Date.now() + options.maxage);
    }

    if (options.path) str += `; path=${options.path}`;
    if (options.domain) str += `; domain=${options.domain}`;
    if (options.expires) str += `; expires=${options.expires.toUTCString()}`;
    if (options.secure) str += '; secure';

    // eslint-disable-next-line unicorn/no-document-cookie
    document.cookie = str;
  },
  get(name) {
    return CookieHandler.getAll()[name];
  },
  setUserCookie(jwt, { domain, isSafari }) {
    CookieUtil.set('user', jwt, {
      domain,
      path: '/',
      SameSite: isSafari ? 'None' : 'Lax',
      secure: isSafari,
    });
  },
  isUserExpired(token = null) {
    const decodedUser = token || CookieUtil.decodeUserCookie();

    if (!decodedUser) {
      return true;
    }

    // The encoded expiration time is in seconds
    const nowSeconds = Date.now() / 1000;
    return !(decodedUser.exp - nowSeconds > 0);
  },
  decodeUserCookie() {
    try {
      const { user } = CookieHandler.parse(document.cookie);
      return CookieHandler.decodeJWT(user);
    } catch {
      return null;
    }
  },
  clearUser(domain) {
    /**
     * TODO 07-20-2021
     * We're needing to change the domain on cookies to .axiomlaw.com / .axiomlaw.dev
     * Coupled with that, we need to get people off the old domain cookies so making sure
     * to clear them here
     *
     * We only need to leave this around for a few months I'd imagine
     */
    try {
      CookieUtil.set('user', null, {
        domain: '.apps.axiomlaw.com',
        path: '/',
      });
    } catch {}

    try {
      CookieUtil.set('user', null, {
        domain: '.stage.axiomlaw.com',
        path: '/',
      });
    } catch {}

    try {
      CookieUtil.set('user', null, {
        domain: '.apps.axiomlaw.dev',
        path: '/',
      });
    } catch {}

    CookieUtil.set('user', null, {
      domain,
      path: '/',
    });
  },
  clearUserAndReload(domain) {
    CookieUtil.clearUser(domain);
    window.location.reload();
  },
};
