import React from 'react';

import LoginEmail from './LoginEmail';
import { LWrapper } from './LoginStyles';

export const Login = () => (
  <LWrapper>
    <LoginEmail />
  </LWrapper>
);
Login.propTypes = {};
