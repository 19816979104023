import React from 'react';
import { Modal } from 'semantic-ui-react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import {
  FluidButtonLayout,
  FluidButtonLayoutProps,
} from '../../layout/FluidButtonLayout/FluidButtonLayout';

export interface ModalFooterProps {
  children?: React.ReactNode;
  className?: string;
  columnWidth?: FluidButtonLayoutProps['columnWidth'];
  name?: string;
  stackableOn?: FluidButtonLayoutProps['stackableOn'];
}

export const ModalFooter = ({
  children,
  name,
  className,
  columnWidth,
  stackableOn,
}: ModalFooterProps) => {
  return (
    <Modal.Actions>
      <FluidButtonLayout
        className={AttrsHelper.formatClassname(
          'card-footer-container',
          className
        )}
        columnWidth={columnWidth}
        name={name}
        stackableOn={stackableOn}
      >
        {children}
      </FluidButtonLayout>
    </Modal.Actions>
  );
};
