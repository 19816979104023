import React from 'react';
import { number, string } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const MovingMailIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 66 28"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <path d="M24 0v27.715h42V0H24zm3.078 2h35.635L44.996 16.773 27.078 2zM64 3.531v20.715l-.48-.426-11.59-10.224L64 3.53zm-38 .172l12.037 9.924L26.48 23.82l-.48.426V3.703zM.5 9.5v2h17v-2H.5zm0 5v2h17v-2H.5zm49.875.393l11.82 10.427.448.395H27.357l.448-.395L39.6 14.914l5.404 4.457 5.371-4.478zM.5 19.5v2h17v-2H.5zM26 24.785l.818.93H26v-.93zm38 0v.93h-.818l.818-.93z" />
  </svg>
);

MovingMailIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

MovingMailIcon.defaultProps = {
  alt: null,
  className: null,
  height: 28,
  name: 'MAIL_ICON',
  width: 66,
};
