import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import LoadingTreatment from '../components/LoadingTreatment/LoadingTreatment';
import { TokenLoginStore } from '../stores/token-login-store';
import { Page } from '../components/Page/Page';

export const EmailTokenPage = () => {
  const { token } = useParams();

  useEffect(() => {
    TokenLoginStore.load(token);
  });

  return (
    <Page title="Axiom Law - Signing On">
      <LoadingTreatment />
    </Page>
  );
};
