import { shape, string } from 'prop-types';
import { AuthConst } from '@axiom/const';

import { requestPassword } from '../api/auth/auth';
import GenericStore from '../classes/generic-store';

import { FormManualLoginStore } from './form-manual-login-store';

class RequestPasswordStoreClass extends GenericStore {
  getDataShape() {
    return shape({
      email: string,
    });
  }

  load(email) {
    return this.setState({
      email,
    });
  }

  cancel(email) {
    return this.clearState(FormManualLoginStore.load(email));
  }

  submit(email) {
    return this.clearState(requestPassword(email, AuthConst.LoginFlows.Talent));
  }
}

export const RequestPasswordStore = new RequestPasswordStoreClass();
