import { string, number, shape, arrayOf, bool } from 'prop-types';

export const ExperienceShape = shape({
  candidateId: string,
  id: string,
  isAxiom: bool,
  client: string,
  description: string,
  submissionDescription: string,
  startDate: string,
  endDate: string,
  createdAt: string,
  updatedAt: string,
  opportunityId: string,
  externalOpportunityName: string,
  locationAddressComponents: shape({}),
  locationName: string,
  locationLatitude: number,
  locationLongitude: number,
  locationPlaceId: string,
  clientDomain: string,
  tags: arrayOf(
    shape({
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
    })
  ),
});
