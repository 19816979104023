import React from 'react';
import { bool, number, string } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const CheckmarkCircleIcon = ({
  alt,
  checked,
  className,
  filled,
  height,
  name,
  width,
}) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 30 31"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <circle
      fill={filled ? '#fff' : 'none'}
      stroke={checked ? '#40A41E' : '#909590'}
      strokeWidth="3"
      cx="15"
      cy="15.5"
      r="13.5"
    />
    <path
      fill={checked ? '#40A41E' : '#909590'}
      d="M21.697 8.362a1.878 1.878 0 00-2.664.468l-7.182 10.45-2.338-1.71a1.877 1.877 0 00-2.663.467c-.608.885-.404 2.122.453 2.749l3.895 2.851a1.877 1.877 0 002.663-.468L22.15 11.11c.607-.883.404-2.12-.453-2.748"
    />
  </svg>
);

CheckmarkCircleIcon.propTypes = {
  alt: string,
  checked: bool,
  className: string,
  filled: bool,
  height: number,
  name: string,
  width: number,
};

CheckmarkCircleIcon.defaultProps = {
  alt: null,
  checked: false,
  className: null,
  filled: false,
  height: 31,
  name: 'CHECKMARK_CIRCLE_ICON',
  width: 30,
};
