import React from 'react';
import { oneOf, node, string } from 'prop-types';
import styled from 'styled-components';

import { DataTestUtil } from '../../../utils/data-test-util';

const RawLabel = ({ as, children, dataTest }) =>
  React.createElement(as, { dataTest }, children);

RawLabel.propTypes = {
  as: string.isRequired,
  children: node.isRequired,
  dataTest: string.isRequired,
};

export const LabelSketchStyles = ({ theme }) => ({
  fontSize: '0.875rem', // * 16px = 14px
  fontWeight: 'normal',
  color: theme.text.subtle,
  lineHeight: '1.25rem', // * 16px = 20px
  textTransform: 'uppercase',
});

const StyledRawLabel = styled(RawLabel)`
  ${props => LabelSketchStyles(props)}
`;

export const Label = ({ as, for: forProp, children, className, name }) => (
  <StyledRawLabel
    htmlFor={forProp}
    as={as}
    className={className}
    data-test={DataTestUtil.format(
      name || typeof children === 'string'
        ? `${name || children}_label`
        : `label`
    )}
  >
    {children}
  </StyledRawLabel>
);

Label.propTypes = {
  as: oneOf(['div', 'span', 'label']),
  children: node,
  className: string,
  name: string,
  for: string,
};

Label.defaultProps = {
  as: 'label',
  children: null,
  className: '',
  name: null,
  for: null,
};
