const UserRoles = {
  ClientUser: 'ClientUser',
  EnvoyUser: 'EnvoyUser',
  EnvoySales: 'EnvoySales',
  EnvoyAdmin: 'EnvoyAdmin',
  LPUser: 'LPUser',
};

export const UserConst = {
  Expansions: {
    contactAccountInfo: 'contactAccountInfo',
    settingsInfo: 'settingsInfo',
  },
  UserRoles,
  Roles: {
    ...UserRoles,
    BannerMessagesAdmin: 'BannerMessagesAdmin',
    BoomiSync: 'BoomiSync',
    BullhornSync: 'BullhornSync',
    ExternalMlIntegration: 'ExternalMlIntegration',
    NotificationsListener: 'NotificationsListener',
    SalesforceSync: 'SalesforceSync',
    Seed: 'Seed',
    StaticDataCreator: 'StaticDataCreator',
  },
  WhiteLists: {
    TalentUser: {
      OtherUserWhitelist: ['id', 'firstName', 'lastName', 'fullName', 'email'],
      OppContactWhitelist: ['firstName', 'lastName', 'email'],
    },
  },
};
