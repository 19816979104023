import { CandidatesConst } from '@axiom/const';

const { ProfileStatuses } = CandidatesConst;

export const AnonBioProfileStatusUtil = {
  statusSet() {
    return new Set([
      ProfileStatuses.InDiligence,
      ProfileStatuses.Certifying,
      ProfileStatuses.NewLead,
      ProfileStatuses.Reservoir,
    ]);
  },
};
