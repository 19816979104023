import React from 'react';
import { string, number, oneOfType } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const ActivityIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 286 171.403"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <circle fill="#fff" stroke="#bebebe" cx="243.5" cy="127.5" r="41.5" />
    <path
      fill="#d6d6d6"
      d="M227.428 102.434c-2.23.93-7.185 4.492-5.959 12.179s3.28 14.563 12.019 23.912 24.7 14.475 29.045 11.517 5.169-6.307 3.895-8.143-7.09-6.054-8.316-6.386a2.761 2.761 0 00-3.068.89c-.719.933-4.29 5.977-7.196 4.598s-7.832-5.475-10.182-8c0 0-7.913-8.152-7.109-11.366s3.338-3.145 4.602-4.023 1.535-5.113-.984-10.19-4.528-5.916-6.747-4.988z"
    />
    <circle fill="#fff" stroke="#bebebe" cx="192.5" cy="42.5" r="41.5" />
    <path
      fill="#d6d6d6"
      d="M174.627 66.067v-7.552H173a6.007 6.007 0 01-6-6V31.3a6.007 6.007 0 016-6h38.436a6.007 6.007 0 016 6v21.214a6.007 6.007 0 01-6 6h-29.752l-7.056 7.551zm28.662-27.236a3.075 3.075 0 103.076 3.075 3.079 3.079 0 00-3.076-3.075zm-11.071 0a3.075 3.075 0 103.075 3.075 3.079 3.079 0 00-3.075-3.075zm-11.072 0a3.075 3.075 0 103.075 3.075 3.079 3.079 0 00-3.075-3.075z"
    />
    <circle fill="#fff" stroke="#bebebe" cx="42.5" cy="127.5" r="41.5" />
    <path
      fill="#d6d6d6"
      d="M52.583 113.747c5.9.063 7.289 3.984 7.289 8.9s-3.263 8.9-7.289 8.9-7.289-3.991-7.289-8.905 1.388-8.961 7.289-8.895zM48.638 132.235s-5.629 1.017-7.528 2.947a6.3 6.3 0 00-1.812 4.291v6.854a25.064 25.064 0 0013.942 3.579c8.375-.251 13.066-3.832 13.066-3.832v-6.6a8.473 8.473 0 00-2.189-4.291c-1.806-1.768-7.573-2.947-7.573-2.947s-1.054 1.471-4.039 1.471-3.867-1.472-3.867-1.472z"
    />
    <path
      fill="#d6d6d6"
      d="M34.495 105.004c7.326.078 9.05 4.946 9.05 11.048s-4.052 11.046-9.05 11.046-9.05-4.947-9.05-11.049 1.724-11.126 9.05-11.045zM29.597 127.959s-6.988 1.262-9.346 3.66a7.822 7.822 0 00-2.25 5.328v8.511a31.133 31.133 0 0017.312 4.444c10.4-.313 16.224-4.758 16.224-4.758v-8.2a10.516 10.516 0 00-2.718-5.328c-2.241-2.2-9.4-3.66-9.4-3.66s-1.308 1.824-5.015 1.824-4.807-1.821-4.807-1.821z"
    />
    <circle fill="#fff" stroke="#bebebe" cx="93.5" cy="42.5" r="41.5" />
    <path
      fill="#d6d6d6"
      d="M117.642 56.338H69.371l11.58-11.58a1.134 1.134 0 00-1.6-1.6L67.999 54.5V29.9l20.9 14.11c1.774 1.313 2.987 1.849 4.186 1.849 1.365 0 2.735-.707 4.5-1.849l21.428-14V54.5l-11.349-11.346a1.133 1.133 0 10-1.6 1.6l11.576 11.583zM92.936 43.859a3.788 3.788 0 01-2.108-.833c-.24-.154-.512-.328-.815-.5-1.641-.937-14.24-9.676-19.58-13.39L68.799 28h49.839l-.529.348-.01.006-.725.477-.455.3h2.092-2.1c-4.036 2.664-17.455 11.488-20.57 13.394a7.068 7.068 0 01-3.405 1.334z"
    />
    <g fill="#bebebe">
      <path d="M140.85 170.404H95.634a4.661 4.661 0 01-5.008-4.654s-.285-2.874 0-13.35 2.565-26.07 5.008-30.326 30.276-11.6 30.276-11.6a33.76 33.76 0 010 7.317c-.428 4.276 14.94 13.08 14.94 13.08s15.292-9.347 15.232-13.266 0-7.132 0-7.132 27.479 7.341 29.919 11.6 4.715 19.849 5 30.326 0 13.35 0 13.35a4.658 4.658 0 01-5 4.654z" />
      <path d="M99.594 170.403H62.241a3.851 3.851 0 01-4.141-3.845s-.235-2.374 0-11.028 2.124-21.533 4.141-25.052 25.011-9.581 25.011-9.581a27.875 27.875 0 010 6.044c-.352 3.533 12.342 10.806 12.342 10.806s12.633-7.721 12.583-10.959 0-5.891 0-5.891 22.7 6.063 24.716 9.581 3.9 16.4 4.13 25.052 0 11.028 0 11.028a3.848 3.848 0 01-4.13 3.845z" />
      <path d="M100.376 126.614c-2.834 0-5.584-.758-8.176-2.253-2.517-1.452-4.78-3.534-6.728-6.189-1.955-2.666-3.49-5.773-4.564-9.235-1.114-3.591-1.679-7.407-1.679-11.342 0-3.788.06-7.79.495-11.4.459-3.8 1.296-6.814 2.56-9.213 1.446-2.745 3.469-4.75 6.185-6.13 3.014-1.532 6.82-2.276 11.637-2.276h.267c4.886.029 8.744.787 11.795 2.317 2.754 1.382 4.803 3.38 6.265 6.11 2.853 5.326 3.09 12.78 3.09 20.592 0 3.935-.565 7.75-1.679 11.342-1.074 3.462-2.61 6.569-4.565 9.235-1.946 2.655-4.21 4.737-6.727 6.189-2.592 1.495-5.343 2.253-8.176 2.253z" />
      <path d="M100.376 126.114c11.385 0 20.647-12.794 20.647-28.519 0-7.746-.232-15.132-3.03-20.356-1.412-2.636-3.39-4.565-6.05-5.899-2.98-1.495-6.766-2.235-11.573-2.263l-.264-.001c-4.736 0-8.468.727-11.41 2.222-2.621 1.331-4.574 3.267-5.97 5.917-1.235 2.344-2.054 5.3-2.505 9.04-.432 3.583-.492 7.568-.492 11.34 0 15.725 9.262 28.519 20.647 28.519m0 1c-11.955 0-21.647-13.216-21.647-29.519 0-16.23 1.173-29.519 21.377-29.519h.27c20.466.12 21.647 13.217 21.647 29.52 0 16.302-9.692 29.518-21.647 29.518z" />
      <path d="M182.594 170.403h-37.353a3.851 3.851 0 01-4.141-3.845s-.235-2.374 0-11.028 2.124-21.533 4.141-25.052 25.011-9.581 25.011-9.581a27.875 27.875 0 010 6.044c-.352 3.533 12.342 10.806 12.342 10.806s12.633-7.721 12.583-10.959 0-5.891 0-5.891 22.7 6.063 24.716 9.581 3.9 16.4 4.13 25.052 0 11.028 0 11.028a3.848 3.848 0 01-4.13 3.845z" />
      <path d="M183.376 126.614c-2.834 0-5.584-.758-8.176-2.253-2.517-1.452-4.78-3.534-6.728-6.189-1.955-2.666-3.49-5.773-4.564-9.235-1.114-3.591-1.679-7.407-1.679-11.342 0-3.788.06-7.79.495-11.4.459-3.8 1.296-6.814 2.56-9.213 1.446-2.745 3.469-4.75 6.185-6.13 3.014-1.532 6.82-2.276 11.637-2.276h.267c4.886.029 8.744.787 11.795 2.317 2.754 1.382 4.803 3.38 6.265 6.11 2.853 5.326 3.09 12.78 3.09 20.592 0 3.935-.565 7.75-1.679 11.342-1.074 3.462-2.61 6.569-4.565 9.235-1.946 2.655-4.21 4.737-6.727 6.189-2.592 1.495-5.343 2.253-8.176 2.253z" />
      <path d="M183.376 126.114c11.385 0 20.647-12.794 20.647-28.519 0-7.746-.232-15.132-3.03-20.356-1.412-2.636-3.39-4.565-6.05-5.899-2.98-1.495-6.766-2.235-11.573-2.263l-.264-.001c-4.736 0-8.468.727-11.41 2.222-2.621 1.331-4.574 3.267-5.97 5.917-1.235 2.344-2.054 5.3-2.505 9.04-.432 3.583-.492 7.568-.492 11.34 0 15.725 9.262 28.519 20.647 28.519m0 1c-11.955 0-21.647-13.216-21.647-29.519 0-16.23 1.173-29.519 21.377-29.519h.27c20.466.12 21.647 13.217 21.647 29.52 0 16.302-9.692 29.518-21.647 29.518z" />
    </g>
    <path
      fill="#d6d6d6"
      stroke="#fcfcfc"
      d="M140.351 170.404H95.135a4.661 4.661 0 01-5.008-4.654s-.285-2.874 0-13.35 2.565-26.07 5.008-30.326 30.276-11.6 30.276-11.6a33.76 33.76 0 010 7.317c-.428 4.276 14.94 13.08 14.94 13.08s15.292-9.347 15.232-13.266 0-7.132 0-7.132 27.479 7.341 29.919 11.6 4.715 19.849 5 30.326 0 13.35 0 13.35a4.658 4.658 0 01-5 4.654z"
    />
    <path
      fill="#d6d6d6"
      d="M141.204 118C127.307 118 116 102.419 116 83.267c0-4.573.072-9.405.597-13.752.55-4.553 1.549-8.157 3.055-11.017 1.711-3.248 4.105-5.62 7.317-7.253 1.733-.88 3.742-1.554 5.972-2.002 2.344-.471 5.014-.71 7.937-.71h.32c5.853.035 10.466.938 14.104 2.763 3.258 1.635 5.683 4 7.413 7.23 3.41 6.367 3.693 15.336 3.693 24.74 0 19.153-11.306 34.734-25.204 34.734z"
    />
    <path
      fill="#fff"
      d="M141.204 117c3.182 0 6.278-.855 9.2-2.54 2.88-1.66 5.475-4.052 7.716-7.109 2.276-3.102 4.065-6.725 5.319-10.768 1.307-4.213 1.97-8.693 1.97-13.316 0-4.556-.073-9.363-.596-13.646-.54-4.413-1.514-7.888-2.98-10.623-1.629-3.042-3.912-5.27-6.98-6.808-1.676-.841-3.63-1.49-5.805-1.928-2.312-.466-4.955-.711-7.855-.728l-.315-.001c-2.857 0-5.46.232-7.74.69-2.14.43-4.064 1.074-5.716 1.914-3.021 1.535-5.274 3.768-6.885 6.828-1.449 2.75-2.413 6.24-2.947 10.67-.519 4.295-.59 9.09-.59 13.632 0 4.623.663 9.103 1.97 13.316 1.253 4.043 3.043 7.666 5.318 10.768 2.241 3.057 4.837 5.448 7.716 7.108 2.922 1.686 6.018 2.54 9.2 2.54m0 2C126.732 119 115 103.003 115 83.268c0-19.647 1.42-35.734 25.878-35.734l.326.001c24.775.143 26.204 15.998 26.204 35.733S155.676 119 141.204 119z"
    />
  </svg>
);

ActivityIcon.propTypes = {
  alt: string,
  className: string,
  height: oneOfType([number, string]),
  name: string,
  width: oneOfType([number, string]),
};

ActivityIcon.defaultProps = {
  alt: null,
  className: null,
  height: 162,
  name: 'ACTIVITY_ICON',
  width: 270,
};
