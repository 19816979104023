import styled from 'styled-components';
import { node } from 'prop-types';

import { DarkTheme } from '../../theme/DarkTheme/DarkTheme';

export const FlexiCardGridHeader = styled(DarkTheme)`
  word-break: break-word;
`;

FlexiCardGridHeader.propTypes = {
  children: node,
};

FlexiCardGridHeader.defaultProps = {
  children: null,
};
