import React from 'react';
import {
  AxiomLogo,
  Button,
  CondensedLarge,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  SmallHeader,
} from '@axiom/ui';

import { ClientLoginOuterLayout } from './ClientLoginOuterLayout';
import {
  LeftColumn,
  RightColumn,
  TwoColumnLayout,
} from './ClientLoginTwoColumnLayoutStyles';

export const ClientLoginTwoColumnLayout: React.FC<{
  action: string;
  subText?: string;
  backUrl?: string;
  sideImageUrl?: string;
  children: React.ReactNode;
}> = ({ action, subText, backUrl, sideImageUrl, children }) => (
  <ClientLoginOuterLayout>
    <TwoColumnLayout leftColumnOnly={!sideImageUrl}>
      <LeftColumn data-test="left-column">
        <Gutter horizontal="24px" vertical="24px">
          {backUrl ? (
            <Gutter bottom="24px">
              <Button
                name="back-button"
                icon="arrow-left"
                iconPosition="left"
                variation="minimal"
                to={backUrl}
              >
                Back
              </Button>
            </Gutter>
          ) : (
            <Gutter bottom="56px" />
          )}

          <Grid centered>
            <GridRow>
              <GridColumn largeScreenWidth={9} tabletWidth={9} mobileWidth={12}>
                <Gutter bottom="20px" className="text-align-center">
                  <AxiomLogo width="230px" />
                </Gutter>
                <Gutter bottom="24px">
                  <SmallHeader name="ACTION">{action}</SmallHeader>
                </Gutter>
                {subText && (
                  <Gutter bottom="24px">
                    <CondensedLarge name="SUBTEXT">{subText}</CondensedLarge>
                  </Gutter>
                )}
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn largeScreenWidth={9} tabletWidth={8} mobileWidth={12}>
                {children}
              </GridColumn>
            </GridRow>
          </Grid>
        </Gutter>
      </LeftColumn>
      {sideImageUrl && (
        <RightColumn imageUrl={sideImageUrl} data-test="right-column" />
      )}
    </TwoColumnLayout>
  </ClientLoginOuterLayout>
);
