import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, CookieUtil, Form, Gutter, Input } from '@axiom/ui';

import sideImageUrl from '../public/svg/weight-lifter.svg?url';
import LoadingTreatment from '../components/LoadingTreatment/LoadingTreatment';
import { WindowUtil } from '../utils/window-util';
import { ClientLoginTwoColumnLayout } from '../layouts/ClientLogin/ClientLoginTwoColumnLayout';
import { ReadAuthRequest, readAuthRequestSchema } from '../api/auth/auth';

const { getFullRedirect, getUrlWithRelayState } = WindowUtil;

export const ClientEmailPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const relayState = searchParams.get('RelayState');

  useEffect(() => {
    if (!CookieUtil.isUserExpired()) {
      navigate(`/sso${relayState ? `${getFullRedirect(location)}` : ''}`, {
        replace: true,
      });
    }
  }, []);

  if (!CookieUtil.isUserExpired()) {
    return <LoadingTreatment />;
  }

  return (
    <ClientLoginTwoColumnLayout
      action="Let's find your Axiom Account"
      subText="We'll confirm that your Axiom account is associated with this email."
      sideImageUrl={sideImageUrl}
    >
      <Form
        name="EMAIL_FORM"
        onSubmit={async (formData: ReadAuthRequest) => {
          navigate(getUrlWithRelayState(`./${formData.email}`));
        }}
        schema={readAuthRequestSchema}
      >
        {({ fireSubmit }) => {
          return (
            <>
              <Gutter bottom="24px">
                <Input name="email" label="Email" />
              </Gutter>
              <Button fluid onClick={fireSubmit} name="next-button">
                Find my account
              </Button>
            </>
          );
        }}
      </Form>
    </ClientLoginTwoColumnLayout>
  );
};
