import React, { useContext, useState } from 'react';
import { Sticky as SemanticSticky } from 'semantic-ui-react';
import styled from 'styled-components';

import { StickyContext } from './StickyContext';

const StickyContainer = styled.div<{ isSticky: boolean }>`
  ${({ isSticky }) => ({
    width: isSticky ? '100%' : null,
  })}
`;

export const Sticky = ({
  children,
  className,
  name,
  onStick = () => {},
  onUnstick = () => {},
}: {
  children: React.ReactNode;
  className?: string;
  name?: string;
  onStick?: () => void;
  onUnstick?: () => void;
}) => {
  const [isSticky, setIsSticky] = useState(false);
  const containerRef = useContext(StickyContext);
  /**
   * The first div protects against any interference from the parent component
   * if it's leveraging Flex box like Layout.
   */
  return (
    <div>
      <StickyContainer
        isSticky={isSticky}
        data-test={name}
        className={className}
      >
        <SemanticSticky
          context={containerRef}
          onStick={() => {
            if (!isSticky) {
              onStick();
              setIsSticky(true);
            }
          }}
          onUnstick={() => {
            onUnstick();
            setIsSticky(false);
          }}
        >
          {children}
        </SemanticSticky>
      </StickyContainer>
    </div>
  );
};
