import { Opportunity } from './opportunity';
import { CandidatesConst } from './candidate';

const { ProfileStatuses } = CandidatesConst;
const constStageMaps = Opportunity.STAGE_CODE_MAP;

export const Statistics = {
  indexes: {
    candidates: 'Candidates',
    positions: 'Positions',
    optedOutCount: 'OptedOutCount',
  },
};

export const YearsStartDateToInclude = 2;

export const BaseStatisticsFilters = {
  candidateBaseFilter: {
    profileStatus: [
      ProfileStatuses.Active,
      ProfileStatuses.Alum,
      ProfileStatuses.Beach,
      ProfileStatuses.Idle,
      ProfileStatuses.InDiligence,
      ProfileStatuses.PendingActive,
      ProfileStatuses.PendingBeach,
      ProfileStatuses.Waitlist,
    ],
    profilePercentComplete: { start: 50 },
    axiomEmployeeType: ['Insourcing'],
  },
  engagementsBaseFilter: {
    'opportunity.stageCode': [constStageMaps[Opportunity.CLOSED_WON]],
  },
  opportunityBaseFilter: {
    stageCode: [constStageMaps[Opportunity.CLOSED_WON]],
  },
  optedOutBaseFilter: {
    isSpotlightedLawyer: { value: false },
    profileStatus: [
      ProfileStatuses.Active,
      ProfileStatuses.Beach,
      ProfileStatuses.Idle,
      ProfileStatuses.PendingActive,
      ProfileStatuses.PendingBeach,
      ProfileStatuses.Waitlist,
      ProfileStatuses.Alum,
      ProfileStatuses.InDiligence,
    ],
    profilePercentComplete: { start: 50 },
  },
};
