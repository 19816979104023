import React from 'react';

import { EducationShape } from '../../../models/education-model';
import { DataTestUtil } from '../../../utils/data-test-util';
import { DataTest } from '../../general/DataTest/DataTest';
import { Header4 } from '../../content/Header4/Header4';
import { Text } from '../../content/Text/Text';

export const Education = ({ education }) => (
  <div
    style={{ breakInside: 'avoid' }}
    data-test={DataTestUtil.format('educationItem')}
  >
    <Header4 name="institution">{education.institution}</Header4>
    <Text name="educationItemDetails">
      <DataTest value="degree" inline>
        {education.degree}
      </DataTest>
      {/* only comma separate if both values are present */}
      {education.degree && education.yearAwarded ? ',' : ''}
      {education.yearAwarded > new Date().getFullYear()
        ? ' expected completion '
        : ' '}
      <DataTest value="yearAwarded" inline>
        {education.yearAwarded}
      </DataTest>
    </Text>
  </div>
);

Education.propTypes = {
  education: EducationShape.isRequired,
};
