import React, { Component } from 'react';

import { LoggerUtil } from '../../../utils/logger-util';
import { ApiError } from '../../../classes/api-error';
import { CookieUtil } from '../../../utils/cookie-util';
import { DesignSysEnvUtil } from '../../../utils/design-sys-env-util';

type ErrorBoundaryProps = {
  children: React.ReactNode;
  errorLayout: React.ReactNode;
  ignoreUnauthorizedError?: boolean;
};

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  { hasError: boolean }
> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error) {
    const { ignoreUnauthorizedError } = this.props;
    if (
      !ignoreUnauthorizedError &&
      error instanceof ApiError &&
      error.getStatusCode() === 401
    ) {
      CookieUtil.clearUserAndReload(DesignSysEnvUtil.cookieDomain);
    } else {
      LoggerUtil.fireError(error);
      this.setState({ hasError: true });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children, errorLayout } = this.props;
    if (hasError) {
      return errorLayout;
    }

    return children;
  }
}
