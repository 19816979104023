import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { string, node } from 'prop-types';
import { Button, Gutter, KeyIcon, Text } from '@axiom/ui';
import { Link, useNavigate } from 'react-router-dom';

import LabelWrapper from '../LabelWrapper/LabelWrapper';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import CommonCard from '../CommonCard/CommonCard';
import { FormResetPasswordStore } from '../../stores/form-reset-password-store';
import { FFInput } from '../FFInput/FFInput';
import { getPasswordScore } from '../../utils/ajv-util';
import { StateComponentUtil } from '../../utils/state-component-util';
import { formatDataTestId } from '../../utils/dataTest';
import { ErrorBlurb } from '../ErrorBlurb/ErrorBlurb';

import {
  RPStrengthMeter,
  RPStrengthBlock,
  RPFooterButtonsWrapper,
  RPFormGroup,
  RPPasswordWrapper,
  RPStrengthWrapper,
} from './ResetPasswordStyles';

const ResetPassword = ({ formState, title, errorMessage }) => {
  const [isSuccess, setSuccessful] = useState(false);
  const navigate = useNavigate();

  const backToSignIn = () => navigate('/');

  return (
    <CommonCard
      title={title}
      icon={<KeyIcon height={52} width={42} />}
      goBackLink="/"
      goBackText="SIGN-IN PAGE"
    >
      {errorMessage}
      {isSuccess ? (
        <div className="text-align-center">
          Your password has been reset. Redirecting you now. Otherwise
          <br />
          <Link to="/">Click here</Link> to login.
          <Gutter bottom="32px" />
        </div>
      ) : (
        <StoreStateTreatment
          nonBlockingErrorComponent={null}
          storeState={formState}
          renderLoadedView={({ data: formData }) => (
            <Form
              onSubmit={submitData =>
                FormResetPasswordStore.submit(submitData).then(() => {
                  setSuccessful(true);
                  setTimeout(backToSignIn, 5000);
                })
              }
              initialValues={formData}
              validate={validateData =>
                FormResetPasswordStore.validate(validateData)
              }
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <RPFormGroup>
                    Your new password must have a minimum strength of Strong.
                  </RPFormGroup>
                  <Field name="token" type="hidden" component={FFInput} />
                  <RPPasswordWrapper>
                    <LabelWrapper label="Password" margin={false}>
                      <Field
                        name="password"
                        type="password"
                        component={FFInput}
                        data-test={formatDataTestId('PASSWORD_INPUT')}
                        style={{ fontSize: 32, letterSpacing: 5 }}
                      />
                    </LabelWrapper>
                  </RPPasswordWrapper>
                  <RPStrengthWrapper>
                    <RPStrengthMeter>
                      <RPStrengthBlock
                        full={
                          getPasswordScore(values.password) >= 0 &&
                          values.password
                        }
                        score={getPasswordScore(values.password)}
                      />
                      <RPStrengthBlock
                        full={getPasswordScore(values.password) >= 1}
                        score={getPasswordScore(values.password)}
                      />
                      <RPStrengthBlock
                        full={getPasswordScore(values.password) >= 2}
                        score={getPasswordScore(values.password)}
                      />
                      <RPStrengthBlock
                        full={getPasswordScore(values.password) >= 3}
                        score={getPasswordScore(values.password)}
                      />
                      <RPStrengthBlock
                        full={getPasswordScore(values.password) >= 4}
                        score={getPasswordScore(values.password)}
                      />
                    </RPStrengthMeter>
                    <Gutter top="0.5rem">
                      <Text subtle name="RESET_PASSWORD_STRENGTH">
                        Strength:{' '}
                        {values.password ? (
                          [
                            'Very Weak',
                            'Weak',
                            'Fair',
                            'Strong',
                            'Very Strong',
                          ][getPasswordScore(values.password)]
                        ) : (
                          <br />
                        )}
                      </Text>
                    </Gutter>
                  </RPStrengthWrapper>
                  <LabelWrapper label="Confirm Password">
                    <Field
                      name="passwordConfirm"
                      type="password"
                      component={FFInput}
                      data-test={formatDataTestId('REPEAT_PASSWORD')}
                      style={{ fontSize: 32, letterSpacing: 5 }}
                    />
                  </LabelWrapper>
                  <RPFooterButtonsWrapper>
                    <Button type="submit" name="SUBMIT">
                      Set Password
                    </Button>
                  </RPFooterButtonsWrapper>
                  {StateComponentUtil.hasErrors([formState]) && (
                    <ErrorBlurb
                      errorMessage={StateComponentUtil.getErrors([
                        formState,
                      ]).join(', ')}
                    />
                  )}
                  <Gutter bottom="32px" />
                </form>
              )}
            />
          )}
        />
      )}
    </CommonCard>
  );
};

ResetPassword.defaultProps = {
  errorMessage: null,
};

ResetPassword.propTypes = {
  formState: FormResetPasswordStore.getStateShape().isRequired,
  title: string.isRequired,
  errorMessage: node,
};

const mapStateToProps = state => ({
  formState: FormResetPasswordStore.select(state),
});

export default connect(mapStateToProps)(ResetPassword);
