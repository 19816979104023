import { shape, string, number, bool, oneOf } from 'prop-types';

const degrees = [
  'JD',
  'MBA',
  'Qualification',
  'State Exam',
  'BS',
  'BA',
  'BCL',
  'MS',
  'MA',
  'MD',
  'PharmD',
  'PHD',
  'Year of Call',
  'LLB',
  'LLM',
  'GDL',
  'LPC',
  'QLTS',
  'BVC',
  'PCLL',
  'MLIS',
];

export const EducationShape = shape({
  id: string,
  institution: string,
  degree: oneOf(degrees),
  yearAwarded: number,
  stateExamScore: string,
  isPrimary: bool,
});
