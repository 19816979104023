import styled from 'styled-components';

export const StyledFinePrint = styled.div`
  * {
    font-size: 13.6px !important;
  }
`;

export const FooterWrapper = styled.footer`
  margin-top: 0;
`;
