import React from 'react';
import { arrayOf } from 'prop-types';
import styled from 'styled-components';

import { BarredLocationShape } from '../../../models/barred-location-model';
import { Gutter } from '../../layout/Gutter/Gutter';
import { LocationIcon } from '../../icons/LocationIcon/LocationIcon';

const SpanLabelWrapper = styled.span`
  vertical-align: middle;
`;

export const BarredLocations = ({ barredLocations }) => {
  if (barredLocations.length === 0) {
    return null;
  }

  return barredLocations.map(loc => (
    <Gutter right="1rem" as="span" key={loc.id}>
      <LocationIcon /> <SpanLabelWrapper>{loc.name}</SpanLabelWrapper>
    </Gutter>
  ));
};

BarredLocations.propTypes = {
  barredLocations: arrayOf(BarredLocationShape).isRequired,
};
