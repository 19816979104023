import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';

import { CertifiedIcon } from '../../icons/CertifiedIcon/CertifiedIcon';

const StrongWrapper = styled.strong`
  vertical-align: middle;
`;

export const CertifiedLabel = ({ title }) => (
  <div>
    <CertifiedIcon />{' '}
    <StrongWrapper>
      <small>{title}</small>
    </StrongWrapper>
  </div>
);

CertifiedLabel.propTypes = {
  title: string.isRequired,
};
