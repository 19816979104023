import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import { stores, initialState } from '../stores';
import GenericStore from '../classes/generic-store';

const initializeStores = () => {
  Object.keys(stores).forEach(namespace => {
    if (stores[namespace] instanceof GenericStore) {
      const reduxNamespace = namespace.toUpperCase();
      stores[reduxNamespace].initializeNamespace({
        namespace: reduxNamespace,
        rootParent: stores[namespace],
        parent: stores[namespace],
      });
    }
  });
};

const makeReducers = () => {
  const returnReducers = {};
  Object.keys(stores).forEach(namespace => {
    /**
     * getReducers returns an object of reducer events, so make a
     * func that will act as the reducer func call in redux
     */
    namespace = namespace.toUpperCase();
    const stateReducers = stores[namespace].getReducers() || {};

    /**
     * Setup an initialize state
     */
    // events[getInitializeEventName(namespace)] = (state, initialData) => ({
    //   ...state,
    //   ...initialData,
    // });
    returnReducers[namespace] = (state = {}, action) => {
      /**
       * Because we prepend all reducer action types with namespace
       * (SET_RECORD => CANDIDATE_SET_RECORD), we need to chop off the namespace
       * when looking in each reducer action call to make sure its
       * 1. the correct namespace (CANDIDATE)
       * 2. the events object has the action (SET_RECORD)
       */

      if (!action) {
        throw new Error('You need to pass an {type:###} to the dispatch');
      }

      const actionType = action.type.toUpperCase();
      if (stateReducers[actionType]) {
        const allowedActionProps = ['payload', 'errors', 'error'];
        let payload = null;
        allowedActionProps.forEach(name => {
          if (Object.keys(action).includes(name)) {
            payload = action[name];
          }
        });
        return stateReducers[actionType](state, payload);
      }
      return state;
    };
  });

  return returnReducers;
};

export const initializeStore = () => {
  initializeStores();

  const state = Object.keys(stores).reduce((acc, namespace) => {
    if (stores[namespace] instanceof GenericStore) {
      acc[namespace] = stores[namespace].getInitialState();
    }
    return acc;
  }, initialState);

  const store = createStore(
    combineReducers(makeReducers()),
    state,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  );

  Object.keys(stores).forEach(namespace => {
    if (stores[namespace] instanceof GenericStore) {
      stores[namespace].initializeDispatch(store.dispatch);
    }
  });

  return store;
};
