import React, { useState } from 'react';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { PopupProps } from 'semantic-ui-react/dist/commonjs/modules/Popup/Popup';

import { IconButton } from '../Button/IconButton';

const TooltipWrapper = styled.div`
  display: flex;

  & > *:last-child {
    margin-left: 16px;
  }
`;

type TooltipProps = {
  anchor?: React.ReactNode;
  children: React.ReactNode;
  name?: string;
  position?: 'top' | 'bottom' | 'left' | 'right' | 'top center';
};

const positionMappings = {
  top: 'top right',
  bottom: 'bottom left',
  left: 'left center',
  right: 'right center',
} as Record<string, unknown>;

export const Tooltip = ({
  anchor = (
    <IconButton
      name="TOOLTIP"
      variation="minimal"
      pattern="secondary"
      icon="circle-info"
    />
  ),
  children,
  name = 'tooltip',
  position = 'top',
}: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);
  let positionMap = position as PopupProps['position'];
  if (positionMappings[position]) {
    positionMap = positionMappings[position] as PopupProps['position'];
  }
  return (
    <span data-test={`${name}-anchor`}>
      <Popup
        basic
        hideOnScroll
        content={
          <TooltipWrapper>
            <span data-test={`${name}-contents`}>{children}</span>
            <span data-test={`${name}-close`}>
              <IconButton
                icon="x-mark"
                pattern="secondary"
                variation="minimal"
                onClick={() => setIsOpen(false)}
              />
            </span>
          </TooltipWrapper>
        }
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        position={positionMap}
        trigger={anchor}
      />
    </span>
  );
};
