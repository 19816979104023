import React from 'react';

import { ErrorBoundaryLayout } from '../layouts/ErrorBoundaryLayout/ErrorBoundaryLayout';
import { Page } from '../components/Page/Page';

export const NotFoundPage = () => (
  <Page title="Axiom Law - Not Found">
    <ErrorBoundaryLayout errorMessage="Page not found. Please try something else" />
  </Page>
);
