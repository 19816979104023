import styled from 'styled-components';

interface ItemBlockType {
  groupSize: number;
  lastOfGroup: boolean;
  isAxiom: boolean;
}

export const ItemBlock = styled.div<ItemBlockType>`
  ${({ groupSize, lastOfGroup, isAxiom, theme }) =>
    groupSize > 1 &&
    `
    padding-left: 2rem;
    position: relative;

    ${
      !isAxiom &&
      `
        // the ball
        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: 1rem;
          height: 1rem;
          background: ${theme.colors.controlSecondary};
          border-radius: 50%;
        }
    `
    }

    ${
      lastOfGroup && !isAxiom
        ? ``
        : `
    // the bar
    &::after {
      content: '';
      position: absolute;
      width: 0.25rem;
      left: 0.375rem;
      background: ${theme.colors.controlSecondary};
      top: 0;
      bottom: 0;
    }
      `
    }
  `}
`;
