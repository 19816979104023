import styled, { css } from 'styled-components';
import { DarkTheme, MediaQueryUtil } from '@axiom/ui';

import { PALETTE } from '../../themes/constants';

const { grays } = PALETTE;

export const LWrapper = styled.div`
  height: 100%;
  min-height: 52.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;

  .section {
    width: 32.375rem;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    height: 640px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 45px;
    flex-direction: column;
  }
  ${MediaQueryUtil.smallScreenOnly(css`
    min-height: 100vh;
    flex-direction: column;
  `)}

  & section {
    margin-bottom: 0 !important;
  }

  ${MediaQueryUtil.mobileOnly(css`
    .section {
      width: 90%;
      padding: 0 15%;
    }

    .email {
      margin-top: 5%;
    }
  `)}

  ${MediaQueryUtil.mobileOnly(css`
    .section {
      width: 90%;
      padding: 0 5%;
    }

    .email {
      margin-top: 5%;
    }
  `)}
`;

export const LPanelWrapperLeft = styled.div`
  background-color: white;
  border-radius: 8px 0 0 8px;

  ${MediaQueryUtil.smallScreenOnly(css`
    box-shadow: none;
    border-radius: 8px 8px 0 0;
  `)}
`;

export const LPanelWrapperRight = styled(DarkTheme)`
  border-radius: 0 8px 8px 0;

  ${MediaQueryUtil.smallScreenOnly(css`
    border-radius: 0 0 8px 8px;
  `)}
`;

export const LAxiomLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.625rem;
  height: 6.5rem;
  width: 100%;
  ${MediaQueryUtil.mobileOnly(css`
    height: 5rem;
  `)}
`;

export const LFooterButtonsWrapper = styled.div`
  text-align: center;
  margin-top: 0;
`;

export const LTextBold = styled.div`
  color: #6c757e;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2.3125rem;
`;

export const LPEmailWrapper = styled.div`
  margin-bottom: 1.4825rem;
  margin-top: 1.5625rem;
`;

export const LPEmail = styled.div`
  margin-left: 1.0625rem;
  margin-top: 0.6875rem;
`;

export const LPLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Sidelined = styled.span`
  display: flex;
  align-items: center;
  margin: 0 -1rem;
  font-size: 1.5rem;
  color: ${grays.gray4};

  &::before,
  &::after {
    background: ${grays.gray4};
    content: '';
    flex: 1 0 auto;
    height: 1px;
    margin: 0 1rem;
  }
`;

export const LPPasswordWrapper = styled.div`
  margin-bottom: 2.5625rem;
`;

export const LPWrapper = styled.div`
  width: 100vw;
`;

export const LEEmailWrapper = styled.div`
  margin-bottom: 2.5rem;
`;

export const LEForm = styled.form`
  width: 100%;
`;
