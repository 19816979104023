import React, { useEffect } from 'react';

import { IconButton } from '../Button/IconButton';
import { Layout } from '../../layout/Layout/Layout';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export type ToastProps = {
  children?: React.ReactNode;
  dismissible?: number | boolean;
  name?: string;
  onClose?: () => void;
  type?: 'info' | 'critical' | 'error';
};

export const Toast = ({
  children,
  dismissible,
  name,
  onClose,
  type = 'info',
}: ToastProps) => {
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> = null;

    if (typeof dismissible === 'number') {
      timeout = setTimeout(onClose, dismissible * 1000);
    } else if (dismissible === undefined) {
      timeout = setTimeout(onClose, 10000);
    }

    return () => timeout && clearTimeout(timeout);
  }, []);

  return (
    <div
      className={AttrsHelper.formatClassname(
        'axiom-toast',
        `toast-type-${type}`
      )}
      data-test={name}
    >
      <Layout>
        <LayoutItem fluid name={`${name}-toast-contents`}>
          {children}
        </LayoutItem>
        {dismissible && (
          <LayoutItem leftGutter="16px">
            <IconButton
              name={`${name}-close-toast`}
              icon="x-mark"
              pattern="secondary"
              variation="minimal"
              onClick={onClose}
            />
          </LayoutItem>
        )}
      </Layout>
    </div>
  );
};
