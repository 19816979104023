import React from 'react';
import { Button, DataTestUtil, Header1 } from '@axiom/ui';

import { EnvUtil } from '../../utils/env-util';

import {
  WelcomeDivider,
  WelcomeSection,
  WelcomeText,
  WelcomeWrapper,
} from './WelcomeStyles';

export const Welcome = () => (
  <WelcomeWrapper data-test={DataTestUtil.format('WELCOME')}>
    <Header1>Join the Axiom network</Header1>

    <WelcomeSection>
      <WelcomeText>
        Sign up for an Axiom account to access our network.
      </WelcomeText>
      <Button
        pattern="secondary"
        variation="outline"
        to={EnvUtil.signUpUrl}
        name="SIGN_UP_LINK"
        className="gtm-login-sign-up-link"
      >
        Sign Up as an Axiom Client
      </Button>
    </WelcomeSection>

    <WelcomeDivider />

    <WelcomeSection>
      <WelcomeText>Join our network of top-tier legal talent.</WelcomeText>
      <Button
        pattern="secondary"
        variation="outline"
        to="https://www.axiomlaw.com/careers/lawyers"
        name="CAREERS"
        className="gtm-login-careers-link"
      >
        Become an Axiom Lawyer
      </Button>
    </WelcomeSection>
  </WelcomeWrapper>
);
