import { CandidatesConst } from './candidate';

export const ExternalCandidateDefaultFilters = {
  // need to limit to opted in lawyers.
  BOOLEAN_FILTER_FIELDS: ['isSpotlightedLawyer', 'isBookDirectOffer'],
  PROFILE_EXTERNAL_STATUS_FILTER: [
    CandidatesConst.ProfileStatuses.Active,
    CandidatesConst.ProfileStatuses.Beach,
    CandidatesConst.ProfileStatuses.PendingActive,
    CandidatesConst.ProfileStatuses.PendingBeach,
    CandidatesConst.ProfileStatuses.Waitlist,
    CandidatesConst.ProfileStatuses.Idle,
  ],
  PROFILE_AUTHED_STATUS_FILTER: [
    CandidatesConst.ProfileStatuses.Active,
    CandidatesConst.ProfileStatuses.Beach,
    CandidatesConst.ProfileStatuses.PendingActive,
    CandidatesConst.ProfileStatuses.PendingBeach,
    CandidatesConst.ProfileStatuses.Waitlist,
  ],
  IS_SPOTLIGHTED: { value: true },
};

export const availabilityFilters = {
  [CandidatesConst.PartTime]: { start: 1, end: 39 },
  [CandidatesConst.FullTime]: { start: 40 },
};
