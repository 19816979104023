import { TimeZone } from '@axiom/validation';

export const TIMEZONES: ReadonlyArray<TimeZone> = [
  {
    abbr: 'LINT',
    name: 'Line Islands Time',
    iana: 'Etc/GMT-14',
    offset: '+14',
    momentName: '+14',
    id: '93e62abb-9271-4650-9d3b-5f7b2b72999f',
  },
  {
    abbr: 'CHADT',
    name: 'Chatham Island Daylight Time',
    iana: 'Pacific/Chatham',
    offset: '+13:45',
    momentName: '+1345',
    id: '7ee0f450-abfc-4016-ba70-939b8f78af36',
  },
  {
    abbr: 'NZDT',
    name: 'New Zealand Daylight Time',
    iana: 'Pacific/Auckland',
    offset: '+13',
    momentName: 'NZDT',
    id: '897ec32b-0664-4843-93d1-1366d9fc350b',
  },
  {
    abbr: 'CHAST',
    name: 'Chatham Island Standard Time',
    iana: 'Pacific/Chatham',
    offset: '+12:45',
    momentName: '+1245',
    id: 'f0dfa75e-c32f-4499-8056-6238bff0cb6b',
  },
  {
    abbr: 'ANAT',
    name: 'Anadyr Time',
    iana: 'Asia/Anadyr',
    offset: '+12',
    momentName: '+12',
    id: 'dd26188e-81d2-4602-81ee-a3a2d2ce8e47',
  },
  {
    abbr: 'NZST',
    name: 'New Zealand Standard Time',
    iana: 'Pacific/Auckland',
    offset: '+12',
    momentName: 'NZST',
    id: 'fc15cdf7-2cc8-402e-bf99-990f3e019631',
  },
  {
    abbr: 'LHDT',
    name: 'Lord Howe Island Daylight Saving Time',
    iana: 'Australia/Lord_Howe',
    offset: '+11',
    momentName: '+11',
    id: '5425439b-39ff-45dd-851e-348dfa170204',
  },
  {
    abbr: 'AEDT',
    name: 'Australian Eastern Daylight Saving Time',
    iana: 'Australia/Sydney',
    offset: '+11',
    momentName: 'AEDT',
    id: 'd1491819-9b1c-4064-82ca-feb462a121ec',
  },
  {
    abbr: 'LHST',
    name: 'Lord Howe Island Time',
    iana: 'Australia/Lord_Howe',
    offset: '+10:30',
    momentName: '+1030',
    id: '44909a43-d8db-4b6d-ac0d-a2cee6512ca0',
  },
  {
    abbr: 'ACDT',
    name: 'Australian Central Daylight Saving Time',
    iana: 'Australia/Adelaide',
    offset: '+10:30',
    momentName: 'ACDT',
    id: '0fabfabd-d62e-41d5-9b73-5e8ed8d7557d',
  },
  {
    abbr: 'AEST',
    name: 'Australian Eastern Standard Time',
    iana: 'Australia/Sydney',
    offset: '+10',
    momentName: 'AEST',
    id: '3efd02a0-37df-4c61-b96b-908f183b4b54',
  },
  {
    abbr: 'ACST',
    name: 'Australian Central Standard Time',
    iana: 'Australia/Adelaide',
    offset: '+9:30',
    momentName: 'ACST',
    id: 'd31fd87c-f8d7-46b4-a479-a33f773d1d74',
  },
  {
    abbr: 'KST',
    name: 'Korea Standard Time',
    iana: 'Asia/Seoul',
    offset: '+9',
    momentName: 'KST',
    id: 'e0d6d8f3-c8bd-4940-83f8-964f05fb350c',
  },
  {
    abbr: 'JST',
    name: 'Japan Standard Time',
    iana: 'Asia/Tokyo',
    offset: '+9',
    momentName: 'JST',
    id: 'b8b76b6b-7a86-4c34-94e6-b8ecf5c40116',
  },
  {
    abbr: 'ACWST',
    name: 'Australian Central Western Standard Time',
    iana: 'Australia/Eucla',
    offset: '+8:45',
    momentName: '+0845',
    id: 'eb6100ca-15c2-4946-935f-0af76072603c',
  },
  {
    abbr: 'CST',
    name: 'China Standard Time',
    iana: 'Asia/Shanghai',
    offset: '+8',
    id: '4b3ec9f2-7a8a-49a1-8eba-f171cbb914e5',
    momentName: 'CST',
  },
  {
    abbr: 'WIB',
    name: 'Western Indonesian Time',
    iana: 'Asia/Jakarta',
    offset: '+7',
    id: 'e1196fe9-87ca-462a-bbfb-3077efd8942f',
    momentName: 'WIB',
  },
  {
    abbr: 'CCT',
    name: 'Cocos Islands Time',
    iana: 'Indian/Cocos',
    offset: '+6:30',
    id: 'bbcd9316-7a5f-4afb-8589-634b84c2e05a',
    momentName: '+0630',
  },
  {
    abbr: 'IOT',
    name: 'Indian Chagos Time',
    iana: 'Indian/Chagos',
    offset: '+6',
    id: 'c17567e6-0bd0-4b07-a0f1-7a1a1013ad38',
    momentName: '+06',
  },
  {
    abbr: 'NPT',
    name: 'Nepal Time',
    iana: 'Asia/Kathmandu',
    offset: '+5:45',
    id: '52231102-c78e-4a54-b94a-098d4532c871',
    momentName: '+0545',
  },
  {
    abbr: 'IST',
    name: 'India Standard Time',
    iana: 'Asia/Colombo',
    offset: '+5:30',
    id: 'd265e64f-e5f3-4a43-906a-0083239d52e8',
    momentName: '+0530',
  },
  {
    abbr: 'PKT',
    name: 'Pakistan Standard Time',
    iana: 'Asia/Karachi',
    offset: '+5',
    id: 'e94d3542-9edf-429d-9de1-04930d333ab4',
    momentName: 'PKT',
  },
  {
    abbr: 'AFT',
    name: 'Afghanistan Time',
    iana: 'Asia/Kabul',
    offset: '+4:30',
    id: 'df47272a-812b-4d91-b852-c8b4443d92e3',
    momentName: '+0430',
  },
  {
    abbr: 'GST',
    name: 'Gulf Standard Time',
    iana: 'Asia/Dubai',
    offset: '+4',
    id: '970c6462-ddfe-47a5-bc14-1d364bb8380e',
    momentName: '+04',
  },
  {
    abbr: 'IRST',
    name: 'Iran Standard Time',
    iana: 'Asia/Tehran',
    offset: '+3:30',
    id: 'fecd33a1-455d-4c78-a087-f1c7b8a2f223',
    momentName: '+0330',
  },
  {
    abbr: 'EAT',
    name: 'East Africa Time',
    iana: 'Africa/Nairobi',
    offset: '+3',
    id: 'ec7c4ea5-3b49-4fdf-b022-36b5909c6bbf',
    momentName: 'EAT',
  },
  {
    abbr: 'EEST',
    name: 'Eastern European Summer Time',
    iana: 'Africa/Cairo',
    offset: '+3',
    id: '7b9797b8-52a9-48a0-8df8-4bdbe9aa076b',
    momentName: 'EEST',
  },
  {
    abbr: 'EET',
    name: 'Eastern European Time',
    iana: 'Africa/Cairo',
    offset: '+2',
    id: 'adccd7b1-87dc-44f7-ad84-47e520deeb8e',
    momentName: 'EET',
  },
  {
    abbr: 'CAT',
    name: 'Central Africa Time',
    iana: 'Africa/Maputo',
    offset: '+2',
    id: 'c0234ea1-4731-462e-9d27-35a40f281ccf',
    momentName: 'CAT',
  },
  {
    abbr: 'CEST',
    name: 'Central European Summer Time',
    iana: 'Europe/Brussels',
    offset: '+2',
    id: 'd12716ce-edaa-4ed6-8136-9ccf7869470b',
    momentName: 'CEST',
  },
  {
    abbr: 'CET',
    name: 'Central European Time',
    iana: 'Europe/Brussels',
    offset: '+1',
    id: 'a8cd5a15-ef90-471c-ab77-e67b1eb789b9',
    momentName: 'CET',
  },
  {
    abbr: 'BST',
    name: 'British Summer Time',
    iana: 'Europe/London',
    offset: '+1',
    id: '1e83db09-99d7-46f0-9f23-c1785fc5a4bc',
    momentName: 'BST',
  },
  {
    abbr: 'WEST',
    name: 'Western European Summer Time',
    iana: 'Europe/Lisbon',
    offset: '+1',
    id: '1f8904e3-b8b7-455e-b5ff-d96185d9b506',
    momentName: 'WEST',
  },
  {
    abbr: 'GMT',
    name: 'British Time',
    iana: 'Europe/London',
    offset: '0',
    id: 'a4f29391-2aaf-4093-b4f2-a8252aee1b65',
    momentName: 'GMT',
  },
  {
    abbr: 'WET',
    name: 'Western European Time',
    iana: 'Europe/Lisbon',
    offset: '0',
    id: '472631da-9cb1-4feb-a921-45c5823119fb',
    momentName: 'WET',
  },
  {
    abbr: 'CVT',
    name: 'Cape Verde Time',
    iana: 'Atlantic/Cape_Verde',
    offset: '-1',
    id: '467f19ed-c984-47a1-a291-f3b87298d2c8',
    momentName: '-01',
  },
  {
    abbr: 'PMDT',
    name: 'Pierre & Miquelon Daylight Time',
    iana: 'America/Miquelon',
    offset: '-2',
    id: 'a8ced118-4255-4d85-b7f2-d9b3369db2ab',
    momentName: '-02',
  },
  {
    abbr: 'NDT',
    name: 'Newfoundland Daylight Time',
    iana: 'America/St_Johns',
    offset: '-2:30',
    id: 'ac2aed48-52be-45c5-9177-4459e34106d9',
    momentName: 'NDT',
  },
  {
    abbr: 'PMST',
    name: 'Pierre & Miquelon Standard Time',
    iana: 'America/Miquelon',
    offset: '-3',
    id: '9cef434a-f384-49cb-b748-e18350032fc8',
    momentName: '-03',
  },
  {
    abbr: 'ART',
    name: 'Argentina Time',
    iana: 'America/Argentina/Buenos_Aires',
    offset: '-3',
    id: '1b0e32ed-0679-4133-a7d5-46269a684cb7',
    momentName: '-03',
  },
  {
    abbr: 'CLST',
    name: 'Chile Summer Time',
    iana: 'America/Santiago',
    offset: '-3',
    id: '880e6828-00aa-4fbf-ac6d-4cdbd29cf710',
    momentName: '-03',
  },
  {
    abbr: 'NST',
    name: 'Newfoundland Standard Time',
    iana: 'America/St_Johns',
    offset: '-3:30',
    id: 'de63c54c-5fdb-48d3-a077-dbff95b37611',
    momentName: 'NST',
  },
  {
    abbr: 'CLT',
    name: 'Chile Standard Time',
    iana: 'America/Santiago',
    offset: '-4',
    id: '8fc27228-1791-4026-b79d-1b87815f491e',
    momentName: '-04',
  },
  {
    abbr: 'AST',
    name: 'Atlantic Standard Time',
    iana: 'America/Puerto_Rico',
    offset: '-4',
    id: '4a83ae99-a0e3-4bc9-9472-95e91d7864a3',
    momentName: 'AST',
  },
  {
    abbr: 'EDT',
    name: 'Eastern Daylight Time',
    iana: 'America/New_York',
    offset: '-4',
    id: '4f66c5bd-18fb-41bd-bd4a-ca5636438646',
    momentName: 'EDT',
  },
  {
    abbr: 'EST',
    name: 'Eastern Standard Time',
    iana: 'America/New_York',
    offset: '-5',
    id: '421c6d7c-5e42-43cb-8787-0cd5aa70fa53',
    momentName: 'EST',
  },
  {
    abbr: 'CDT',
    name: 'Central Daylight Time',
    iana: 'America/Chicago',
    offset: '-5',
    id: '0c5a7beb-8610-41d5-a62d-5fe9d2142114',
    momentName: 'CDT',
  },
  {
    abbr: 'CST',
    name: 'Central Standard Time',
    iana: 'America/Chicago',
    offset: '-6',
    id: 'a8844d02-569c-48e8-8a97-7a2b76d5bb71',
    momentName: 'CST',
  },
  {
    abbr: 'MDT',
    name: 'Mountain Daylight Time',
    iana: 'America/Denver',
    offset: '-6',
    id: '79c39d44-f5f6-4606-95a5-1f599e235a07',
    momentName: 'MDT',
  },
  {
    abbr: 'MST',
    name: 'Mountain Standard Time',
    iana: 'America/Denver',
    offset: '-7',
    id: '3dc52e83-7152-40f1-ab3d-4cfdadb3ea6d',
    momentName: 'MST',
  },
  {
    abbr: 'MST',
    name: 'Phoenix',
    iana: 'America/Phoenix',
    offset: '-7',
    id: '0608e0ea-9cf2-40c9-9e0a-2bf8fa2e13ca',
    momentName: 'MST',
  },
  {
    abbr: 'PDT',
    name: 'Pacific Daylight Time',
    iana: 'America/Los_Angeles',
    offset: '-7',
    id: '7e4640b6-a31e-478a-8747-f75243e072fb',
    momentName: 'PDT',
  },
  {
    abbr: 'PST',
    name: 'Pacific Standard Time',
    iana: 'America/Los_Angeles',
    offset: '-8',
    id: 'efb9ee97-e3ac-4843-832e-01c342b262fa',
    momentName: 'PST',
  },
  {
    abbr: 'AKDT',
    name: 'Alaska Daylight Time',
    iana: 'America/Anchorage',
    offset: '-8',
    id: '843921e1-3c1f-4f14-aac9-d5c5d60f6fb8',
    momentName: 'AKDT',
  },
  {
    abbr: 'AKST',
    name: 'Alaska Standard Time',
    iana: 'America/Anchorage',
    offset: '-9',
    id: '1bce204f-933c-4189-925f-b700b8fece8d',
    momentName: 'AKST',
  },
  {
    abbr: 'HDT',
    name: 'Hawaii–Aleutian Daylight Time',
    iana: 'America/Adak',
    offset: '-9',
    id: '20165844-20c1-416a-90fd-073ee12a5a7d',
    momentName: 'HDT',
  },
  {
    abbr: 'MIT',
    name: 'Marquesas Islands Time',
    iana: 'Pacific/Marquesas',
    offset: '-9:30',
    id: 'b2df12dd-dcf6-461b-abf3-db6cd1c54ca1',
    momentName: '-0930',
  },
  {
    abbr: 'HST',
    name: 'Hawaii–Aleutian Standard Time',
    iana: 'Pacific/Honolulu',
    offset: '-10',
    id: '5cc44fd0-23e1-46b3-bcfa-64dd0fd8e2f7',
    momentName: 'HST',
  },
  {
    abbr: 'SST',
    name: 'Samoa Standard Time',
    iana: 'Pacific/Pago_Pago',
    offset: '-11',
    id: '0448fcdd-253d-45d9-a634-6a342952b3f1',
    momentName: 'SST',
  },
];

export const NO_TIMEZONE: TimeZone = {
  abbr: '000',
  name: 'No Where',
  iana: 'not/found',
  offset: '0',
  id: '00000000-0000-0000-0000-000000000000',
  momentName: '0',
};
