import React from 'react';
import styled from 'styled-components';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

type HeaderType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'div'
  | 'label'
  | 'span';

export interface CondensedLabelProps {
  as?: HeaderType;
  children?: React.ReactNode;
  className?: string;
  name?: string;
  maxLines?: number;
}

interface H6Props {
  as: HeaderType;
  children: React.ReactNode;
  dataTest: string;
}

const Label = ({ as, children, dataTest }: H6Props) =>
  React.createElement(as, { dataTest }, children);

const StyledLabel = styled(Label)``;

export const CondensedLabel = ({
  as = 'label',
  children = null,
  className = '',
  name = null,
  maxLines = null,
}: CondensedLabelProps) => (
  <StyledLabel
    as={as}
    className={AttrsHelper.formatClassname(
      'condensed-label',
      maxLines && `max-lines-${maxLines}`,
      className
    )}
    data-test={name}
  >
    {children}
  </StyledLabel>
);
