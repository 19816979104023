import React from 'react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import {
  FluidButtonLayout,
  FluidButtonLayoutProps,
} from '../FluidButtonLayout/FluidButtonLayout';

export const CardFooter = ({
  className,
  children,
  columnWidth,
  name,
  stackableOn,
}: {
  children?: React.ReactNode;
  className?: string;
  columnWidth?: FluidButtonLayoutProps['columnWidth'];
  name?: string;
  stackableOn?: FluidButtonLayoutProps['stackableOn'];
}) => {
  return (
    <FluidButtonLayout
      className={AttrsHelper.formatClassname(
        'card-footer-container',
        className
      )}
      columnWidth={columnWidth}
      name={name}
      stackableOn={stackableOn}
    >
      {children}
    </FluidButtonLayout>
  );
};
