import { z } from 'zod';
import { CurrencyMap } from '@axiom/const';

export const SchemaTypes = {
  ZodArray: 'SchemaArray',
  ZodBoolean: 'SchemaBoolean',
  ZodNumber: 'SchemaNumber',
  ZodObject: 'SchemaObject',
  ZodString: 'SchemaString',
  ZodEnum: 'SchemaEnum',
};

export const CustomSchemaTypes = {
  SchemaCurrency: 'SchemaCurrency',
  SchemaDate: 'SchemaDate',
  SchemaEmail: 'SchemaEmail',
  SchemaLocation: 'SchemaLocation',
  SchemaMonthYear: 'SchemaMonthYear',
  SchemaPassword: 'SchemaPassword',
  SchemaPhoneNumber: 'SchemaPhoneNumber',
  SchemaRate: 'SchemaRate',
  SchemaTime: 'SchemaTime',
  SchemaTimestamp: 'SchemaTimestamp',
  SchemaYear: 'SchemaYear',
};

/**
 * Custom Types
 */

export const SchemaPassword = z.string();
(SchemaPassword as ZodExtendType<unknown>)._customType =
  CustomSchemaTypes.SchemaPassword;

export const SchemaDate = z.string().regex(/^\d{4}-\d{2}-\d{2}$/);
(SchemaDate as ZodExtendType<unknown>)._customType =
  CustomSchemaTypes.SchemaDate;

export const SchemaMonthYear = z.string().regex(/^\d{4}-\d{2}-\d{2}$/);
(SchemaMonthYear as ZodExtendType<unknown>)._customType =
  CustomSchemaTypes.SchemaMonthYear;

export const SchemaTime = z.string().regex(/^\d{2}:\d{2}:\d{2}-\d{4}$/);
(SchemaTime as ZodExtendType<unknown>)._customType =
  CustomSchemaTypes.SchemaTime;

export const SchemaTimestamp = z
  .string()
  .regex(
    /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/
  );

(SchemaTimestamp as ZodExtendType<unknown>)._customType =
  CustomSchemaTypes.SchemaTimestamp;

export const SchemaYear = z.number().int().positive();
(SchemaYear as ZodExtendType<unknown>)._customType =
  CustomSchemaTypes.SchemaYear;

export const SchemaEmail = z
  .string()
  .email()
  .trim()
  .max(255)
  .transform(val => val?.toLowerCase());
(SchemaEmail as ZodExtendType<unknown>)._customType =
  CustomSchemaTypes.SchemaEmail;

/**
 * Here we check for digit count and non-approved-special characters
 * That was good enough for now (per TPM)
 * This isn't 100% for every pattern since we don't have that kinda time.
 * */
export const SchemaPhoneNumber = z
  .string()
  .trim()
  .refine(
    phoneNumber => {
      const approveSpecialCharacters = new Set([' ', '(', ')', '-', '+', 'x']);
      const numBits = [...phoneNumber];
      const { numberCount, characters } = numBits.reduce(
        (crt, bit) => {
          if (Number.parseInt(bit, 10) >= 0) {
            crt.numberCount++;
            return crt;
          }

          crt.characters.push(bit);
          return crt;
        },
        { numberCount: 0, characters: [] }
      );

      let isGood = numberCount >= 7 && numberCount <= 17;
      if (isGood && characters.length > 0) {
        isGood = characters.every(character =>
          approveSpecialCharacters.has(character.toLowerCase())
        );
      }

      return isGood;
    },
    {
      message:
        'Phone numbers must be 7-21 characters and can include only numbers, spaces, and these special characters: +, -, (, ), x.',
    }
  );
(SchemaPhoneNumber as ZodExtendType<unknown>)._customType =
  CustomSchemaTypes.SchemaPhoneNumber;

export const SchemaLocation = z.object({
  locationAddressComponents: z
    .object({
      addresses: z
        .array(
          z.object({
            types: z.array(z.string()),
            long_name: z.string(),
            short_name: z.string(),
          })
        )
        .nullish(),
    })
    .nullish(),
  locationLatitude: z.number().nullish(),
  locationLongitude: z.number().nullish(),
  locationName: z.string().nullish(),
  locationPlaceId: z.string().nullish(),
});
(SchemaLocation as ZodExtendType<unknown>)._customType =
  CustomSchemaTypes.SchemaLocation;

export const SchemaRate = z
  .string()
  .trim()
  .refine(
    value => {
      const bits = value.split(/[.,]/);

      const invalid = bits.some((bit, index, ary) => {
        let isInvalid = /(.*\D.*)/.test(bit);

        if (!isInvalid) {
          if (ary.length > 2) {
            if (index === 0) {
              isInvalid = bit.length === 0 || bit.length > 3;
            } else if (index === ary.length - 1) {
              isInvalid = bit.length !== 2;
            } else {
              isInvalid = bit.length !== 3;
            }
          } else if (ary.length > 1) {
            if (index === 1) {
              isInvalid = bit.length === 0;
            } else if (index === ary.length - 1) {
              isInvalid = bit.length !== 2;
            }
          }
        }

        return isInvalid;
      });

      return !invalid;
    },
    { message: 'This can only contain numbers, commas, and periods.' }
  );
(SchemaRate as ZodExtendType<unknown>)._customType =
  CustomSchemaTypes.SchemaRate;

export const SchemaCurrency = z.union([
  z.literal(CurrencyMap.codes.AUD),
  z.literal(CurrencyMap.codes.CAD),
  z.literal(CurrencyMap.codes.CHF),
  z.literal(CurrencyMap.codes.EUR),
  z.literal(CurrencyMap.codes.GBP),
  z.literal(CurrencyMap.codes.HKD),
  z.literal(CurrencyMap.codes.SGD),
  z.literal(CurrencyMap.codes.USD),
]);
(SchemaCurrency as ZodExtendType<unknown>)._customType =
  CustomSchemaTypes.SchemaCurrency;
