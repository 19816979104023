import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const ExclamationMarkCircleIcon = ({
  alt,
  className,
  height,
  name,
  width,
}) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 31 31"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <circle
      stroke="#F18805"
      strokeWidth="3"
      fill="#FFF"
      cx="15.5"
      cy="15.5"
      r="13.5"
    />
    <text
      fill="#F18805"
      fontFamily="Walsheim, GTWalsheimProBold, GT Walsheim Pro"
      fontSize="21"
      fontWeight="bold"
    >
      <tspan x="11.951" y="23">
        !
      </tspan>
    </text>
  </svg>
);

ExclamationMarkCircleIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

ExclamationMarkCircleIcon.defaultProps = {
  alt: null,
  className: null,
  height: 31,
  name: 'EXCLAMATION_ICON',
  width: 31,
};
