import React from 'react';
import { number, string } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const CloseIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <path d="M3.8 2L22 20.2 20.2 22 2 3.8z" />
    <path d="M22 3.8L3.8 22 2 20.2 20.2 2z" />
  </svg>
);

CloseIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

CloseIcon.defaultProps = {
  alt: null,
  className: null,
  height: 20,
  name: 'CLOSE_ICON',
  width: 20,
};
