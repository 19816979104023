import React from 'react';

import LoginPassword from '../components/Login/LoginPassword';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Page } from '../components/Page/Page';

export const InputPasswordPage = () => (
  <Page title="Axiom Law - Login">
    <DefaultLayout>
      <LoginPassword />
    </DefaultLayout>
  </Page>
);
InputPasswordPage.propTypes = {};
