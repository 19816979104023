import has from 'lodash/has';

import { ShowableError } from '../classes/showable-error';

export const StateComponentUtil = {
  hasErrors(states) {
    return (
      states && states.some(state => state.errors && state.errors.length > 0)
    );
  },
  getErrors(states) {
    /**
     * The errors were here get are Error objects but the error message is a strigified Error object.
     * When an error object is toString() 'Error: ' is appended to the string.
     * Only remove 'Error: ' if it is the beginning of the string.
     * */
    const toRemove = 'Error: ';
    const pattern = `^(${toRemove})`;
    const regEx = new RegExp(pattern);

    return (
      states &&
      states.reduce((acc, state) => {
        if (state.errors && state.errors.length > 0) {
          acc = [
            ...acc,
            ...state.errors.map(err => {
              const e = err.toString();
              return regEx.test(e) ? e.slice(toRemove.length - 1) : e;
            }),
          ];
        }

        return acc;
      }, [])
    );
  },
  isLoading(states) {
    return (
      !states ||
      states.some(state => !has(state, 'isLoading') || state.isLoading)
    );
  },
  hasLoaded(states) {
    return (
      states &&
      states.every(state => has(state, 'hasLoaded') && state.hasLoaded)
    );
  },
  shouldShowInitialLoader(states) {
    if (this.hasErrors(states)) {
      return false;
    }

    if (this.isLoading(states) && !this.hasLoaded(states)) {
      return true;
    }

    return false;
  },
  shouldShowBlockingLoader(states) {
    if (this.hasErrors(states)) {
      return false;
    }

    if (this.isLoading(states) && this.hasLoaded(states)) {
      return true;
    }

    return false;
  },
  shouldShowBlockingError(states) {
    if (!this.hasErrors(states)) {
      return false;
    }

    return states.some(
      state =>
        state.errors &&
        state.errors.some(error => {
          if (error instanceof ShowableError) {
            return false;
          }

          return (
            error.statusCode !== 400 ||
            !error.errors ||
            error.errors.some(errMsg => !(typeof errMsg === 'string'))
          );
        })
    );
  },
  shouldShowNonBlockingError(states) {
    if (!this.hasErrors(states)) {
      return false;
    }

    return states.every(
      state =>
        state.errors &&
        state.errors.length > 0 &&
        state.errors.every(error => {
          if (error instanceof ShowableError) {
            return true;
          }

          return (
            error.statusCode === 400 &&
            error.errors &&
            error.errors.every(errMsg => typeof errMsg === 'string')
          );
        })
    );
  },

  shouldShowComponent(states) {
    if (this.shouldShowBlockingError(states)) {
      return false;
    }

    if (!this.hasLoaded(states)) {
      return false;
    }

    return true;
  },
};
