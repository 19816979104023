import React from 'react';
import { number, string } from 'prop-types';

import { AxiomTheme } from '../../../theme/axiom-theme';
import { DataTestUtil } from '../../../utils/data-test-util';

export const WarningIcon = ({
  alt,
  backgroundColor,
  color,
  className,
  height,
  name,
  width,
}) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 35 32"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <polygon
      stroke={backgroundColor}
      fill={backgroundColor}
      points="17.5 0 34 31.5 1 31.5"
    />
    <text
      fill={color}
      fontFamily="Walsheim, GTWalsheimProMedium, GT Walsheim Pro"
      fontSize="24"
      fontWeight="400"
    >
      <tspan x="14.167" y="28.25">
        !
      </tspan>
    </text>
  </svg>
);

WarningIcon.propTypes = {
  alt: string,
  backgroundColor: string,
  color: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

WarningIcon.defaultProps = {
  alt: null,
  backgroundColor: AxiomTheme.colors.axiomOrange,
  color: '#fff',
  className: null,
  height: 19,
  name: 'WARNING_ICON',
  width: 25,
};
