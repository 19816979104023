import styled from 'styled-components';

export const CheckboxWithLabelWrapper = styled.div`
  cursor: pointer;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  height: fit-content;
`;

export const CWLLabel = styled.label`
  margin-left: 1rem;
  margin-bottom: 0;
  font-size: 0.95rem;
  ${({ bold }) => bold && `font-weight: bold`};
`;
