import React from 'react';
import styled from 'styled-components';

export interface DividerProps {
  character?: string;
  children: React.ReactNode;
  name?: string;
}

const StyledDivider = styled.span`
  margin: 0 3px;
  display: inline-block;
`;

export const Divider = ({
  character = '\u2022',
  children = [],
  name = '',
}: DividerProps): JSX.Element => {
  const childArray = [...React.Children.toArray(children)];
  return (
    <div data-test={name}>
      {childArray.reduce((acc, child, index, list) => {
        if (child || child === 0) {
          const Group = (
            <span key={`group-${index.toString()}`}>
              {child}
              {index < list.length - 1 && (
                <StyledDivider>{`${character}`}</StyledDivider>
              )}
            </span>
          );
          if (Array.isArray(acc)) {
            acc.push(Group);
          }
        }

        return acc;
      }, [])}
    </div>
  );
};
