import React from 'react';
import styled from 'styled-components';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

type ElementType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'div'
  | 'span';

interface H4Props {
  as: string;
  children: React.ReactNode;
  dataTest: string;
}

export interface SectionHeaderProps {
  as?: ElementType;
  children?: React.ReactNode;
  className?: string;
  name?: string;
  maxLines?: number;
}

const H4 = ({ as, children, dataTest }: H4Props) =>
  React.createElement(as, { dataTest }, children);

const StyledH4 = styled(H4)``;

export const SectionHeader = ({
  as = 'h4',
  children,
  className = '',
  name,
  maxLines,
}: SectionHeaderProps) => (
  <StyledH4
    as={as}
    className={AttrsHelper.formatClassname(
      'section-header',
      maxLines && `max-lines-${maxLines}`,
      className
    )}
    data-test={name}
  >
    {children}
  </StyledH4>
);
