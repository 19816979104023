import styled from 'styled-components';

export const FrameBlocker = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f0f2f1;
`;

export const ContentWrapper = styled.div`
  flex: 0;
  height: 200px;
  width: 400px;

  img {
    display: block;
    margin: 2em auto;
  }
`;
