import Superagent from 'superagent';
import qs, { stringify } from 'qs';

import { ApiError } from './api-error';
import { BadRequestError } from './bad-request-error';
import { ForbiddenError } from './forbidden-error';
import { UnauthorizedError } from './unauthorized-error';
import { RequestProps, ResponseProps } from './api-types';
import { UnprocessableEntityError } from './unprocessable-entity-error';
import { InternalServerError } from './internal-server-error';
import { BadGatewayError } from './bad-gateway-error';
import { GoneError } from './gone-error';

/**
 * For the Java API, objects need to be an encoded JSON string. Here, we extract and
 * encode each object and append it to the existing query string
 * @param {*} query The query string containing the potential object(s) to be
 * encoded
 */
const prepareQuery = (query: Record<string, unknown>, encodeQuery: boolean) => {
  if (!query) {
    return '';
  }

  if (!encodeQuery) {
    return `?${qs.stringify(query)}`;
  }
  const serializedObjects = [];
  const updatedQuery: Record<string, unknown> = {};

  for (const property in query) {
    if (query[property] && typeof query[property] === 'object') {
      serializedObjects.push(
        [property, encodeURIComponent(JSON.stringify(query[property]))].join(
          '='
        )
      );
    } else {
      updatedQuery[property] = query[property];
    }
  }

  return `?${[stringify(updatedQuery), ...serializedObjects].join('&')}`;
};

export async function apiRequest<ResponseType>({
  cookieTokenRefresher,
  domain,
  encodeQuery = true,
  endpoint,
  method,
  body,
  query,
  headers,
}: RequestProps): Promise<ResponseType> {
  try {
    if (cookieTokenRefresher) {
      cookieTokenRefresher.resetTimeout();
    }

    const fullDomain = `${domain}${endpoint}${prepareQuery(
      query,
      encodeQuery
    )}`;
    const requestObj = Superagent(method.toUpperCase(), fullDomain)
      .set({
        ...(body instanceof FormData
          ? {}
          : { 'Content-Type': 'application/json' }),
        ...headers,
      })
      .withCredentials();

    const response = await requestObj.send(body);
    if (response?.body?.redirect) {
      const rebody = (await apiRequest({
        endpoint: response.body.redirect,
        method,
        body,
        headers,
      } as RequestProps)) as ResponseType & { redirected: string };
      rebody.redirected = response.body.redirect;
      return rebody;
    }
    return response.body;
  } catch (e) {
    if (!e || !e.response) {
      throw new Error(e);
    }

    const { response } = e;

    const { body: errBody, status } = response;
    const obj: ResponseProps = {
      endpoint,
      method,
      status,
      body: errBody || {},
      headers,
      response,
    };

    let error;
    switch (status) {
      case 400: {
        error = new BadRequestError(obj);
        break;
      }
      case 403: {
        error = new ForbiddenError(obj);
        break;
      }
      case 401: {
        error = new UnauthorizedError(obj);
        break;
      }
      case 410: {
        error = new GoneError(obj);
        break;
      }
      case 422: {
        error = new UnprocessableEntityError(obj);
        break;
      }
      case 500: {
        error = new InternalServerError(obj);
        break;
      }
      case 502: {
        error = new BadGatewayError(obj);
        break;
      }
      default: {
        error = new ApiError(obj);
      }
    }
    throw error;
  }
}
