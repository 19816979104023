import { Enum } from 'enumify';

export const AfcRejectionLossCodes = {
  ExperienceOverqualified: 'ExperienceOverqualified',
  ExperienceUnderqualified: 'ExperienceUnderqualified',
  ExperienceLackingIndustrySpecificExperience:
    'ExperienceLackingIndustrySpecificExperience',
  CostTooExpensive: 'CostTooExpensive',
  ConflictOfInterest: 'ConflictOfInterest',
  TimeZoneLocation: 'TimeZoneLocation',
  IncorrectLanguage: 'IncorrectLanguage',
  QualifiedCandidateThatWasNotSelected: 'QualifiedCandidateThatWasNotSelected',
  ProfileNeedsMoreDetail: 'ProfileNeedsMoreDetail',
  Other: 'Other',
};

const AfcLossReasonLabels = {
  ExperienceOverqualified: 'Qualifications exceed our requirements',
  ExperienceUnderqualified: 'Looking for more experience',
  ExperienceLackingIndustrySpecificExperience:
    'Looking for more experience in our industry',
  CostTooExpensive: "Rate doesn't work with our budget",
  ConflictOfInterest: 'Possible conflict of interest',
  TimeZoneLocation:
    "Talent location or time zone doesn't work well for our business needs",
  IncorrectLanguage: 'Missing required language skills',
  QualifiedCandidateThatWasNotSelected:
    'Selected a different Axiom lawyer or legal pro',
  ProfileNeedsMoreDetail: 'Profile is incomplete',
  Other: 'Other',
};

const AfcLossReasonMessage = {
  ExperienceOverqualified:
    "The client decided not to interview you for this opportunity because your profile doesn't match up well with the required level of experience.",
  ExperienceUnderqualified:
    'The client decided not to interview you for this opportunity because they are looking for a little more experience.',
  ExperienceLackingIndustrySpecificExperience:
    'The client decided not to interview you for this opportunity because they need someone whose background includes more relevant industry experience.',
  CostTooExpensive:
    'The client decided not to interview you for this opportunity due to budget constraints.',
  ConflictOfInterest:
    'The client decided not to interview you for this opportunity due to a possible conflict of interest with something in your background or experience.',
  TimeZoneLocation:
    "The client decided not to interview you for this opportunity because your location or time zone doesn't match up with the client's business needs.",
  IncorrectLanguage:
    "The client decided not to interview you for this opportunity because your profile doesn't indicate that you have the required language skills.",
  QualifiedCandidateThatWasNotSelected:
    'The client was impressed with your qualifications but decided to move forward with another Axiom legal talent for this opportunity.',
  ProfileNeedsMoreDetail:
    'The client decided not to interview you for this opportunity because your Axiom profile is incomplete.',
  Other:
    'The client decided not to interview you for this opportunity but did not provide a reason.',
};

class AfcRejectionLossCodesEnum extends Enum {}
AfcRejectionLossCodesEnum.initEnum({
  [AfcRejectionLossCodes.ExperienceOverqualified]: {
    v: AfcLossReasonLabels.ExperienceOverqualified,
  },
  [AfcRejectionLossCodes.ExperienceUnderqualified]: {
    v: AfcLossReasonLabels.ExperienceUnderqualified,
  },
  [AfcRejectionLossCodes.ExperienceLackingIndustrySpecificExperience]: {
    v: AfcLossReasonLabels.ExperienceLackingIndustrySpecificExperience,
  },
  [AfcRejectionLossCodes.CostTooExpensive]: {
    v: AfcLossReasonLabels.CostTooExpensive,
  },
  [AfcRejectionLossCodes.ConflictOfInterest]: {
    v: AfcLossReasonLabels.ConflictOfInterest,
  },
  [AfcRejectionLossCodes.TimeZoneLocation]: {
    v: AfcLossReasonLabels.TimeZoneLocation,
  },
  [AfcRejectionLossCodes.IncorrectLanguage]: {
    v: AfcLossReasonLabels.IncorrectLanguage,
  },
  [AfcRejectionLossCodes.QualifiedCandidateThatWasNotSelected]: {
    v: AfcLossReasonLabels.QualifiedCandidateThatWasNotSelected,
  },
  [AfcRejectionLossCodes.ProfileNeedsMoreDetail]: {
    v: AfcLossReasonLabels.ProfileNeedsMoreDetail,
  },
  [AfcRejectionLossCodes.Other]: {
    v: AfcLossReasonLabels.Other,
  },
});
AfcRejectionLossCodesEnum.v = v =>
  AfcRejectionLossCodesEnum.enumValues.find(x => x.v === v);

export const ClientRequestConst = {
  RequestTypes: {
    ClientPassed: 'ClientPassed',
    HireRequested: 'HireRequested',
    InterviewRequested: 'InterviewRequested',
  },
  AfcRejectionLossCode: AfcRejectionLossCodesEnum,
  AfcRejectionLossCodes,
  AfcLossReasonLabels,
  AfcLossReasonMessage,
  // field names
  CLIENT_REQUEST: 'clientRequest',
} as const;

export type ClientRequestConstKeyTypes = keyof typeof ClientRequestConst;
export type ClientRequestConstValueTypes =
  (typeof ClientRequestConst)[ClientRequestConstKeyTypes];

export type ClientRequestTypesRejectionLossCodesKeyTypes =
  keyof typeof ClientRequestConst.AfcRejectionLossCodes;
export type ClientRequestTypesRejectionLossCodesValueTypes =
  (typeof ClientRequestConst.AfcRejectionLossCodes)[ClientRequestTypesRejectionLossCodesKeyTypes];

export type ClientRequestTypesKeyTypes =
  keyof typeof ClientRequestConst.RequestTypes;
export type ClientRequestTypesValueTypes =
  (typeof ClientRequestConst.RequestTypes)[ClientRequestTypesKeyTypes];
