import styled from 'styled-components';
import { Text } from '@axiom/ui';

import { PALETTE } from '../../themes/constants';

export const WelcomeWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 45px 0; /* Matches parent section's horizontal padding */
  text-align: center;
`;

export const WelcomeSection = styled.div`
  padding: 2rem 0;
`;

export const WelcomeDivider = styled.hr`
  background-color: #fff;
  width: 100%;
`;

export const WelcomeText = styled(Text)`
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  ${({ thin }) =>
    thin &&
    `
    font-weight: 100;
    text-align: left;
  `}

  a {
    border-bottom: 2px solid ${PALETTE.theme.primary};
    color: inherit;
    font-weight: bold;

    &:focus,
    &:hover,
    &:active {
      border-bottom-color: currentColor;
      text-decoration: none;
    }

    ::before {
      content: '\\A';
      white-space: pre;
    }
  }
`;
