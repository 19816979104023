import { isSafari } from '@axiom/ui';

import Cookie from '../utils/browser-cookies';
import GenericStore from '../classes/generic-store';
import { EnvUtil } from '../utils/env-util';
import { loginWithToken } from '../api/auth/auth';

class TokenLoginStoreClass extends GenericStore {
  load(token) {
    return this.clearState(
      loginWithToken(token)
        .then(jwt => {
          const isBrowserSafari = isSafari();
          Cookie('user', jwt, {
            domain: `${EnvUtil.cookieDomain}`,
            path: '/',
            SameSite: isBrowserSafari ? 'None' : 'Lax',
            secure: isBrowserSafari,
          });
          window.location.href = `/sso${window.location.search}`;
        })
        .catch(() => {
          window.location.href = '/link-expired';
        })
    );
  }
}

export const TokenLoginStore = new TokenLoginStoreClass();
