import React from 'react';
import { node } from 'prop-types';

import { WarningIcon } from '../../icons/WarningIcon/WarningIcon';
import { Gutter } from '../../layout/Gutter/Gutter';

export const Error = ({ children }) => (
  <Gutter vertical="0.5rem" className="text-align-middle">
    <WarningIcon />
    <span>{children}</span>
  </Gutter>
);

Error.propTypes = {
  children: node,
};

Error.defaultProps = {
  children: 'An error occurred. Please refresh.',
};
