import React from 'react';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { Button, Gutter, Label } from '@axiom/ui';

import CommonCard from '../CommonCard/CommonCard';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { FFInput } from '../FFInput/FFInput';
import { FormManualLoginStore } from '../../stores/form-manual-login-store';
import { StateComponentUtil } from '../../utils/state-component-util';
import { ErrorBlurb } from '../ErrorBlurb/ErrorBlurb';

import {
  LFooterButtonsWrapper,
  LPEmailWrapper,
  LPEmail,
  LPPasswordWrapper,
  LPWrapper,
} from './LoginStyles';

const LoginPassword = ({ loginFormState }) => {
  const navigate = useNavigate();
  return (
    <StoreStateTreatment
      nonBlockingErrorComponent={null}
      storeState={loginFormState}
      renderLoadedView={({ data }) => (
        <LPWrapper data-test="LOGIN_PASSWORD">
          <CommonCard
            title="Enter your password"
            goBackText="SIGN-IN PAGE"
            handleGoBack={() => FormManualLoginStore.cancel()}
          >
            <Form
              onSubmit={formData => FormManualLoginStore.submit(formData)}
              initialValues={data}
              validate={formData => FormManualLoginStore.validate(formData)}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} data-test="LOGIN_PASSWORD_FORM">
                  <LPEmailWrapper>
                    <Label data-test="STATIC_EMAIL">Email Address</Label>
                    <LPEmail>{data.email}</LPEmail>
                  </LPEmailWrapper>
                  <LPPasswordWrapper>
                    <Label>Password</Label>
                    <Field
                      name="password"
                      type="password"
                      component={FFInput}
                      data-test="PASSWORD"
                    />
                  </LPPasswordWrapper>
                  <LFooterButtonsWrapper>
                    <Gutter bottom="1rem">
                      <Button
                        pattern="primary"
                        type="submit"
                        className="gtm-login-sign-in-button"
                        name="SUBMIT"
                      >
                        Sign in
                      </Button>
                    </Gutter>
                    <Button
                      pattern="primary"
                      variation="minimal"
                      onClick={() => {
                        FormManualLoginStore.clearState(
                          navigate(
                            `/request-password?email=${encodeURIComponent(
                              data.email
                            )}`
                          )
                        );
                      }}
                      name="REQUEST_PASSWORD_LINK"
                    >
                      Set or reset your password
                    </Button>
                  </LFooterButtonsWrapper>
                  <div data-test="ERRORS">
                    {StateComponentUtil.hasErrors([loginFormState]) && (
                      <ErrorBlurb
                        errorMessage={StateComponentUtil.getErrors([
                          loginFormState,
                        ]).join(', ')}
                      />
                    )}
                  </div>
                  <Gutter bottom="16px" />
                </form>
              )}
            />
          </CommonCard>
        </LPWrapper>
      )}
    />
  );
};

LoginPassword.propTypes = {
  loginFormState: FormManualLoginStore.getStateShape().isRequired,
};

export default connect(state => ({
  loginFormState: FormManualLoginStore.select(state),
}))(LoginPassword);
