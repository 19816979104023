import React from 'react';
import { oneOf, node, string } from 'prop-types';
import styled from 'styled-components';

import { DataTestUtil } from '../../../utils/data-test-util';

const RawHighlight = ({ as, children, dataTest }) =>
  React.createElement(as, { dataTest }, children);

RawHighlight.propTypes = {
  as: string.isRequired,
  children: node.isRequired,
  dataTest: string.isRequired,
};

export const HighlightSketchStyles = ({ theme }) => ({
  fontSize: '2.5rem', // * 16px = 40px
  fontWeight: 'bold',
  color: theme.text.highlight,
  lineHeight: '2.8125rem', // * 16px = 45px
});

const StyledRawHighlight = styled(RawHighlight)`
  ${props => HighlightSketchStyles(props)}
`;

export const Highlight = ({ as, children, className, name }) => (
  <StyledRawHighlight
    as={as}
    className={className}
    data-test={DataTestUtil.format(name)}
  >
    {children}
  </StyledRawHighlight>
);

Highlight.propTypes = {
  as: oneOf(['div', 'span']),
  children: node,
  className: string,
  name: string,
};

Highlight.defaultProps = {
  as: 'div',
  children: null,
  className: '',
  name: null,
};
