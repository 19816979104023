import React from 'react';
import { number, string } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const ResetIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 28 26"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <path d="M15 0c7.18 0 13 5.82 13 13s-5.82 13-13 13a12.997 12.997 0 01-11.38-6.71l2.993.001A10.468 10.468 0 0015 23.484c5.79 0 10.484-4.694 10.484-10.484S20.79 2.516 15 2.516c-5.072 0-9.303 3.602-10.274 8.387H2.168C3.171 4.721 8.534 0 15 0z" />
    <polygon points="3.65,14 7.15,10 0.15,10" />
  </svg>
);

ResetIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

ResetIcon.defaultProps = {
  alt: null,
  className: null,
  height: 16,
  name: 'RESET_ICON',
  width: 18,
};
