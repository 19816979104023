import React from 'react';
import styled from 'styled-components';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { FormLabel } from '../../content/FormLabel/FormLabel';

const RawToggleWrapper = styled.div``;

export type RawToggleValueType = boolean;

export type RawToggleProps = {
  disabled: boolean;
  id?: string;
  name: string;
  onChange?: (value: boolean) => void;
  value: RawToggleValueType;
};

export const RawToggle = ({
  disabled,
  id,
  name,
  onChange,
  value,
}: RawToggleProps) => {
  const handleOnClick = () => {
    if (!disabled) {
      onChange(!value);
    }
  };

  return (
    <RawToggleWrapper
      className={AttrsHelper.formatClassname('toggle', disabled && 'disabled')}
      id={id}
      tabIndex={0}
      data-test={name}
      onClick={handleOnClick}
    >
      <span
        className={AttrsHelper.formatClassname(
          'toggle-slider',
          !value && 'toggle-transition-right'
        )}
      />
      <span
        className={AttrsHelper.formatClassname(
          'toggle-option',
          value && 'active'
        )}
        data-test={`${name}-yes`}
      >
        <FormLabel className="toggle-label">Yes</FormLabel>
      </span>
      <span
        className={AttrsHelper.formatClassname(
          'toggle-option',
          !value && 'active'
        )}
        data-test={`${name}-no`}
      >
        <FormLabel className="toggle-label">No</FormLabel>
      </span>
    </RawToggleWrapper>
  );
};
