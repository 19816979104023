import styled from 'styled-components';

import { PALETTE } from '../../themes/constants';

export const ErrorBoundaryLayoutWrapper = styled.div`
  text-align: center;
  padding: 100px 15%;
`;
export const ErrorBoundarySorryText = styled.h2`
  color: ${PALETTE.theme.textMuted};
  line-height: 4rem;
`;
