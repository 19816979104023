import { Subject } from 'rxjs';

import { ResponsiveConfig } from '../../../configs/responsive-config';

export type SidebarProps = {
  content: React.ReactElement;
  direction: 'left' | 'right';
  name: string;
  only?: keyof typeof ResponsiveConfig.viewports;
};

let sidebars: Record<string, Array<SidebarProps>> = { left: [], right: [] };
const SidebarEvents = new Subject();

const filterOutSidedrawer = (name: string) => {
  sidebars.left = sidebars.left.filter(sidebar => sidebar.name !== name);
  sidebars.right = sidebars.right.filter(sidebar => sidebar.name !== name);
};

export const SidedrawerUtil = {
  listen(evt: () => void) {
    return SidebarEvents.subscribe({ next: () => evt() });
  },
  getAll() {
    return sidebars;
  },
  isOpen() {
    return !!(sidebars.left?.length > 0 || sidebars.right?.length > 0);
  },
  show(sidebar: SidebarProps) {
    filterOutSidedrawer(sidebar.name);
    sidebars[sidebar.direction].push(sidebar);
    SidebarEvents.next(sidebar.name);
  },
  showOnly(sidebar: SidebarProps) {
    sidebars = { left: [], right: [] };
    SidedrawerUtil.show(sidebar);
  },
  remove(name: string) {
    filterOutSidedrawer(name);
    SidebarEvents.next(name);
  },
  removeAll() {
    sidebars = { left: [], right: [] };
    SidebarEvents.next(null);
  },
};
