import styled from 'styled-components';
import { bool, node } from 'prop-types';

export const FlexiCardGridBody = styled.div.attrs(props => ({
  className: `flexi-card-body ${props.className || ''}`.trim(),
}))`
  background-color: ${({ theme }) => theme.backgrounds.primary};
  & > * {
    ${({ divided, theme }) => {
      return divided
        ? {
            borderBottom: `1px solid ${theme.neutrals.neutral30}`,
            height: '100%',
          }
        : '';
    }}
`;

FlexiCardGridBody.propTypes = {
  children: node,
  divided: bool,
};

FlexiCardGridBody.defaultProps = {
  children: null,
  divided: false,
};
