import styled, { css } from 'styled-components';

import { MediaQueryUtil } from '../../../utils/media-query-util';

export const HeaderBlock = styled.div`
  padding: 5px 0;
  background-color: ${({ theme }) => theme.colors.panelLight};
  border-bottom: 1px solid ${({ theme }) => theme.colors.contentBreak};
`;

export const HeaderLinkSection = styled.div`
  display: flex;
  column-gap: 32px;
  border-right: 1px solid ${({ theme }) => theme.colors.contentBreak};

  padding-right: 16px;

  ${MediaQueryUtil.smallScreenOnly(css`
    flex-direction: column;
    border-right-width: 0;
    row-gap: 24px;
    & > *:last-child {
      margin-bottom: 24px;
    }
    border-bottom: 1px solid ${({ theme }) => theme.colors.contentBreak};
    width: 100%;
  `)}
`;

export const HeaderUserSection = styled.div`
  margin-left: 16px;
  display: flex;
  align-items: center;

  ${MediaQueryUtil.smallScreenOnly(css`
    margin-left: 0;
    margin-top: 24px;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 24px;
  `)};
`;

export const HeaderLinkBlock = styled.div<{ selected?: boolean }>`
  &,
  a,
  &:hover,
  a:hover {
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.textDefault};
    line-height: 24px;
    font-weight: 700;
    text-decoration: none !important;
  }
  & {
    ${({ selected, theme }) =>
      selected && { borderBottom: `3px solid ${theme.colors.controlPrimary}` }};
  }

  &:hover,
  a:hover {
    color: ${({ theme }) => theme.colors.textLink};
  }
`;

export const HeaderMenuBlock = styled.div`
  display: flex;
  align-items: center;
  ${MediaQueryUtil.smallScreenOnly(css`
    transition: all 0.5s ease-in-out;
    position: fixed;
    top: 66px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 200;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 24px;
    &:not(.mobile-menu-open) {
      display: none;
    }
  `)};
  ${MediaQueryUtil.tabletOnly(css`
    padding-left: ${({ theme }) => theme.spacing.tabletPageBleed};
    padding-right: ${({ theme }) => theme.spacing.tabletPageBleed};
  `)};

  ${MediaQueryUtil.mobileOnly(css`
    padding-left: ${({ theme }) => theme.spacing.mobilePageBleed};
    padding-right: ${({ theme }) => theme.spacing.mobilePageBleed};
  `)};
`;

export const HeaderMobileMenuButton = styled.button`
  & {
    height: 24px;
    width: 30px;
    cursor: pointer;
    position: relative;
    border: 0;
    background: transparent;
    margin-right: 0 !important;
  }
  &:focus {
    outline: none;
  }

  & > span,
  & > span:before,
  & > span:after {
    content: '';
    height: 4px;
    width: 30px;
    background-color: #ff5a2d;
    position: absolute;
    left: 0;
    -webkit-transition: all ease-in-out 0.05s;
    -moz-transition: all ease-in-out 0.05s;
    -o-transition: all ease-in-out 0.05s;
    -ms-transition: all ease-in-out 0.05s;
    transition: all ease-in-out 0.05s;
    transform: rotate(0deg);
  }

  & > span {
    top: 10px;
  }

  & > span:before {
    display: block;
    top: -9px;
    animation-name: uncrossTop;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
  }

  & > span:after {
    display: block;
    bottom: -9px;
    animation-name: uncrossBottom;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
  }

  &.mobile-menu-open > span {
    background-color: transparent;
  }

  &.mobile-menu-open > span:before {
    animation-name: crossTop;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
  }

  &.mobile-menu-open > span:after {
    animation-name: crossBottom;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
  }

  @keyframes uncrossTop {
    0% {
      top: 0;
      transform: rotate(45deg);
    }
    50% {
      top: 0;
      transform: rotate(0deg);
    }
    100% {
      top: -9px;
      transform: rotate(0deg);
    }
  }
  @keyframes crossTop {
    0% {
      top: -9px;
      transform: rotate(0deg);
    }
    50% {
      top: 0;
      transform: rotate(0deg);
    }
    100% {
      top: 0;
      transform: rotate(45deg);
    }
  }
  @keyframes uncrossBottom {
    0% {
      bottom: 0;
      transform: rotate(-45deg);
    }
    50% {
      bottom: 0;
      transform: rotate(0deg);
    }
    100% {
      bottom: -9px;
      transform: rotate(0deg);
    }
  }
  @keyframes crossBottom {
    0% {
      bottom: -9px;
      transform: rotate(0deg);
    }
    50% {
      bottom: 0;
      transform: rotate(0deg);
    }
    100% {
      bottom: 0;
      transform: rotate(-45deg);
    }
  }
`;
