import React from 'react';

import { DefaultLayout } from '../layouts/DefaultLayout';
import TermsOfUse from '../components/TermsOfUse/TermsOfUse';
import { Page } from '../components/Page/Page';

export const TermsOfUsePage = () => (
  <Page title="Axiom Law - Terms of Use">
    <DefaultLayout>
      <TermsOfUse />
    </DefaultLayout>
  </Page>
);
