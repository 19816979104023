import React, { useState, useEffect, useMemo } from 'react';
import { ReplaySubject } from 'rxjs';

import { FormGroupLabel } from './FormGroupLabel';
import { FormGroupContext } from './FormGroupContext';
import { FormGroupError } from './FormGroupError';

export type FormGroupProps = {
  children?: React.ReactNode;
  description?: React.ReactNode;
  label?: React.ReactNode;
  name?: string;
  Tooltip?: React.ReactNode;
};

export const FormGroup = ({
  children,
  description,
  label,
  name,
  Tooltip,
}: FormGroupProps) => {
  /**
   * Reason for ReplaySubject:
   * When each of the children inside a FormGroup render, they then tell FormGroup what
   * their name field is so that we can group error messages together with the FormGroup.
   *
   * FormGroupError listens for those to then show errors for those properties when they
   * enter an error state.
   *
   * If we do just a setState in the parent here, react complains about children
   * affecting the render of the parent which would then cause two renders in the child.
   */
  const [propInitializer] = useState(new ReplaySubject());

  useEffect(() => {
    if (name) {
      propInitializer.next(name);
    }
  }, []);

  const formGroupProviderValue = useMemo(
    () => ({
      initialize: (propName: string) => {
        propInitializer.next(propName);
      },
    }),
    [propInitializer]
  );

  return (
    <div data-test={`${name}-form-field`} className="form-field">
      <FormGroupContext.Provider value={formGroupProviderValue}>
        <FormGroupLabel
          propInitializer={propInitializer}
          description={description}
          label={label}
          name={name}
          Tooltip={Tooltip}
        />
        {children}
      </FormGroupContext.Provider>
      <FormGroupError propInitializer={propInitializer} />
    </div>
  );
};
