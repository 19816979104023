import { bool, func, node, string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@axiom/ui';

import { formatDataTestId } from '../../utils/dataTest';

import {
  CCPageWrapper,
  CCWrapper,
  CCBody,
  CCGoBackWrapper,
  CCFooter,
  CCIconWrap,
  CCHeader,
  CCTitle,
} from './CommonCardStyles';

const CommonCard = ({
  center,
  children,
  className,
  footer,
  goBackLink,
  goBackClassName,
  goBackText,
  handleGoBack,
  icon,
  name,
  title,
  wide,
}) => {
  let backButton = null;

  if (goBackText) {
    const isInternal = goBackLink && !goBackLink.startsWith('http');

    backButton = isInternal ? (
      <Link
        to={goBackLink}
        onClick={handleGoBack}
        data-test={formatDataTestId('GO_BACK')}
        className={`${goBackClassName} text-align-middle`}
      >
        <Icon name="arrow-left" />
        {goBackText}
      </Link>
    ) : (
      <a
        onClick={handleGoBack}
        href={goBackLink}
        tabIndex={0}
        data-test={formatDataTestId('GO_BACK')}
        className={`${goBackClassName} text-align-middle`}
      >
        <Icon name="arrow-left" />
        {goBackText}
      </a>
    );
  }

  return (
    <CCPageWrapper
      className={className}
      data-test={formatDataTestId(name || title)}
    >
      <CCWrapper hasFooter={footer} isWide={wide}>
        <div>
          <CCGoBackWrapper>{backButton}</CCGoBackWrapper>

          <CCBody isCentered={center}>
            <CCHeader>
              {icon && <CCIconWrap>{icon}</CCIconWrap>}
              <CCTitle>{title}</CCTitle>
            </CCHeader>

            {children}
          </CCBody>
        </div>

        {footer && <CCFooter>{footer}</CCFooter>}
      </CCWrapper>
    </CCPageWrapper>
  );
};

CommonCard.defaultProps = {
  center: false,
  children: null,
  className: null,
  footer: null,
  goBackLink: null,
  goBackClassName: null,
  goBackText: null,
  handleGoBack: null,
  name: null,
  icon: null,
  wide: false,
};

CommonCard.propTypes = {
  center: bool,
  children: node,
  className: string,
  footer: node,
  goBackLink: string,
  goBackClassName: string,
  goBackText: string,
  handleGoBack: func,
  icon: node,
  name: string,
  title: node.isRequired,
  wide: bool,
};

export default CommonCard;
