import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const EducationPlaceholderIcon = ({
  alt,
  className,
  height,
  name,
  width,
}) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 132 68"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <g fill="#BEBEBE">
      <path d="M98.978 53.121c.015.174.022.346.022.517 0 7.732-15.222 14-34 14s-34-6.268-34-14c0-.17.007-.342.022-.516L31 53.116V36.368l29.803 9.526c1.862.737 4.279.737 7.249 0 2.97-.738 13.286-3.657 30.948-8.756v15.978l-.022.005zM16 43.535A3.998 3.998 0 0 0 14 47c0 1.48.804 2.773 2 3.465V51h4v-.535A3.998 3.998 0 0 0 22 47c0-1.48-.804-2.773-2-3.465V25h-4v18.535z" />
      <path d="M2.007 19.563L64.395.188a2 2 0 0 1 1.208.007l64.693 20.927a2 2 0 0 1-.058 3.824L65.591 43.708a2 2 0 0 1-1.176-.019L1.982 23.375a2 2 0 0 1 .025-3.812zM17.5 44l3.835 14.488A2 2 0 0 1 19.402 61h-3.804a2 2 0 0 1-1.933-2.512L17.5 44z" />
    </g>
  </svg>
);

EducationPlaceholderIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

EducationPlaceholderIcon.defaultProps = {
  alt: null,
  className: null,
  height: 68,
  name: 'EDUCATION_PLACEHOLDER_ICON',
  width: 132,
};
