/* eslint-disable no-console */
/**
 * Temp disable until we can get to this ticket
 * APCORE-2484
 */

export const ConsumeWarningsUtil = {
  consumePropWarnings: () => {
    const originalError = console.error;
    console.error = (...args) => {
      if (
        typeof args?.[0] === 'string' &&
        (args?.[0]?.includes(
          'Support for defaultProps will be removed from function components in a future major release.'
        ) ||
          args?.[0]?.includes(
            'findDOMNode is deprecated and will be removed in the next major release'
          ))
      ) {
        return;
      }
      originalError(...args);
    };

    const originalWarn = console.warn;
    console.warn = (...args) => {
      if (
        typeof args?.[0] === 'string' &&
        (args?.[0]?.includes(
          'Support for defaultProps will be removed from function components in a future major release.'
        ) ||
          args?.[0]?.includes(
            'findDOMNode is deprecated and will be removed in the next major release'
          ))
      ) {
        return;
      }
      originalWarn(...args);
    };
  },
};
