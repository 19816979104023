// https://stackoverflow.com/questions/7944460/detect-safari-browser

export const browsers = {
  SAFARI: 'SAFARI',
  FIREFOX: 'FIREFOX',
  INTERNET_EXPLORER: 'INTERNET EXPLORER',
};

export const isSafari = () => {
  const { userAgent, vendor } = navigator;

  return (
    vendor?.indexOf('Apple') > -1 &&
    userAgent?.indexOf('CriOS') === -1 &&
    userAgent?.indexOf('FxiOS') === -1
  );
};

export const isFireFox = () => {
  return navigator.userAgent.toLowerCase().includes('firefox');
};

export const isIE = () => {
  const { userAgent } = navigator;
  return (
    userAgent.toLowerCase().includes('msie') ||
    userAgent.toLowerCase().includes('trident')
  );
};

export const DetectBrowserUtil = {
  isSafari,
  isIE,
  isFireFox,
};
