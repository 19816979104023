import GenericStore from '../classes/generic-store';
import { getTerms } from '../api/terms/terms';

class TermsStoreClass extends GenericStore {
  async load() {
    return this.setState(
      getTerms().catch(() => {
        return null;
      })
    );
  }
}

export const TermsStore = new TermsStoreClass();
