import React from 'react';
import { useParams } from 'react-router-dom';
import { ApiError, useApiWithErrors } from '@axiom/ui';

import { AuthApi } from '../../api/auth/auth';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { ExpiredLink } from '../ExpiredLink/ExpiredLink';
import { ClientResetPassword } from '../ClientResetPassword/ClientResetPassword';

export const ClientSetPassword = () => {
  const { token, providedEmail } = useParams();
  const [response] = useApiWithErrors(token && AuthApi.readTokenIsValid(token));
  const tokenIsValid = !(response instanceof ApiError);

  return tokenIsValid ? (
    <ClientResetPassword
      action="Set a password"
      token={token}
      email={providedEmail}
    />
  ) : (
    <DefaultLayout>
      <ExpiredLink />
    </DefaultLayout>
  );
};
