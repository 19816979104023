import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const AvatarIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={`${height}px`}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 18 18"
    width={`${width}px`}
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <path d="M9 .5C4.311.5.5 4.312.5 9c0 4.688 3.811 8.5 8.5 8.5s8.5-3.812 8.5-8.5C17.5 4.312 13.689.5 9 .5zm0 1c4.148 0 7.5 3.352 7.5 7.5s-3.352 7.5-7.5 7.5A7.492 7.492 0 011.5 9c0-4.148 3.352-7.5 7.5-7.5zM9 4c-1.619-.015-2 .893-2 2a2 2 0 004 0c0-1.104-.38-1.986-2-2zm.936 4l-.09.125c-.024.027-.272.283-.928.283-.636 0-.846-.24-.87-.27l-.075-.13-.174.03a9.09 9.09 0 00-.877.196c-.647.18-1.1.388-1.348.62A1.754 1.754 0 005 10.109v1.868l.105.066c.015.009.394.247 1.05.482h.001A8.19 8.19 0 008.9 13a8.456 8.456 0 003.006-.533c.64-.245.984-.486.998-.496l.096-.067V10.09l-.004-.018c-.005-.03-.124-.724-.678-1.222-.236-.213-.686-.414-1.34-.6-.47-.133-.871-.21-.888-.213L9.936 8z" />
  </svg>
);

AvatarIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

AvatarIcon.defaultProps = {
  alt: null,
  className: null,
  height: 18,
  name: 'AXIOM_FOR_TALENT_ICON',
  width: 18,
};
