import React from 'react';

export const ExpiredLinkYieldSign = () => (
  <svg
    width="90"
    height="88"
    viewBox="0 0 90 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.998 13.7882C46 13.7882 46.8123 13.0066 46.8123 12.0424C46.8123 11.0782 46 10.2966 44.998 10.2966C43.9959 10.2966 43.1836 11.0782 43.1836 12.0424C43.1836 13.0066 43.9959 13.7882 44.998 13.7882Z"
      stroke="#A4ABB7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4462 68.8024C13.4483 68.8024 14.2605 68.0208 14.2605 67.0566C14.2605 66.0924 13.4483 65.3108 12.4462 65.3108C11.4442 65.3108 10.6318 66.0924 10.6318 67.0566C10.6318 68.0208 11.4442 68.8024 12.4462 68.8024Z"
      stroke="#A4ABB7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.5575 68.8024C78.5595 68.8024 79.3719 68.0208 79.3719 67.0566C79.3719 66.0924 78.5595 65.3108 77.5575 65.3108C76.5554 65.3108 75.7432 66.0924 75.7432 67.0566C75.7432 68.0208 76.5554 68.8024 77.5575 68.8024Z"
      stroke="#A4ABB7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.7783 73.2572V87"
      stroke="#A4ABB7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.2266 73.2572V87"
      stroke="#A4ABB7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.0045 2.1094L2.31109 69.929C1.42626 71.4082 2.53452 73.2572 4.30418 73.2572H85.6909C87.4695 73.2572 88.5778 71.4082 87.684 69.929L46.9906 2.1094C46.1058 0.6302 43.8803 0.6302 42.9955 2.1094H43.0045Z"
      stroke="#5C6471"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.1729 47.741L41.3506 35.8988C41.1986 33.594 41.1182 31.9342 41.1182 30.928C41.1182 29.5606 41.4935 28.4942 42.2354 27.7288C42.9772 26.9634 43.9604 26.5764 45.1759 26.5764C46.6506 26.5764 47.6338 27.0666 48.1343 28.047C48.6348 29.0274 48.8761 30.4378 48.8761 32.2868C48.8761 33.379 48.8136 34.4798 48.6974 35.5978L47.5891 47.7926C47.4729 49.246 47.2137 50.3554 46.8204 51.1294C46.4272 51.9034 45.7747 52.2904 44.8631 52.2904C43.9514 52.2904 43.299 51.912 42.9325 51.1638C42.5661 50.4156 42.3159 49.2718 42.1639 47.741H42.1729ZM45.0239 64.0122C43.9782 64.0122 43.0666 63.6854 42.289 63.0318C41.5114 62.3782 41.1182 61.4666 41.1182 60.297C41.1182 59.2736 41.4935 58.405 42.2354 57.6912C42.9772 56.9774 43.8888 56.6162 44.9703 56.6162C46.0518 56.6162 46.9724 56.9774 47.7321 57.6912C48.4918 58.405 48.8761 59.2736 48.8761 60.297C48.8761 61.4494 48.4918 62.361 47.7231 63.0146C46.9545 63.6682 46.0518 64.0036 45.0239 64.0036V64.0122Z"
      stroke="#F15B35"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
