import styled from 'styled-components';

export const LoadingCurtainCoverWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.71);
  z-index: 10;
`;

export const LoadingCurtainSpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -12px;
  width: 100%;
  text-align: center;
`;
