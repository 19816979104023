import styled from 'styled-components';

import { AxiomTheme } from '../../../theme/axiom-theme';

export const DSCandidateHighlight = styled.div`
  border-left: 3px solid ${AxiomTheme.colors.axiomOrange};
  padding-left: 15px;
`;

export const DSCandidateRecommendedWrapper = styled.div`
  margin: 0 -1.5rem 35px -1.5rem;
`;

export const DSCandidateSection = styled.div`
  ${props =>
    props.borderless
      ? ''
      : `
  border-top: 1px solid ${AxiomTheme.neutrals.neutral40};
  margin-top: 1rem;
  padding-top: 1rem;`}
`;

/* DSExperiences */

export const DSCandidateExperiencesWrapper = styled.div`
  @media print {
    ${DSCandidateHighlight} {
      display: none;
    }
  }
`;

export const DSCandidateExpTextWrapper = styled.div`
  margin-top: 1.5rem;
  padding-bottom: 1rem;

  & + & {
    margin-top: 0.5rem;
  }
`;

export const DSCandidateExpTextExistsWrapper = styled.div`
  font-style: italic;
  font-weight: 300;
`;

// Adjustments for Kendo PDF export

export const DSCandidateWrapper = styled.div`
  kendo-pdf-document & {
    ${DSCandidateRecommendedWrapper} {
      border-bottom: 1px solid ${AxiomTheme.neutrals.neutral40};
      margin: 0 0 1rem;
      padding-bottom: 1.5rem;

      > div {
        border: 0 !important;
        margin: 0 !important;

        > div {
          margin: 0 !important;
        }
      }
    }

    .panel .card-header {
      border-radius: 0 !important;
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;

      .ds-candidate-name {
        transform: translate(0, -0.5rem);
      }

      .ds-candidate-logo {
        display: block;
        transform: translate(0, 0.25rem);
      }
    }

    .panel .card-body {
      padding: 1.5rem 1rem 0 !important;
    }
  }
`;
