import React from 'react';
import { oneOf, node, string, bool } from 'prop-types';
import styled from 'styled-components';

import { DataTestUtil } from '../../../utils/data-test-util';

const RawBlock = ({ as, children, dataTest }) =>
  React.createElement(as, { dataTest }, children);

RawBlock.propTypes = {
  as: string.isRequired,
  children: node.isRequired,
  dataTest: string.isRequired,
};

export const BlockSketchStyles = ({ theme, size, bold, subtle }) => ({
  lineHeight: '1.2rem', // * 16px = 19.2px
  fontSize: size === 'small' ? '0.875rem' : '1rem', // * 16px = 14px/16px
  fontFamily: 'Walsheim',
  fontWeight: bold ? 'bold' : '300',
  color: subtle ? theme.text.subtle : theme.text.default,
});

const StyledBlock = styled(RawBlock)`
  ${props => BlockSketchStyles(props)}
  ${({ uppercase }) => uppercase && 'text-transform: uppercase'};
`;

export const Block = ({
  as,
  alt,
  bold,
  children,
  className,
  name,
  size,
  subtle,
  uppercase,
}) => (
  <StyledBlock
    as={as}
    title={alt}
    bold={bold}
    size={size}
    subtle={subtle}
    uppercase={uppercase}
    className={className}
    data-test={DataTestUtil.format(name)}
  >
    {children}
  </StyledBlock>
);

Block.propTypes = {
  as: oneOf(['p', 'div', 'span']),
  alt: string,
  bold: bool,
  children: node,
  className: string,
  name: string,
  size: oneOf(['regular', 'small']),
  subtle: bool,
  uppercase: bool,
};

Block.defaultProps = {
  as: 'div',
  alt: null,
  bold: false,
  children: null,
  className: '',
  name: null,
  size: 'regular',
  subtle: false,
  uppercase: false,
};
