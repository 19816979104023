import React from 'react';
import styled from 'styled-components';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

type ElementType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'div'
  | 'span';

interface H1Props {
  as: string;
  children: React.ReactNode;
  dataTest: string;
}

export interface PageHeaderProps {
  as?: ElementType;
  children?: React.ReactNode;
  className?: string;
  name?: string;
  maxLines?: number;
}

const H1 = ({ as, children, dataTest }: H1Props) =>
  React.createElement(as, { dataTest }, children);

const StyledH1 = styled(H1)``;

export const PageHeader = ({
  as = 'h1',
  children,
  className = '',
  name,
  maxLines,
}: PageHeaderProps) => (
  <StyledH1
    as={as}
    className={AttrsHelper.formatClassname(
      'page-header',
      maxLines && `max-lines-${maxLines}`,
      className
    )}
    data-test={name}
  >
    {children}
  </StyledH1>
);
