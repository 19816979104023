import styled, { css } from 'styled-components';
import { MediaQueryUtil, Text } from '@axiom/ui';

export const RPWrapper = styled.div`
  height: 100%;
`;

export const RPText = styled(Text)`
  margin-top: 1rem;
  text-align: center;

  ${MediaQueryUtil.mobileOnly(css`
    font-size: 1.125rem;
  `)}
`;

export const RPButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
