import React from 'react';
import styled from 'styled-components';

export const LoaderBarsRoot = styled.div`
  .line {
    height: 16px;
    background: linear-gradient(
      -45deg,
      transparent,
      #e9ecef,
      transparent,
      #dee2e6
    );
    background-size: 400% 400%;
    -webkit-animation: Gradient 3s ease infinite;
    animation: Gradient 3s ease infinite;
  }
  .line:not(:last-child) {
    margin-bottom: 4px;
  }
  @-webkit-keyframes Gradient {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  @keyframes Gradient {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;

export const LoaderBars = () => (
  <LoaderBarsRoot>
    <div className="line" />
    <div className="line" />
    <div className="line" />
  </LoaderBarsRoot>
);
