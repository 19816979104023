import React from 'react';
import { Card, CardSection, CondensedSmall } from '@axiom/ui';

import { OuterSpacer } from './ClientLoginOutLayoutStyes';

export const defaultWidth = '871px';
export const ClientLoginOuterLayout: React.FC<{
  children: React.ReactNode;
  width?: string;
}> = ({ children, width = defaultWidth }) => (
  <OuterSpacer width={width} data-test="outer-spacer">
    <Card>
      {children}
      <CardSection background="light blue">
        <CondensedSmall className="text-align-center">
          © {new Date().getFullYear()} Axiom Law. Axiom's{' '}
          <a
            href="https://www.axiomlaw.com/axiom-terms-of-use"
            rel="noreferrer"
            target="_blank"
          >
            Terms of Use
          </a>{' '}
          and{' '}
          <a
            href="https://www.axiomlaw.com/privacy-notice"
            rel="noreferrer"
            target="_blank"
          >
            Privacy Notice
          </a>
          .
        </CondensedSmall>
      </CardSection>
    </Card>
  </OuterSpacer>
);
