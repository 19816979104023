const IMAGE_RESIZE_DIRECTORY = 'resized-profile-images';
const PROFILE_IMAGE_DIRECTORY = 'profile-images';

const ImageSizesConst = [
  { width: 56, height: 56 },
  { width: 100, height: 100 },
  { width: 160, height: 160 },
] as const;

export type ImageSizeKeysTypes =
  | 'W_56'
  | 'W_100'
  | 'W_160'
  | 'H_56'
  | 'H_100'
  | 'H_160';
export type ImageSizeValueTypes = (typeof ImageSizesConst)[number];

const ImageSizesByWidth = (sizes => {
  return sizes.reduce(
    (current, next) => {
      current[`W_${next.width}`] = next;
      return current;
    },
    {} as Record<ImageSizeKeysTypes, ImageSizeValueTypes>
  );
})(ImageSizesConst);

const ImageSizesByHeight = (sizes => {
  return sizes.reduce(
    (current, next) => {
      current[`H_${next.height}`] = next;
      return current;
    },
    {} as Record<ImageSizeKeysTypes, ImageSizeValueTypes>
  );
})(ImageSizesConst);

export const PossibleImageSizes = (sizes => {
  return sizes.reduce(
    (current, next) => {
      current[`W_${next.width}`] = `W_${next.width}`;
      current[`H_${next.height}`] = `H_${next.height}`;
      return current;
    },
    {} as Record<ImageSizeKeysTypes, ImageSizeKeysTypes>
  );
})(ImageSizesConst);

export const CandidateImageConst = {
  IMAGE_RESIZE_DIRECTORY,
  PROFILE_IMAGE_DIRECTORY,
  ImageSizesConst,
  ImageSizesByWidth,
  ImageSizesByHeight,
  PossibleImageSizes,
};
