import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const Page = props => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.title = props.title || 'Axiom Law - Login';
  }, [props.title]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return props.children;
};
