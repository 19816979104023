import React from 'react';
import { number, oneOf, string } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

const PATH_DATA = {
  down: 'M22.63 6.37l-9.9 9.9-1.41 1.41-1.42-1.41L0 6.37l1.42-1.42 9.9 9.9 9.9-9.9z',
  left: 'M16.26 0l-9.9 9.9-1.41 1.41 1.41 1.42 9.9 9.9 1.42-1.42-9.9-9.9 9.9-9.9z',
  right:
    'M6.37 0l9.9 9.9 1.41 1.41-1.41 1.42-9.9 9.9-1.42-1.42 9.9-9.9-9.9-9.9z',
  up: 'M22.63 16.26l-9.9-9.9-1.41-1.41L9.9 6.36 0 16.26l1.42 1.42 9.9-9.9 9.9 9.9z',
};

const BOLD_PATH_DATA = {
  down: 'M10 2 6 6 2 2',
  left: 'm5.865 10-4-4 4-4',
  right: 'm2 2 4 4-4 4',
  up: 'm2 6 4-4 4 4',
};

const WEIGHTS = {
  regular: 'regular',
  bold: 'bold',
};

export const ChevronIcon = ({
  alt,
  className,
  direction,
  weight,
  height,
  name,
  width,
}) => {
  const RegularChevron = () => {
    return (
      <svg
        className={className}
        data-test={DataTestUtil.format(name)}
        focusable="false"
        height={height}
        role={alt ? 'img' : 'presentation'}
        viewBox="0 0 22.68 22.63"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        {alt && <title>{alt}</title>}
        <path d={PATH_DATA[direction]} />
      </svg>
    );
  };

  const BoldChevron = () => {
    const viewBoxData =
      direction === 'up' || direction === 'down' ? '0 0 12 8' : '0 0 8 12';
    return (
      <svg
        className={className}
        data-test={DataTestUtil.format(name)}
        focusable="false"
        height={height}
        role={alt ? 'img' : 'presentation'}
        viewBox={viewBoxData}
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        {alt && <title>{alt}</title>}
        <path
          d={BOLD_PATH_DATA[direction]}
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="square"
        />
      </svg>
    );
  };
  return weight === WEIGHTS.regular ? <RegularChevron /> : <BoldChevron />;
};

ChevronIcon.propTypes = {
  alt: string,
  className: string,
  direction: oneOf(Object.keys(PATH_DATA)),
  weight: oneOf(Object.keys(WEIGHTS)),
  height: number,
  name: string,
  width: number,
};

ChevronIcon.defaultProps = {
  alt: null,
  className: null,
  direction: 'right',
  weight: 'regular',
  height: 22.63,
  name: 'CHEVRON_ICON',
  width: 22.68,
};
