import React from 'react';
import { node, shape } from 'prop-types';
import { I18nProvider as Provider } from '@lingui/react';
import { i18n } from '@lingui/core';
// la is not in the 'make-plural/plurals' library
// af is the first import from the library that uses
// a standard catch-all method of implementing plurals.
// this works fine for the pseudolocalization effect since
// plurals have no accuracy requirements
import { en, de, af } from 'make-plural/plurals';

import enLocale from '../../../locales/en/messages';
import deLocale from '../../../locales/de/messages';
import laLocale from '../../../locales/la/messages';

const getBrowserLocale = () => {
  if (typeof window !== 'undefined') {
    const languages = window.navigator.language?.split('-');
    if (languages.length > 1 && ['en', 'de', 'la'].includes(languages[0])) {
      return languages[0];
    }
  }

  return 'en';
};

const locale = getBrowserLocale();

export const I18nProvider = ({ children, locales }) => {
  let appTranslations;
  let sharedUiTranslations;

  switch (locale) {
    case 'la': {
      appTranslations = locales?.la?.messages;
      sharedUiTranslations = laLocale;
      break;
    }
    case 'de': {
      appTranslations = locales?.de?.messages;
      sharedUiTranslations = deLocale;
      break;
    }
    default: {
      appTranslations = locales?.en?.messages;
      sharedUiTranslations = enLocale;
    }
  }

  i18n.loadLocaleData({
    en: { plurals: en },
    de: { plurals: de },
    la: { plurals: af },
  });
  i18n.load(locale, appTranslations);
  i18n.load(locale, sharedUiTranslations);
  i18n.activate(locale);

  return (
    <Provider i18n={i18n} forceRenderOnLocaleChange={false}>
      {children}
    </Provider>
  );
};

I18nProvider.propTypes = {
  children: node,
  locales: shape({ en: shape({}), de: shape({}), la: shape({}) }).isRequired,
};

I18nProvider.defaultProps = {
  children: null,
};
