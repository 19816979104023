import zxcvbn, { ZXCVBNScore } from 'zxcvbn';

export type PasswordStrengthScore = ZXCVBNScore;

export const scoreMustBeGreaterThan = 2;

const getPasswordScore: (
  password: string
) => PasswordStrengthScore = password => zxcvbn(password || '').score;

const meetsRequirement: (password: string) => boolean = password =>
  getPasswordScore(password) > scoreMustBeGreaterThan;

export const PasswordStrengthUtil = {
  getPasswordScore,
  meetsRequirement,
};
