import { useEffect, useState } from 'react';

import { FetchWatcher } from '../classes/endpoint-cache';

export type UnwrapFetchWatcherArray<T> = {
  [K in keyof T]: T[K] extends FetchWatcher<infer O> ? O : T[K];
};

export const useApiWithErrors = <
  T extends ReadonlyArray<FetchWatcher<unknown> | undefined | null>,
>(
  ...watchers: T
): UnwrapFetchWatcherArray<T> => {
  const areWatchersReady = () => {
    return watchers.every(
      checkWatcher => !checkWatcher || checkWatcher.hasResolved()
    );
  };

  const [, setFetchData] = useState(new Date());

  useEffect(() => {
    const subscriptions = watchers.map(watcher => {
      return watcher?.listen(() => {
        setFetchData(new Date());
      });
    });

    return () => {
      subscriptions.forEach(sub => sub?.unsubscribe());
    };
  }, [...watchers, setFetchData]);

  if (!areWatchersReady()) {
    throw Promise.all(
      watchers
        .filter(watcher => watcher && !watcher.hasResolved())
        .map(watcher => watcher.toPromise())
    );
  }

  return watchers.map(w =>
    w?.hasError() ? w?.getError() : w?.getData()
  ) as UnwrapFetchWatcherArray<T>;
};
