import { CandidateOpportunitiesConst, CandidatesConst } from '@axiom/const';
import StateMachine from 'javascript-state-machine';
import moment from 'moment';

import { isEndingSoon } from './position';
import { DateUtils } from './date';
import {
  AP_USER_ALLOWED_STATUS_CHANGES,
  AP_ENGAGED_USER_ALLOWED_STATUS_CHANGES,
} from './candidate-profile-status-state-constants';

const {
  getTodayDateString,
  isFutureDate,
  isOnOrFutureDate,
  isPastDate,
  isOnOrPastDate,
} = DateUtils;
const { CandidateStatuses } = CandidateOpportunitiesConst;
const {
  DaysUntilAvailabilityPreferencesTriggerAutoIdle,
  DaysUntilAvailabilityPreferencesTriggerAutoAlumReservoir,
  EmploymentStatuses,
  ProfileStatuses,
} = CandidatesConst;

// after a candidate is AlumDNR or Alum'd with completed engagements, they can be rehired.
// This is called a "boomerang". They are set back to one of these statuses.
// Old completed engagements and terminationDate and terminationReason can still be present on the candidate record
// even in Active state
export const BOOMERANG_PROFILE_STATUSES = [
  ProfileStatuses.Waitlist,
  ProfileStatuses.Certifying,
  ProfileStatuses.InDiligence,
  ProfileStatuses.Idle,
  ProfileStatuses.Reservoir,
  ProfileStatuses.Rejected,
  ProfileStatuses.NewLead,
];

const TERMINATED_PROFILE_STATUSES = [
  ProfileStatuses.AlumDNR,
  ProfileStatuses.Alum,
];

// position is in progress with end date > 30 days away or end date after today but is not confirmed yet
const isInProgress = (
  { startDate, endDate, endDateStatus },
  {
    today = getTodayDateString(),
    thirtyDaysFromToday = moment.utc().add(30, 'days').format('YYYY-MM-DD'),
  } = {}
) =>
  isOnOrPastDate(startDate) &&
  (moment(endDate).isAfter(thirtyDaysFromToday, 'day') ||
    (moment(endDate).isAfter(today, 'day') && endDateStatus !== 'Confirmed'));

const idleTransitionFilter =
  ({ candidate }) =>
  p =>
    // pass-through if not in idle status
    candidate.profileStatus !== ProfileStatuses.Idle ||
    // (if Idle) and previously Waitlist, we pass only Reservoir and Waitlist
    (candidate.previousProfileStatus === ProfileStatuses.Waitlist &&
      [ProfileStatuses.Reservoir, ProfileStatuses.Waitlist].includes(p)) ||
    // (if Idle) and previously Beach, we can go to Beach or Alum
    (candidate.previousProfileStatus === ProfileStatuses.Beach &&
      [ProfileStatuses.Alum, ProfileStatuses.Beach].includes(p));

export const isValidIdleTransition = ({ candidate, profileStatus }) =>
  idleTransitionFilter({ candidate })(profileStatus);

const checkPendingAlum =
  ({ to }) =>
  ({ allEngagements }) =>
    allEngagements.some(position => isOnOrFutureDate(position.endDate)) && to;

const checkAlum =
  ({ to }) =>
  ({ allEngagements }) =>
    allEngagements.every(position => isPastDate(position.endDate)) && to;

const checkAutoIdle = ({ candidate }) =>
  moment(candidate.availabilityPreferencesUpdatedAt).isSameOrBefore(
    moment
      .utc()
      .subtract(DaysUntilAvailabilityPreferencesTriggerAutoIdle, 'days')
  ) &&
  candidate.availabilityPreferencesUpdatedAt !== null &&
  ProfileStatuses.Idle;

const profileStatusHandlers = {
  [ProfileStatuses.Beach]: checkAutoIdle,
  [ProfileStatuses.PendingAlum]: checkAlum({ to: ProfileStatuses.Alum }),
  [ProfileStatuses.PendingAlumDNR]: checkAlum({ to: ProfileStatuses.AlumDNR }),
  [ProfileStatuses.Waitlist]: checkAutoIdle,
};

const profileStatusRewriteHandlers = {
  [ProfileStatuses.Alum]: checkPendingAlum({ to: ProfileStatuses.PendingAlum }),
  [ProfileStatuses.AlumDNR]: checkPendingAlum({
    to: ProfileStatuses.PendingAlumDNR,
  }),
};

if (
  Object.keys(profileStatusHandlers).some(
    k => !Object.values(ProfileStatuses).includes(k)
  ) ||
  Object.keys(profileStatusRewriteHandlers).some(
    k => !Object.values(ProfileStatuses).includes(k)
  )
) {
  throw new Error(
    `FATAL: A profileStatus defined in profileStatusHandlers does not exist, ${JSON.stringify(
      Object.keys(profileStatusHandlers)
    )} ${JSON.stringify(Object.keys(profileStatusRewriteHandlers))}`
  );
}

const getChangeStatus = ({
  allowedChanges,
  allEngagements,
  candidate,
  from,
  to,
}) => {
  const allowedTo = allowedChanges?.[from]?.includes(to) && to;
  if (!allowedTo) {
    return allowedTo;
  }

  // Check for transitions based on state (interceptions)
  if (Object.keys(profileStatusRewriteHandlers).includes(to)) {
    const newProfileStatus = profileStatusRewriteHandlers[to]({
      allEngagements,
      candidate,
      from,
    });
    if (newProfileStatus) {
      return newProfileStatus;
    }
  }

  return allowedTo;
};

const getEngagementProfileStatus = ({
  allEngagements = [],
  candidate,
  falseStarts = [],
}) => {
  // Check for transitions based on state
  if (Object.keys(profileStatusHandlers).includes(candidate.profileStatus)) {
    const newProfileStatus = profileStatusHandlers[candidate.profileStatus]({
      allEngagements,
      candidate,
    });
    if (newProfileStatus) {
      return newProfileStatus;
    }
  }

  if (
    [
      ProfileStatuses.Alum,
      ProfileStatuses.AlumDNR,
      ProfileStatuses.PendingAlum,
      ProfileStatuses.PendingAlumDNR,
    ].includes(candidate.profileStatus)
  ) {
    // Alum statuses supersede other auto statuses
    return candidate.profileStatus;
  } else if (allEngagements.some(position => isInProgress(position))) {
    // 'Active' if an engagement is in progress
    return ProfileStatuses.Active;
  } else if (
    allEngagements.some(position => isFutureDate(position.startDate))
  ) {
    // ‘Pending Active’ if candidate has an engagement starting in the future
    return ProfileStatuses.PendingActive;
  } else if (allEngagements.some(position => isEndingSoon(position))) {
    // ‘Pending Beach’ if candidate has a current engagement in progress that is confirmed to be ending soon
    return ProfileStatuses.PendingBeach;
  }

  // Check if we should we Auto-Alum-Reservoir
  if (
    candidate.profileStatus === ProfileStatuses.Idle &&
    candidate.employmentStatus !== EmploymentStatuses.OnLeave &&
    moment(candidate.availabilityPreferencesUpdatedAt).isSameOrBefore(
      moment
        .utc()
        .subtract(
          DaysUntilAvailabilityPreferencesTriggerAutoAlumReservoir,
          'days'
        )
    ) &&
    candidate.availabilityPreferencesUpdatedAt !== null
  ) {
    if (candidate.previousProfileStatus === ProfileStatuses.Beach) {
      return ProfileStatuses.Alum;
    } else if (candidate.previousProfileStatus === ProfileStatuses.Waitlist) {
      return ProfileStatuses.Reservoir;
    }
  }

  // 'Waitlist' if there have been only false starts - TT-1365
  if (allEngagements.length === 0 && falseStarts.length > 0) {
    return ProfileStatuses.Waitlist;
  }

  // otherwise, ‘Beach’ if all engagement end dates have been reached
  if (
    allEngagements.length > 0 &&
    allEngagements.every(position => isOnOrPastDate(position.endDate))
  ) {
    // there are completed engagements but don't overlay boomerang or terminated statuses
    if (
      !BOOMERANG_PROFILE_STATUSES.includes(candidate.profileStatus) &&
      !TERMINATED_PROFILE_STATUSES.includes(candidate.profileStatus)
    ) {
      return ProfileStatuses.Beach;
    }
  }

  // otherwise, no status change
  return candidate.profileStatus;
};

export const CandidateProfileStatusStateMachine = StateMachine.factory({
  transitions: [
    {
      name: 'changeStatus',
      from: '*',
      to(toStatus) {
        const to = getChangeStatus({
          allowedChanges: this.allowedChanges,
          allEngagements: this.allEngagements,
          candidate: this.candidate,
          from: this.state,
          to: toStatus,
        });
        if (to) {
          return to;
        }
        throw new Error(
          `Profile status change from ${this.state} to ${toStatus} not allowed.`
        );
      },
    },
    {
      name: 'engagementChanged',
      from: '*',
      to() {
        return (
          getEngagementProfileStatus({
            allEngagements: this.allEngagements,
            candidate: this.candidate,
            falseStarts: this.falseStarts,
            from: this.state,
          }) || this.state
        );
      },
    },
    {
      name: 'init',
      from: 'none',
      to(s) {
        return s;
      },
    },
  ],
  methods: {
    onInvalidTransition(transition, from, to) {
      throw new Error(
        `Profile status state machine transition ${transition} not allowed from ${from} to ${to}`
      );
    },
    allowedEndUserStatusTransitions() {
      return this.allowedChanges && this.allowedChanges[this.state]
        ? this.allowedChanges[this.state].filter(
            idleTransitionFilter({ candidate: this.candidate })
          )
        : [];
    },
  },
  // arguments used when constructing each instance are passed thru to the data method directly.
  data(candidate, candidateOpportunities = []) {
    this.init(candidate.profileStatus || 'none');
    let allowedChanges = {};
    const engagedPositions = candidateOpportunities
      .filter(
        ({ candidateStatus }) => candidateStatus === CandidateStatuses.Engaged
      )
      .map(({ position }) => position);
    const allEngagements = engagedPositions.concat(
      candidateOpportunities
        .filter(
          ({ candidateStatus }) =>
            candidateStatus === CandidateStatuses.Completed
        )
        .map(({ position }) => position)
    );
    const falseStarts = candidateOpportunities
      .filter(
        ({ previousCandidateStatus, opportunity }) =>
          // candidateStatus === 'Cooled' too, but that could change someday so don't check it
          previousCandidateStatus === CandidateStatuses.Engaged &&
          // opportunity is brought into scope for engagementChanged calculations. The state
          // machine is also used for getting allowed transitions, but opportunity is not
          // needed for that, hence the opportunity?.isFalseStart here.
          opportunity?.isFalseStart
      )
      .map(({ position }) => position);

    if (allEngagements.some(position => position == null)) {
      throw new Error(
        'developer error: candidateOpportunities must have position property'
      );
    }

    // only allow non-engagement statuses if the candidate is not engaged
    if (engagedPositions.length === 0) {
      allowedChanges = AP_USER_ALLOWED_STATUS_CHANGES;
    } else {
      allowedChanges = AP_ENGAGED_USER_ALLOWED_STATUS_CHANGES;
    }
    // object properties returned from this method to the constructor are saved in the state machine
    return {
      candidate,
      allowedChanges,
      allEngagements,
      falseStarts,
    };
  },
});
