/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { string, node } from 'prop-types';

import { DarkTheme } from '../../theme/DarkTheme/DarkTheme';

import { RSCardTitleWrapper } from './CardLayoutStyles';

export const DefaultCardHeader = ({ header, ...rest }) => {
  if (header !== null) {
    return (
      <RSCardTitleWrapper {...rest}>
        <DarkTheme backgroundName="none">{header}</DarkTheme>
      </RSCardTitleWrapper>
    );
  }
  return null;
};

DefaultCardHeader.defaultProps = {
  className: 'card-header bg-secondary text-white',
  header: null,
};
DefaultCardHeader.propTypes = {
  className: string,
  header: node,
};
