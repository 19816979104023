import React from 'react';

import { FormLabel } from '../../content/FormLabel/FormLabel';

export const FormFieldLabel = ({
  id,
  label,
  name,
  required,
  Tooltip,
}: {
  id?: string;
  label?: React.ReactNode;
  name?: string;
  required?: boolean;
  Tooltip?: React.ReactNode;
}) => {
  if (!label && !Tooltip) {
    return null;
  }

  return (
    <div className="label-wrapper" data-test="form-label">
      {label && (
        <FormLabel for={id || name} name="form-label-text">
          {label}
          {required && <span className="required">*</span>}
        </FormLabel>
      )}
      {Tooltip && <div className="form-tooltip">{Tooltip}</div>}
    </div>
  );
};
