import styled from 'styled-components';

import { CheckmarkIcon } from '../../icons/CheckmarkIcon/CheckmarkIcon';

export const CheckboxWrapper = styled.div`
  flex: none;
  position: relative;
  width: 1.0625rem;
  height: 1.0625rem;
  border: 0.0625rem currentColor solid;
  ${props => props.disabled && 'color: #a1a1a1'};

  &:focus-within {
    outline: 0.125em solid ${({ theme }) => theme.colors.axiomOrange};
  }
`;

export const CheckboxIconWrapper = styled(CheckmarkIcon)`
  position: absolute;
  top: -0.375rem;
  width: 1.25rem;
  ${props => props.disabled && 'fill: #adadad'};
`;
