const truncate = (str: string, len: number, mask = '') =>
  str.length >= len ? `${str.slice(0, Math.max(0, len))}${mask}` : str;

const capitalizeString = (str?: string) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1) : str;

const cleanStringForFileName = (str?: string) => {
  if (!str) {
    return str;
  }

  const data = str.replaceAll(/[.<>:"/\\|?*]+/gim, ' ');
  return data.replaceAll(/ {2,}/gim, ' ').trim();
};

export const StringUtil = {
  truncate,
  capitalizeString,
  cleanStringForFileName,
};
