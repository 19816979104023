import styled from 'styled-components';
import { Text } from '@axiom/ui';

import { PALETTE } from '../../themes/constants';

const { passwordStrength, grays } = PALETTE;

const PASSWORD_STRENGTH_COLORS = [
  passwordStrength.veryWeak,
  passwordStrength.weak,
  passwordStrength.fair,
  passwordStrength.strong,
  passwordStrength.veryStrong,
];

export const RPStrengthMeter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RPStrengthBlock = styled.div`
  border: ${props => (props.full ? 'none' : `1px solid ${grays.gray11}`)};
  width: 77px;
  height: 5px;
  background-color: ${props =>
    props.full ? PASSWORD_STRENGTH_COLORS[props.score] : 'transparent'};

  :first-child {
    border-radius: 2px 0px 0px 2px;
  }

  :last-child {
    border-radius: 0px 2px 2px 0px;
  }
`;

export const RPFooterButtonsWrapper = styled.div`
  text-align: right;
  margin-top: 2.5rem;
`;

export const RPFormGroup = styled(Text)`
  margin-bottom: 30px;
  margin-top: 10px;
`;

export const RPPasswordWrapper = styled.div`
  margin-bottom: 13px;
`;

export const RPStrengthWrapper = styled.div`
  padding: 0 17px;
  margin-bottom: 33px;
`;
