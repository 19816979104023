import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const PhoneIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 88 88"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <circle fill="#75b73b" cx="39" cy="39" r="39" />
    <path
      d="M7263.633,12687.667c-1.942,1.107-6.108,4.95-4.108,11.892s4.663,13.043,13.775,20.667,24.391,10.541,28.058,7.324,4.048-6.394,2.667-7.941-7.216-4.774-8.383-4.941a2.562,2.562,0,0,0-2.726,1.166c-.559.941-3.274,5.991-6.108,5.05s-7.833-4.157-10.284-6.216c0,0-8.212-6.614-7.833-9.667s2.718-3.273,3.784-4.226.833-4.883-2.059-9.274S7265.575,12686.56,7263.633,12687.667Z"
      fill="#fff"
      transform="translate(-7242.995 -12669.174)"
    />
  </svg>
);

PhoneIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

PhoneIcon.defaultProps = {
  alt: null,
  className: null,
  height: 88,
  name: 'PHONE_ICON',
  width: 88,
};
