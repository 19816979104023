import React, { useMemo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { Dropdown as SDropdown } from 'semantic-ui-react';
import uniq from 'lodash/uniq';

import { AsyncConnector } from '../../../classes/async-connector';
import { Button } from '../../element/Button/Button';
import { IconButton } from '../../element/Button/IconButton';
import { Icon } from '../../element/Icon/Icon';
import { DesignSysEnvUtil } from '../../../utils/design-sys-env-util';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

const DropdownWrapper = styled.div``;

export type RawDropdownValueType =
  | string
  | number
  | boolean
  | null
  | Array<string | number | boolean>;

export type RawDropdownDirectionType = 'up' | 'down' | 'default';

export const DirectionMap = {
  up: true,
  down: false,
};

export type RawDropdownProps = {
  allowAdditions?: boolean;
  direction?: RawDropdownDirectionType;
  displayKey: string;
  disabled?: boolean;
  hideIcon?: boolean;
  id?: string;
  invalid?: boolean;
  multiple?: boolean;
  name: string;
  onAddItem?: (value: RawDropdownValueType) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (
    value: RawDropdownValueType,
    e?: React.SyntheticEvent<HTMLElement, Event>,
    valueOption?: Record<string, unknown> | Record<string, unknown>[]
  ) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onType?: (value: RawDropdownValueType) => void;
  options: Array<Record<string, unknown>> | AsyncConnector;
  placeholder?: string;
  value?: RawDropdownValueType;
  valueKey: string;
};

const divider = ':||:';

const convertValue = (value: RawDropdownValueType | unknown) => {
  if (Array.isArray(value)) {
    return value.join(divider);
  }

  return value;
};
export const RawDropdown = ({
  allowAdditions,
  displayKey,
  direction = 'default',
  disabled = false,
  hideIcon,
  id,
  invalid = false,
  multiple = false,
  name,
  onAddItem,
  onBlur,
  onChange,
  onFocus,
  onType,
  options,
  placeholder,
  value,
  valueKey,
}: RawDropdownProps) => {
  const isAsyncOptions = options instanceof AsyncConnector;

  const [optionsState, setOptionsState] = useState({
    options: isAsyncOptions ? [] : options,
    totalPages: 1,
    currentPage: 1,
    searchQuery: `${value}`,
  });
  const [loading, setLoading] = useState({
    isLoading: false,
    hasLoaded: false,
  });

  if (multiple && isAsyncOptions) {
    // BE endpoints that want typeahead for a multi select needs the search to be inclusive and searchable by IDs
    throw new Error(
      `Field '${name}' expects an array of values, however the dropdown currently does not support async search for multi select`
    );
  }

  const dropdownOptions = useMemo(() => {
    return optionsState.options.reduce((acc, o) => {
      const display = get(o, displayKey);
      const v = get(o, valueKey);
      if (display) {
        if (Array.isArray(v) && !multiple) {
          throw new Error(
            `Option '${display}' has an array of values but the schema prop for '${name}' does not accept an array of values`
          );
        }
        acc.push({
          key: convertValue(v),
          text: display,
          value: convertValue(v),
        });
      }

      return acc;
    }, []);
  }, [optionsState.options]);

  // loads async data into dropdown options
  const loadDataSource = (searchQuery: string, page = 1) => {
    if (isAsyncOptions) {
      setLoading({ isLoading: true, hasLoaded: false });

      options
        .getData({ search: searchQuery, page, sort: {} })
        .then(response => {
          const {
            data,
            totalPages: tPages,
            currentPage: cPage,
          } = options.parseData(response);

          setOptionsState(({ options: opts }) => ({
            options: page > 1 ? [...opts, ...data] : data,
            totalPages: tPages,
            currentPage: cPage,
            searchQuery,
          }));
          setLoading({ isLoading: false, hasLoaded: true });
        });
    }
  };

  const debounceSearch = useCallback(
    debounce(s => {
      loadDataSource(s);
    }, DesignSysEnvUtil.typingDebounce),
    []
  );

  // Sets the dropdown options as expected if not async
  useEffect(() => {
    if (!isAsyncOptions) {
      setOptionsState(state => ({
        ...state,
        options,
      }));
      setLoading(state => ({
        ...state,
        hasLoaded: true,
      }));
    }
  }, [options]);

  // ensure dropdown options contain the value upon initialization
  // so the value displays as selected properly
  useEffect(() => {
    if (value) {
      loadDataSource(`${value}`);
    } else {
      setLoading(state => ({
        ...state,
        hasLoaded: true,
      }));
    }
  }, []);

  if (multiple && (value === undefined || value === null)) {
    value = [];
  }

  const hasValue = multiple
    ? (value as Array<unknown>)?.length > 0
    : !!value || value === 0;

  const getDropdownOptions = () => {
    const loadMoreOption = {
      key: 'LOAD_MORE',
      content: (
        <Button
          name="LOAD_MORE_OPTIONS"
          onClick={e => {
            e.stopPropagation();
            loadDataSource(
              optionsState.searchQuery,
              optionsState.currentPage + 1
            );
          }}
        >
          Load More
        </Button>
      ),
    };

    if (optionsState.currentPage < optionsState.totalPages) {
      return [...dropdownOptions, loadMoreOption];
    }

    return dropdownOptions;
  };

  return (
    <DropdownWrapper data-test={name}>
      <SDropdown
        className={AttrsHelper.formatClassname(
          loading.hasLoaded && 'has-loaded'
        )}
        allowAdditions={allowAdditions}
        clearable
        upward={DirectionMap[direction as keyof typeof DirectionMap]}
        disabled={disabled}
        error={invalid}
        fluid
        icon={
          loading.isLoading
            ? undefined
            : !hasValue &&
              !hideIcon && (
                <IconButton
                  pattern="secondary"
                  variation="minimal"
                  icon="arrow-down"
                  name="default-dropdown-arrow"
                  className="control-icon fixed-dimension-inline"
                />
              )
        }
        id={id}
        multiple={multiple}
        onAddItem={(_e, data) => {
          onAddItem(data.value);
        }}
        onBlur={onBlur}
        onChange={(e, data) => {
          const formattedValue = Array.isArray(data.value)
            ? uniq(
                data.value
                  .reduce((acc, v) => {
                    if (typeof v === 'string') {
                      acc.push(v.includes(divider) ? v.split(divider) : v);
                    } else {
                      acc.push(v);
                    }

                    return acc;
                  }, [])
                  .flat()
              )
            : data.value;

          const valueOption = Array.isArray(formattedValue)
            ? optionsState.options.filter(opt =>
                formattedValue.includes(get(opt, valueKey))
              )
            : optionsState.options.find(
                opt => get(opt, valueKey) === formattedValue
              );

          onChange(formattedValue, e, valueOption);
        }}
        onFocus={onFocus}
        onSearchChange={(_e, data) => {
          if (onType) {
            onType(data.searchQuery);
          }
          if (isAsyncOptions) {
            debounceSearch(data.searchQuery);
          }
        }}
        options={getDropdownOptions()}
        placeholder={placeholder}
        renderLabel={({ text }) => {
          // TODO: remove this in Semantic v3
          // This maintains compatibility with Shorthand API in v1 as this might be called in "Label.create()"
          if (typeof text === 'function') {
            return text;
          }

          return {
            content: (
              <>
                {text}
                <span
                  className="pill-control-icon"
                  onClick={e => {
                    e.stopPropagation();
                    const foundPillItem = dropdownOptions.find(
                      op => op.text === text
                    );
                    onChange(
                      (value as Array<string>).filter(
                        v => v !== foundPillItem?.value
                      )
                    );
                  }}
                >
                  <Icon name="x-mark" />
                </span>
              </>
            ),
          };
        }}
        // for async, options are determined by the response, otherwise use default filtering of the dropdown
        search={isAsyncOptions ? opt => opt : true}
        selection
        selectOnBlur={false}
        scrolling
        value={value}
        loading={loading.isLoading}
      />
    </DropdownWrapper>
  );
};
