import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const FilterIcon = ({ alt, className, height, name, width, fill }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    width={width}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <defs>
      <path
        d="M1.818.877c-.751 0-1.314.485-1.403 1.21-.049.417.09.793.414 1.117l4.384 4.385c-.002 2.201-.002 4.401-.001 6.601 0 .445.169.835.475 1.096.236.202.532.308.851.308a1.384 1.384 0 00.969-.39c.603-.596 1.202-1.195 1.8-1.795l.596-.596c.275-.274.414-.622.414-1.04l-.003-4.183 4.372-4.376c.464-.466.563-1.055.273-1.614-.237-.459-.68-.723-1.215-.723H1.818zm4.896 6.561a1.09 1.09 0 00-.334-.803L2.125 2.377h11.277L9.147 6.635a1.093 1.093 0 00-.334.806l.031 4.309-.598.6a95.1 95.1 0 00-1.534 1.559c-.001-2.156-.002-4.313.002-6.471z"
        id="a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M2.126 2.906l4.253 4.258c.222.216.335.488.335.803-.004 2.158-.004 4.315-.003 6.472a99.456 99.456 0 011.535-1.56l.598-.6-.03-4.31a1.098 1.098 0 01.332-.805l4.255-4.258H2.127zm4.413 13.218c-.32 0-.615-.106-.851-.308-.308-.262-.476-.652-.476-1.097-.002-2.2-.002-4.4.002-6.601L.829 3.734C.505 3.409.367 3.033.417 2.616c.087-.724.65-1.21 1.401-1.21h11.928c.534 0 .977.264 1.215.723.29.56.19 1.149-.274 1.614l-4.373 4.376.005 4.184c0 .417-.14.766-.415 1.039l-.596.597c-.598.599-1.197 1.199-1.8 1.795a1.379 1.379 0 01-.968.39z"
        fill={fill}
      />
      <g transform="translate(0 .53)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M-2.5 18.5h20.529V-2.029H-2.5V18.5zM18.779 20H-3.25a.75.75 0 01-.75-.75V-2.779a.75.75 0 01.75-.75h22.029a.75.75 0 01.75.75V19.25a.75.75 0 01-.75.75z"
          fill={fill}
          mask="url(#b)"
        />
        <path
          d="M1.906 14.095h11.717V2.377H1.906v11.718zm12.468 1.5H1.156a.75.75 0 01-.75-.75V1.627a.75.75 0 01.75-.75h13.218a.75.75 0 01.75.75v13.218a.75.75 0 01-.75.75z"
          fill={fill}
          mask="url(#b)"
        />
      </g>
    </g>
  </svg>
);

FilterIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
  fill: string,
};

FilterIcon.defaultProps = {
  alt: null,
  className: null,
  height: 17,
  name: 'FILTER_ICON',
  width: 16,
  fill: '#FF5A2D',
};
