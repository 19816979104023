import { CandidatesConst } from '@axiom/const';

const { ProfileStatuses } = CandidatesConst;

export const AP_USER_ALLOWED_STATUS_CHANGES = {
  [ProfileStatuses.NewLead]: [
    ProfileStatuses.Certifying,
    ProfileStatuses.Rejected,
    ProfileStatuses.Reservoir,
  ],
  [ProfileStatuses.Reservoir]: [ProfileStatuses.NewLead],
  [ProfileStatuses.Certifying]: [
    ProfileStatuses.InDiligence,
    ProfileStatuses.Rejected,
    ProfileStatuses.Reservoir,
  ],
  [ProfileStatuses.Rejected]: [],
  [ProfileStatuses.InDiligence]: [
    ProfileStatuses.Rejected,
    ProfileStatuses.Reservoir,
    ProfileStatuses.Waitlist,
  ],
  [ProfileStatuses.Waitlist]: [
    ProfileStatuses.Idle,
    ProfileStatuses.Rejected,
    ProfileStatuses.Reservoir,
  ],
  [ProfileStatuses.Idle]: [
    ProfileStatuses.Alum,
    ProfileStatuses.Beach,
    ProfileStatuses.Reservoir,
    ProfileStatuses.Waitlist,
  ],
  [ProfileStatuses.Active]: [
    ProfileStatuses.PendingAlum,
    ProfileStatuses.PendingAlumDNR,
  ],
  [ProfileStatuses.PendingBeach]: [
    ProfileStatuses.PendingAlum,
    ProfileStatuses.PendingAlumDNR,
  ],
  [ProfileStatuses.Beach]: [
    ProfileStatuses.Idle,
    ProfileStatuses.Alum,
    ProfileStatuses.AlumDNR,
  ],
  [ProfileStatuses.PendingAlum]: [ProfileStatuses.NewLead],
  [ProfileStatuses.PendingAlumDNR]: [ProfileStatuses.NewLead],
  [ProfileStatuses.Alum]: [ProfileStatuses.NewLead],
  [ProfileStatuses.AlumDNR]: [ProfileStatuses.NewLead],
};

export const AP_ENGAGED_USER_ALLOWED_STATUS_CHANGES = {
  [ProfileStatuses.Active]: [
    ProfileStatuses.PendingAlum,
    ProfileStatuses.PendingAlumDNR,
  ],
  [ProfileStatuses.PendingBeach]: [
    ProfileStatuses.PendingAlum,
    ProfileStatuses.PendingAlumDNR,
  ],
};
