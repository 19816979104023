import React from 'react';
import { number, string } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const KeyIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 42 42"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <path d="M4.318 3.42c-4.287 4.288-4.287 11.27 0 15.557 2.5 2.5 5.914 3.532 9.177 3.117l4.685 4.684 2.878-.342-.241 2.979 4.603 4.6 2.62-.387-.492 2.515 5.651 5.652 7.698-.923-.2-8.55-18.045-18.045c1.08-3.73.156-7.923-2.777-10.857A10.965 10.965 0 0012.096.205 10.965 10.965 0 004.318 3.42zm1.415 1.415a8.973 8.973 0 016.363-2.644c2.302 0 4.603.882 6.364 2.644a8.98 8.98 0 011.883 9.961l1.501 1.501 2.667 2.667L38.717 33.17l.139 5.932-4.93.59-4.211-4.21.833-4.241-4.424.65-3.239-3.236.368-4.494-4.344.515-.055-.055-3.073-3.073-1.6-1.599a8.972 8.972 0 01-8.448-2.386 8.985 8.985 0 010-12.728zM9.369 8.47c-1.498 1.498-1.495 3.957.003 5.455a3.868 3.868 0 005.45-.001 3.871 3.871 0 00.002-5.454 3.845 3.845 0 00-2.727-1.123c-.99 0-1.98.374-2.728 1.123zm1.414 1.414a1.85 1.85 0 012.627 0 1.841 1.841 0 01-.002 2.626 1.838 1.838 0 01-2.622-.002 1.84 1.84 0 01-.003-2.624z" />
  </svg>
);

KeyIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

KeyIcon.defaultProps = {
  alt: null,
  className: null,
  height: 42,
  name: 'KEY_ICON',
  width: 42,
};
