/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { node, bool } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';
import { Label } from '../../content/Label/Label';

import {
  SectionWrapper,
  DivLabelWrapper,
  DivFooterWrapper,
} from './LabelWrapperStyles';

export const LabelWrapper = ({
  children,
  flexLabel,
  footerLeft,
  footerRight,
  label,
  labelRight,
  margin,
  name,
  ...rest
}) => (
  <SectionWrapper
    flexLabel={flexLabel}
    margin={margin}
    data-test={DataTestUtil.format(`section_${label}`)}
    {...rest}
  >
    <DivLabelWrapper data-test={name}>
      <Label>{label}</Label>
      <div>{labelRight}</div>
    </DivLabelWrapper>
    {children}
    <DivFooterWrapper>
      <div>{footerLeft}</div>
      <div>{footerRight}</div>
    </DivFooterWrapper>
  </SectionWrapper>
);

LabelWrapper.defaultProps = {
  children: '',
  flexLabel: false,
  footerLeft: null,
  footerRight: null,
  labelRight: null,
  margin: true,
  name: null,
};

LabelWrapper.propTypes = {
  children: node,
  flexLabel: bool,
  footerLeft: node,
  footerRight: node,
  label: node.isRequired,
  labelRight: node,
  margin: bool,
  name: node,
};
