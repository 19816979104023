import styled from 'styled-components';

export const CMContainer = styled.div`
  border-radius: 5px;
  position: relative;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
`;

export const CMWrapper = styled.div`
  margin: 1.5rem;
  border-left: 3px solid ${({ theme }) => theme?.colors?.axiomOrange};
  padding-left: 1rem;
  text-align: left;
`;

export const CMText = styled.p`
  margin-bottom: 0;
`;
