import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CookieUtil } from '@axiom/ui';

import LoadingTreatment from '../components/LoadingTreatment/LoadingTreatment';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Login } from '../components/Login/Login';
import { FormLoginStore } from '../stores/form-login-store';
import { WindowUtil } from '../utils/window-util';
import { Page } from '../components/Page/Page';

export const IndexPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    FormLoginStore.load();
    if (!CookieUtil.isUserExpired()) {
      const query = new URLSearchParams(location.search);
      navigate(
        `/sso${
          query.get('RelayState')
            ? `${WindowUtil.getFullRedirect(location)}`
            : ''
        }`,
        { replace: true }
      );
    }
  }, []);

  if (!CookieUtil.isUserExpired()) {
    return <LoadingTreatment />;
  }

  return (
    <Page title="Axiom Law - Login">
      <DefaultLayout>
        <Login />
      </DefaultLayout>
    </Page>
  );
};

IndexPage.propTypes = {};
