// import color from 'color';
const BASE_FONT_SIZE = 1;

export const FONT = {
  sizes: {
    base: `${BASE_FONT_SIZE}rem`,
    lg: `${BASE_FONT_SIZE * 1.25}rem`,
    sm: `${BASE_FONT_SIZE * 0.875}rem`,
  },
  weights: {
    lighter: 'lighter',
    light: 300,
    normal: 400,
    medium: 500,
    bold: 600,
    bolder: 'bolder',
  },
  lineHeights: {
    base: '1.5rem',
  },
  headerSizes: {
    h1: `${BASE_FONT_SIZE * 2.5}rem`,
    h2: `${BASE_FONT_SIZE * 2}rem`,
    h3: `${BASE_FONT_SIZE * 1.75}rem`,
    h4: `${BASE_FONT_SIZE * 1.5}rem`,
    h5: `${BASE_FONT_SIZE * 1.25}rem`,
    h6: BASE_FONT_SIZE,
  },
};

const GRAYS = {
  white: '#fff',
  black: '#000',
  gray1: '#ecf0f0',
  gray2: '#dbe0e2',
  gray3: '#bfc6cd',
  gray4: '#a3abb7',
  gray5: '#7d8694',
  gray6: '#5c6471',
  gray7: '#464e5b',
  gray8: '#313844',
  gray9: '#1c222e',
  gray10: '#ebf0f0',
  gray11: '#c6c6c6',
  gray12: '#ced4d4',
  gray13: '#5b6471',
  gray14: '#575757',
};

export const COLORS = {
  sky: '#a5d4d2',
  denim: '#477e96',
  canary: '#fdd355',
  gold: '#ffbe0d',
  grass: '#b1c472',
  forest: '#68855e',
  crimson: '#c93333',
  brick: '#852b32',
  rust: '#de5727',
  sienna: '#b84118',
  lavender: '#ab7eaa',
  grape: '#7a5579',
  khaki: '#c2ba90',
  olive: '#7c7562',
  taupe: '#957d73',
  umber: '#5c4a4c',
  axiomOrange: '#ff5a2d',
  green: '#8CC63F',
  aqua: '#a4d3de',
};

const THEME_COLORS = {
  primary: COLORS.axiomOrange,
  secondary: GRAYS.gray9,
  success: COLORS.green,
  info: COLORS.denim,
  warning: COLORS.canary,
  danger: '#c93333',
  light: GRAYS.gray1,
  dark: GRAYS.gray9,
  textMuted: '#6c757d',
};

const PASSWORD_STRENGTH_COLORS = {
  veryWeak: '#852b33',
  weak: '#c93233',
  fair: '#ffa700',
  strong: '#b1c471',
  veryStrong: '#579e2d',
};

export const PALETTE = {
  theme: THEME_COLORS,
  grays: GRAYS,
  colors: COLORS,
  passwordStrength: PASSWORD_STRENGTH_COLORS,
};
