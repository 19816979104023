export const formatLocation = components => {
  const addresses =
    components && components.addresses ? components.addresses : [];
  const byType = addresses.reduce((acc, data) => {
    data.types.forEach(type => {
      acc[type] = data;
    });
    return acc;
  }, []);
  const placeGet = (key, short) => {
    if (!(key in byType)) return byType.long_name || '';

    return short ? byType[key].short_name : byType[key].long_name;
  };
  const result = {
    streetNumber: placeGet('street_number'),
    streetName: placeGet('route'),
    city: placeGet('locality'),
    county: placeGet('administrative_area_level_2'),
    stateShort: placeGet('administrative_area_level_1', true),
    stateLong: placeGet('administrative_area_level_1'),
    countryShort: placeGet('country', true),
    countryLong: placeGet('country'),
    zipCode: placeGet('postal_code'),
  };
  const display = [];

  if (result.city) display.push(result.city);
  if (result.stateShort) display.push(result.stateShort);
  if (result.countryShort) display.push(result.countryShort);

  return display.join(', ');
};

export default { formatLocation };
