import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import withDragAndDrop, {
  EventInteractionArgs,
} from 'react-big-calendar/lib/addons/dragAndDrop';
import {
  Calendar as BigCalendar,
  Culture,
  DateLocalizer,
  momentLocalizer,
  Views,
} from 'react-big-calendar';
import { CalendarEventType, CalendarComponentEvent } from '@axiom/validation';

import {
  CalendarPermissionsUtil,
  calendarPermissionsType,
} from '../../../utils/calendar-permissions-util';
import { useBreakpoint } from '../../../hooks/use-breakpoint';
import { useOnDependencyUpdate } from '../../../hooks/useOnDependencyUpdate';
import { useInternalEventContext } from '../../../providers/InternalEventContext';

import { CalendarEventsUtil } from './calendar-events-util';
import { CalendarTimeSlot } from './CalendarTimeSlot';
import { useMergeEvents } from './useMergeEvents';
import { useAccessors } from './useAccessors';
import { CalendarEvent } from './CalendarEvent';
import { CalendarToolbar } from './CalendarToolbar';
import { CalendarDayColumn } from './CalendarDayColumn';
import { CalendarDeleteEventModal } from './CalendarDeleteEventModal';
import { EventStates } from './CalendarEventsConst';

// REQUIRED: DON'T REMOVE ======= START
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
// REQUIRED: DON'T REMOVE ======= END

const allZones = moment.tz.names();
const DnDCalendar = withDragAndDrop(BigCalendar);

export const TIME_FORMATS = {
  MONTH_DAY: 'MMMM D',
  MONTH_ABBR_DAY: 'ddd M/DD',
  HOUR_MINS: 'hh:mm a',
};

type UpdateEventArgType = Omit<
  EventInteractionArgs<CalendarEventType>,
  'start' | 'end'
> & {
  start: CalendarComponentEvent['start'];
  end: CalendarComponentEvent['end'];
};

type TimeConfig = {
  increments?: number; // smallest selectable time
  slots?: number; // slots per section*
};

/* section = increments * slots
 * 1 * 60  = 1hr sections with 1hr selectable events
 * 2 * 30 =  1hr sections with 30min selectable events
 * */

export type CalendarProps = {
  permissions: calendarPermissionsType;
  events: CalendarEventType[];
  startMode?: typeof Views.WEEK | typeof Views.WORK_WEEK | typeof Views.DAY;
  weekMode?: TimeConfig;
  dayMode?: TimeConfig;
  backgroundEvents?: CalendarEventType[];
  scrollToTime?: Date;
  timezone?: (typeof allZones)[number];
  height?: string;
  onChange?: (prop: CalendarEventType[]) => void;
  displayDate?: Date;
  name: string;
};

export const RawCalendar = ({
  name,
  displayDate = new Date(),
  events = [],
  backgroundEvents = [],
  startMode = Views.WEEK,
  weekMode,
  dayMode,
  scrollToTime,
  timezone,
  height = '600px',
  permissions,
  onChange = () => {},
}: CalendarProps) => {
  const internalEventContext = useInternalEventContext();
  const blockingGranularity =
    CalendarEventsUtil.dateCheckGranularity(permissions);
  const { isMobile } = useBreakpoint();
  const [selectedEvent, setSelectedEvent] = useState<CalendarComponentEvent>(
    {}
  );
  const [timeZoneChanged, setTimeZoneChanged] = useState(false);
  const [perms, setPerms] = useState(
    isMobile ? CalendarPermissionsUtil().getBasePermissions() : permissions
  );

  useOnDependencyUpdate(() => {
    setPerms(permissions);
  }, [permissions]);

  const [eventData, setEventData] = useState(
    CalendarEventsUtil.translateFromStartTimeEndTimeProps(
      events
        .filter(event => {
          return moment(event.endTime).isAfter(moment(), blockingGranularity);
        })
        .map(e => {
          e.state = e.state || EventStates.SAVED;

          if (moment(e.startTime).isBefore(moment(), blockingGranularity)) {
            e.startTime = moment()
              .add(1, 'hour')
              .set({ minutes: 0, seconds: 0, milliseconds: 0 })
              .toISOString();
            e.state = EventStates.MODIFIED;
          }

          return e;
        })
    )
  );
  const bgEventIds = new Set();
  const [allBackgroundEvents] = useState(
    CalendarEventsUtil.translateFromStartTimeEndTimeProps(
      backgroundEvents
        .filter(event => {
          return moment(event.endTime).isAfter(moment(), blockingGranularity);
        })
        .map((bgE: CalendarEventType) => {
          bgEventIds.add(bgE.id);

          return {
            ...bgE,
            name: bgE.name ? `BACKGROUND ${bgE.name}` : 'BACKGROUND_EVENT',
          };
        })
    )
  );
  const timesConfig = useMemo(() => weekMode || dayMode, [weekMode, dayMode]);
  const mergeEvents = useMergeEvents();

  const { handleDraggableAccessor, handleResizableAccessor } =
    useAccessors(perms);

  const { appLocalizer, defaultDate, toTime } = useMemo(() => {
    const invalidMomentDays = new Set([5, 6]); // Fri, Sat
    moment.tz.setDefault(timezone);

    return {
      defaultDate: invalidMomentDays.has(moment(displayDate).weekday())
        ? moment().startOf('week').add(1, 'week').toDate()
        : moment(displayDate).toDate(),
      appLocalizer: momentLocalizer(moment),
      toTime: scrollToTime || moment().subtract(1, 'hour').toDate(),
    };
  }, [timezone]);

  // https://github.com/jquense/react-big-calendar/blob/master/stories/demos/exampleCode/rendering.js
  const { components, formats } = useMemo(
    () => ({
      formats: {
        dayRangeHeaderFormat: (
          { start, end }: { start: Date; end: Date },
          culture: Culture,
          localizer: DateLocalizer
        ) => {
          const sameMonth = moment(start).month() === moment(end).month();
          const startFormat = TIME_FORMATS.MONTH_DAY;
          const endFormat = sameMonth ? 'D' : startFormat;

          return `${localizer.format(start, TIME_FORMATS.MONTH_DAY, culture)}${
            sameMonth ? '-' : ' - '
          }${localizer.format(end, endFormat, culture)}`;
        },
        dayFormat: (date: Date, culture: string, localizer: DateLocalizer) =>
          localizer.format(date, TIME_FORMATS.MONTH_ABBR_DAY, culture),
        eventTimeRangeFormat: (
          { start, end }: { start: Date; end: Date },
          culture: Culture,
          localizer: DateLocalizer
        ) =>
          `${localizer.format(
            start,
            TIME_FORMATS.HOUR_MINS,
            culture
          )} - ${localizer.format(end, TIME_FORMATS.HOUR_MINS, culture)}`,
      },
      components: {
        toolbar: CalendarToolbar,
        event: CalendarEvent,
        timeSlotWrapper: CalendarTimeSlot,
        dayColumnWrapper: CalendarDayColumn,
      },
    }),
    []
  );

  useEffect(() => {
    return () => {
      moment.tz.setDefault(); // reset to browser TZ on unmount
    };
  }, []);

  useOnDependencyUpdate(() => {
    /**
     * This is not run on the 'on mount' render cycle
     * since it is only needed if "eventData" changes
     * aka a new or modified event from the user
     * */
    onChange([
      ...CalendarEventsUtil.translateToStartTimeEndTimeProps(eventData),
    ]);
  }, [eventData]);

  useOnDependencyUpdate(() => {
    /**
     * This is not run on the 'on mount' render cycle
     * since it is only needed if "eventData" changes
     * aka a new or modified event from the user
     * */
    if (timeZoneChanged) {
      setEventData(prevState => {
        return prevState.map(event => ({
          ...event,
          state:
            event.state === EventStates.SAVED
              ? EventStates.MODIFIED
              : event.state,
        }));
      });
    }
  }, [timeZoneChanged]);

  useOnDependencyUpdate(() => {
    setTimeZoneChanged(true);
  }, [timezone]);

  const eventPropGetter = useCallback((event: CalendarComponentEvent) => {
    const data = {
      className: 'nonDraggable',
    };

    if (event.busy) {
      data.className = `staticEvent ${
        perms.staticEvent.canMove ? 'isDraggable' : 'nonDraggable'
      }`;
    } else if (!event.busy) {
      data.className = perms.eventBoundary.canMove
        ? 'isDraggable'
        : 'nonDraggable';
    }

    if (event.error) {
      data.className += ' error';
    }

    return data;
  }, []);

  const slotPropGetter = useCallback(
    (date: Date) => {
      const inThePast = CalendarEventsUtil.isInThePast(date, permissions);

      return { className: inThePast ? 'inactive-time-block' : '' };
    },
    [permissions]
  );

  const selectSlot = (props: {
    start: Date;
    end: Date;
    action: 'select' | 'click' | 'doubleClick';
  }): null => {
    if (
      perms.staticEvent.canCreate &&
      !perms.staticEvent.canDragToCreate &&
      (props.action === 'select' || props.action === 'doubleClick')
    ) {
      return null;
    }

    const canCreateStaticEvent =
      perms.staticEvent.canCreate || perms.staticEvent.canDragToCreate;
    const newEvent: CalendarComponentEvent = {
      id: `UNSAVED_${Math.round(Math.random() * 1e17) + 1e17}`,
      title: canCreateStaticEvent ? 'Interview' : '',
      start: props.start,
      end: props.end,
      busy: canCreateStaticEvent,
      error: null,
      state: EventStates.NEW,
    };

    if (newEvent.busy) {
      const dataEvents: CalendarComponentEvent[] =
        allBackgroundEvents.length > 0 ? allBackgroundEvents : eventData;
      const eventBoundaryEvent = dataEvents.find(abe => {
        return (
          moment(newEvent.start).isBetween(
            abe.start,
            abe.end,
            undefined,
            '[]'
          ) &&
          moment(newEvent.end).isBetween(abe.start, abe.end, undefined, '[]')
        );
      });

      if (!eventBoundaryEvent) {
        // staticEvent is not within the free time
        return null;
      }
      // For setting an staticEvent ================= END
    } else if (
      !CalendarEventsUtil.canAddEvent(perms, newEvent.start.toISOString())
    ) {
      return null;
    }

    setEventData(prevState => {
      const unsavedStaticEvent = prevState.find(
        event => event.busy && event.state !== EventStates.SAVED
      );
      const data =
        !perms.staticEvent.canCreateMultiple && !!unsavedStaticEvent
          ? prevState.filter(pe => pe.id !== unsavedStaticEvent.id)
          : prevState;

      data.push(newEvent);

      if (allBackgroundEvents.length > 0) {
        // need the background events for error checking only
        return CalendarEventsUtil.setEventError(
          [...allBackgroundEvents, ...mergeEvents(data)],
          permissions
        ).filter(evnt => !bgEventIds.has(evnt.id));
      }
      return CalendarEventsUtil.setEventError(mergeEvents(data), permissions);
    });
    return null;
  };

  const handleSelectSlot = useCallback(selectSlot, [eventData, perms]);

  const onSelecting = (event: CalendarComponentEvent) => {
    return (
      moment(event.end).isAfter(moment(), blockingGranularity) &&
      (perms.eventBoundary.canDragToCreate || perms.staticEvent.canDragToCreate)
    );
  };

  const handleOnSelecting = useCallback(onSelecting, [perms]);

  const updateEvent = useCallback(
    ({ event, start, end }: UpdateEventArgType) => {
      setEventData(prevState => {
        let movedEvent = false;
        const toBeMerged = prevState.reduce((allEvents, calEvent) => {
          const data: CalendarComponentEvent = {
            ...calEvent,
            error: null,
          };

          if (data.id === event.id) {
            // modifying an existing event
            data.start = start;
            data.end = end;
            data.state =
              data.state === EventStates.NEW ||
              data.state === EventStates.DELETE
                ? data.state
                : EventStates.MODIFIED;
            movedEvent = true;
          }

          allEvents.push(data);

          return allEvents;
        }, [] as CalendarEventType[]);

        if (!movedEvent) {
          toBeMerged.push(event);
        }

        if (allBackgroundEvents.length > 0) {
          // need the background events for error checking only
          return CalendarEventsUtil.setEventError(
            [...allBackgroundEvents, ...mergeEvents(toBeMerged)],
            permissions
          ).filter(data => !bgEventIds.has(data.id));
        }
        return CalendarEventsUtil.setEventError(
          mergeEvents(toBeMerged),
          permissions
        );
      });
    },
    [eventData, allBackgroundEvents, perms]
  );

  const handleOnEventDrop = updateEvent;

  const handleOnEventResize = updateEvent;

  const onSelectHandler = (ev: CalendarComponentEvent) => {
    if (ev?.isBackgroundEvent) return;
    if (
      ev.state === EventStates.SAVED &&
      ev.busy &&
      !perms.staticEvent.canDeleteSaved
    ) {
      return;
    }

    setSelectedEvent(ev);
  };

  const onDeleteHandler = async () => {
    setEventData(prevState =>
      CalendarEventsUtil.setEventError(
        prevState.filter(ev => ev.id !== selectedEvent.id),
        permissions
      )
    );

    setSelectedEvent({});
  };

  /**
   * This is for Internal Unit Test event signalling
   * START
   */
  useEffect(() => {
    const sub = internalEventContext.calendarCreationEvent.subscribe(value => {
      const [convertedValue] =
        CalendarEventsUtil.translateFromStartTimeEndTimeProps([value]);
      if (
        CalendarEventsUtil.canAddEvent(
          perms,
          convertedValue.start.toISOString()
        )
      ) {
        handleSelectSlot({
          start: convertedValue.start,
          end: convertedValue.end,
          action: 'click',
        });
      } else {
        throw new Error('Unable to add event. Check permissions and end date');
      }
    });

    return () => {
      sub.unsubscribe();
    };
  }, []);
  /**
   * This is for Internal Unit Test event signalling
   * END
   */

  // Calendar will error if defaultView={startMode} is not one of the "views"
  return (
    <>
      <div data-test={name}>
        <DnDCalendar
          onSelectEvent={onSelectHandler}
          defaultDate={defaultDate}
          defaultView={isMobile ? 'day' : startMode}
          events={eventData}
          localizer={appLocalizer}
          scrollToTime={toTime}
          views={[Views.WEEK, Views.WORK_WEEK, Views.DAY]}
          backgroundEvents={allBackgroundEvents}
          eventPropGetter={eventPropGetter}
          slotPropGetter={slotPropGetter}
          components={components}
          step={timesConfig.increments}
          timeslots={timesConfig.slots}
          style={{ height }}
          formats={formats}
          resizable={
            perms.staticEvent.canResize || perms.eventBoundary.canResize
          }
          selectable={
            perms.staticEvent.canCreate ||
            perms.eventBoundary.canCreate ||
            perms.staticEvent.canDragToCreate ||
            perms.eventBoundary.canDragToCreate
              ? 'ignoreEvents'
              : false
          }
          draggableAccessor={handleDraggableAccessor}
          resizableAccessor={handleResizableAccessor}
          onSelectSlot={handleSelectSlot}
          onSelecting={handleOnSelecting}
          onEventDrop={handleOnEventDrop}
          onEventResize={handleOnEventResize}
          showMultiDayTimes
        />
      </div>
      {Object.keys(selectedEvent).length > 0 && (
        <CalendarDeleteEventModal
          permissions={perms}
          onDelete={onDeleteHandler}
          onClose={() => setSelectedEvent({})}
          deleteEvent={selectedEvent}
        />
      )}
    </>
  );
};
