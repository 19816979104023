import ApiHelper from '../../lib/api-helper';

const api = new ApiHelper('FILES');

export const getTerms = async () => {
  const text = await api.GET(
    '/files/axiom-tos.html',
    null,
    {
      'Content-Type': 'text/html; charset=UTF-8',
    },
    { bufferedRequest: true }
  );

  return text;
};
