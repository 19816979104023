import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { node, oneOf, string } from 'prop-types';

import {
  AxiomTheme,
  THEME_BG_NAMES,
  THEME_PALETTE_NAMES,
} from '../../../theme/axiom-theme';

const Wrapper = styled.div`
  color: ${({ theme }) => theme.text.default};
  background-color: ${({ theme, backgroundName }) =>
    theme.backgrounds[backgroundName]};
`;

export const DarkTheme = ({ children, backgroundName, className }) => (
  <ThemeProvider theme={AxiomTheme[THEME_PALETTE_NAMES.darkPalette]}>
    <Wrapper backgroundName={backgroundName} className={className}>
      {children}
    </Wrapper>
  </ThemeProvider>
);

DarkTheme.propTypes = {
  backgroundName: oneOf(Object.values(THEME_BG_NAMES)),
  children: node,
  className: string,
};

DarkTheme.defaultProps = {
  backgroundName: THEME_BG_NAMES.primary,
  children: null,
  className: null,
};
