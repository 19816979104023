import styled from 'styled-components';

export const ErrorBlurbWrapper = styled.div`
  margin: 1rem 0;
  text-align: left;
  display: flex;
  justify-content: ${({ align }) => align};
  svg {
    display: block;
    margin-right: 0.5rem;
  }
`;
