import React from 'react';
import styled from 'styled-components';
import { bool } from 'prop-types';
import { Dimmer, Loader as SLoader } from 'semantic-ui-react';

const LoaderSpinnerRoot = styled.div`
  ${({ fullscreen }) => !fullscreen && { minHeight: '100px' }}
`;

export const LoaderSpinner = ({ fullscreen }) => (
  <LoaderSpinnerRoot fullscreen={fullscreen}>
    <Dimmer active>
      <SLoader>Loading...</SLoader>
    </Dimmer>
  </LoaderSpinnerRoot>
);

LoaderSpinner.propTypes = {
  fullscreen: bool.isRequired,
};
