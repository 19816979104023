import styled from 'styled-components';

export const DivLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DivFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SectionWrapper = styled.div`
  margin-bottom: ${props => (props.margin ? '20px' : '0')} !important;

  ${props =>
    props.flexLabel
      ? `display: flex;
        flex-direction: column;
        flex-grow: 1;

        ${DivLabelWrapper} {
          flex-grow: 1;
        }`
      : ''}
`;
