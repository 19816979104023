import React from 'react';
import { GridColumn as SGridColumn } from 'semantic-ui-react';

import {
  ResponsiveConfig,
  ViewportName,
} from '../../../configs/responsive-config';

type ElementType = 'div' | 'span';
export type ResponsiveColumnOptions =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;
type FloatedType = 'left' | 'right';
type TextAlignType = 'left' | 'center' | 'right' | 'justified';
type VerticalAlignType = 'top' | 'middle' | 'bottom';

export interface GridColumnProps {
  as?: ElementType;
  children?: React.ReactNode;
  className?: string;
  desktopWidth?: ResponsiveColumnOptions;
  floated?: FloatedType;
  largeScreenWidth?: ResponsiveColumnOptions;
  mobileWidth?: ResponsiveColumnOptions;
  name?: string;
  /**
   * Usage:
   * only="desktop tablet"
   * only="largeScreen"
   * only="smallScreen"
   *
   * For Storybook, it uses this syntax
   * only={["desktop"]}
   */
  only?: string | ViewportName[];
  smallScreenWidth?: ResponsiveColumnOptions;
  stretched?: boolean;
  tabletWidth?: ResponsiveColumnOptions;
  textAlign?: TextAlignType;
  verticalAlign?: VerticalAlignType;
  widescreenWidth?: ResponsiveColumnOptions;
  width?: ResponsiveColumnOptions;
}

export const GridColumn = ({
  as = 'div',
  children,
  desktopWidth = null,
  className,
  floated,
  largeScreenWidth = null,
  mobileWidth = null,
  name,
  only = [],
  smallScreenWidth = null,
  stretched,
  tabletWidth = null,
  textAlign,
  verticalAlign,
  widescreenWidth = null,
  width,
}: GridColumnProps) => {
  interface AdditionalProps {
    mobile: ResponsiveColumnOptions;
    tablet: ResponsiveColumnOptions;
    largeScreen: ResponsiveColumnOptions;
    widescreen: ResponsiveColumnOptions;
  }

  const additionalProps: AdditionalProps = {
    mobile: mobileWidth || smallScreenWidth,
    tablet: tabletWidth || smallScreenWidth,
    largeScreen: desktopWidth || largeScreenWidth,
    widescreen: widescreenWidth || largeScreenWidth,
  };

  const keys = Object.keys(additionalProps) as (keyof AdditionalProps)[];
  return React.createElement(
    SGridColumn,
    {
      as,
      'data-test': name,
      className,
      floated,
      only: ResponsiveConfig.convertOnlyToSemanticOnly(only),
      stretched,
      textAlign,
      verticalAlign,
      ...keys.reduce((acc, propName) => {
        if (additionalProps[propName]) {
          acc[propName] = additionalProps[propName];
        }
        return acc;
      }, {} as AdditionalProps),
      width,
    },
    children
  );
};
