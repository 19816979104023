import { bool, func, node, string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { CaretIcon } from '../../icons/CaretIcon/CaretIcon';
import { AxiomTheme } from '../../../theme/axiom-theme';

const StyledButton = styled.button`
  background: transparent;
  border: 0;
  color: ${AxiomTheme.neutrals.neutral50};
  cursor: pointer;
  display: block;
  font: inherit;
  width: 100%;
`;

const StyledCaretIcon = styled(CaretIcon)`
  margin-left: 0.4rem;
`;

export const DSToggleButton = ({ children, className, expanded, onClick }) => (
  <StyledButton
    aria-expanded={expanded}
    className={`${className} hide-print`.trim()}
    onClick={onClick}
    type="button"
  >
    {children}
    <StyledCaretIcon
      direction={expanded ? 'up' : 'down'}
      height={12}
      width={12}
    />
  </StyledButton>
);

DSToggleButton.defaultProps = {
  children: null,
  className: null,
  expanded: false,
  onClick: () => {},
};

DSToggleButton.propTypes = {
  children: node,
  className: string,
  expanded: bool,
  onClick: func,
};
