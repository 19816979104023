import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const CertificationPlaceholderIcon = ({
  alt,
  className,
  height,
  name,
  width,
}) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 104 78"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <path
      d="M2 0h100a2 2 0 0 1 2 2v64a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm10 15a2 2 0 1 0 0 4h80a2 2 0 1 0 0-4H12zm0 17a2 2 0 1 0 0 4h80a2 2 0 1 0 0-4H12zm0 17a2 2 0 1 0 0 4h40a2 2 0 1 0 0-4H12z"
      fill="#BEBEBE"
    />
    <g transform="translate(65 50)">
      <circle
        stroke="#FFF"
        strokeWidth="2"
        fill="#D8D8D8"
        cx="13"
        cy="13"
        r="14"
      />
      <path
        fill="#FFF"
        d="M13 17l-4.702 2.472.898-5.236-3.804-3.708 5.257-.764L13 5l2.351 4.764 5.257.764-3.804 3.708.898 5.236z"
      />
    </g>
  </svg>
);

CertificationPlaceholderIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

CertificationPlaceholderIcon.defaultProps = {
  alt: null,
  className: null,
  height: 78,
  name: 'CERTIFICATION_PLACEHOLDER_ICON',
  width: 104,
};
