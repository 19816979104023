import { GenericDto } from './generic-dto';

export const UserDto = class UserDto extends GenericDto {
  constructor(body) {
    super(body, [
      'candidateId',
      'email',
      'firstName',
      'homeOffice',
      'isActive',
      'integrationId',
      'lastName',
      'oldPassword',
      'password',
      'roles',
      'salesforceId',
      'termsAndConditionsAcceptedAt',
      'token',
    ]);
  }
};
