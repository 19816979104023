import React from 'react';
import { oneOf, node, string, bool } from 'prop-types';
import styled from 'styled-components';

import { DataTestUtil } from '../../../utils/data-test-util';

const RawText = ({ as, children, dataTest }) =>
  React.createElement(as, { dataTest }, children);

RawText.propTypes = {
  as: string.isRequired,
  children: node.isRequired,
  dataTest: string.isRequired,
};

const SIZES = {
  XLARGE: 'xlarge',
  LARGE: 'large',
  REGULAR: 'regular',
  SMALL: 'small',
};

export const TextSketchStyles = ({ theme, size, bold, accent, subtle }) => {
  let fontSize;
  let lineHeight;
  switch (size) {
    case SIZES.XLARGE: {
      fontSize = '1.375rem';
      lineHeight = '2rem';
      break;
    }

    case SIZES.LARGE: {
      fontSize = '1.125rem';
      lineHeight = '1.75rem';
      break;
    }

    case SIZES.SMALL: {
      fontSize = '0.875rem';
      lineHeight = '1.5rem';
      break;
    }

    default: {
      // Assumed Regular
      fontSize = '1rem';
      lineHeight = '1.2rem';
    }
  }

  return {
    lineHeight,
    fontSize,
    fontFamily: accent ? 'Caslon' : 'Walsheim',
    fontWeight: bold ? 'bold' : 'normal',
    color: subtle ? theme.text.subtle : theme.text.default,
  };
};

const StyledText = styled(RawText)`
  ${props => TextSketchStyles(props)}
  ${({ uppercase }) => uppercase && 'text-transform: uppercase'};
`;

export const Text = ({
  as,
  accent,
  alt,
  bold,
  children,
  className,
  name,
  size,
  subtle,
  uppercase,
}) => (
  <StyledText
    as={as}
    accent={accent}
    title={alt}
    bold={bold}
    size={size}
    subtle={subtle}
    uppercase={uppercase}
    className={className}
    data-test={DataTestUtil.format(name || `text_${as}`)}
  >
    {children}
  </StyledText>
);

Text.propTypes = {
  accent: bool,
  as: oneOf(['p', 'div', 'span']),
  alt: string,
  bold: bool,
  children: node,
  className: string,
  name: string,
  size: oneOf([SIZES.XLARGE, SIZES.LARGE, SIZES.REGULAR, SIZES.SMALL]),
  subtle: bool,
  uppercase: bool,
};

Text.defaultProps = {
  accent: false,
  as: 'div',
  alt: null,
  bold: false,
  children: null,
  className: '',
  name: null,
  size: SIZES.REGULAR,
  subtle: false,
  uppercase: false,
};
