import React from 'react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { backgroundColorNames } from '../../../theme/axiom-theme';

export const BadgeBackgrounds = Object.values(backgroundColorNames);

export const BadgeFlushes = ['left', 'right'];

export type BadgeBackgroundType = (typeof BadgeBackgrounds)[number];

export const Badge = ({
  background,
  className,
  children,
  flush,
  minimal,
  name,
}: {
  background: BadgeBackgroundType;
  children?: React.ReactNode;
  className?: string;
  flush?: (typeof BadgeFlushes)[number];
  minimal?: boolean;
  name?: string;
}) => (
  <div
    className={AttrsHelper.formatClassname(
      'axiom-badge',
      `background-${background.replace(' ', '-')}`,
      flush && !minimal && `flush-${flush}`,
      minimal && 'minimal',
      className
    )}
    data-test={name}
  >
    {children}
  </div>
);
