import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Header1,
  Text,
} from '@axiom/ui';
import { useNavigate } from 'react-router-dom';

import { UserStore } from '../../stores/user-store';
import { TermsStore } from '../../stores/terms-store';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';

import { Terms } from './Terms';
import {
  TermsContainer,
  TermsHeader,
  TermsFooter,
  TermsTextOuter,
  TermsTextInner,
} from './TermsOfUseStyles';

const TermsOfUse = ({ userState, termsState }) => {
  const navigate = useNavigate();

  useEffect(() => {
    UserStore.load();
    TermsStore.load();
  }, []);

  const renderTerms = terms => {
    if (typeof terms === 'string') {
      return <TermsTextInner dangerouslySetInnerHTML={{ __html: terms }} />;
    }
    return (
      <TermsTextInner>
        <Terms />
      </TermsTextInner>
    );
  };

  return (
    <StoreStateTreatment
      allowOverflow
      name="TERMS_OF_USE"
      storeState={[userState, termsState]}
      renderLoadedView={({ data }) => {
        const [user, terms] = data;
        return (
          <TermsContainer>
            <TermsHeader>
              <Header1 accent>Terms of Use</Header1>
            </TermsHeader>
            <TermsTextOuter>{renderTerms(terms)}</TermsTextOuter>
            <TermsFooter onSubmit={e => e.preventDefault()}>
              <Gutter top="16px" gutterType="margin">
                <Grid>
                  <GridRow verticalAlign="middle">
                    <GridColumn width={7}>
                      <Text size="small" subtle>
                        By clicking on <strong>Agree and Submit</strong> you
                        acknowledge that you have read and agree to the Axiom{' '}
                        <a
                          href="https://www.axiomlaw.com/axiom-terms-of-use"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms of Use
                        </a>{' '}
                        and{' '}
                        <a
                          href="https://www.axiomlaw.com/privacy-notice"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Notice
                        </a>
                        .
                      </Text>
                    </GridColumn>
                    <GridColumn width={5} textAlign="right">
                      <Button
                        name="AGREE"
                        type="SUBMIT"
                        onClick={() => {
                          UserStore.setAcceptTermsAndConditions(user.id)
                            .then(() => UserStore.load())
                            .then(() => navigate('/sso'));
                        }}
                      >
                        Agree and Submit
                      </Button>
                    </GridColumn>
                  </GridRow>
                </Grid>
              </Gutter>
            </TermsFooter>
          </TermsContainer>
        );
      }}
    />
  );
};

TermsOfUse.propTypes = {
  userState: UserStore.getStateShape().isRequired,
  termsState: TermsStore.getStateShape().isRequired,
};

const mapStateToProps = state => ({
  userState: UserStore.select(state),
  termsState: TermsStore.select(state),
});

export default connect(mapStateToProps)(TermsOfUse);
