import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const PeopleGroupIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 207 152"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <g
      id="First-Run---NOT-APPROVED"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="7---My-Bench" transform="translate(-689.000000, -362.000000)">
        <g id="Group-15" transform="translate(692.000000, 365.000000)">
          <path
            d="M57,25.56348 C57,11.4458014 45.5987446,0 31.5360106,0 C17.4711842,0 6.07202114,11.4458014 6.07202114,25.56348 C6.07202114,35.6166284 11.8657588,44.2897269 20.2728507,48.4655855 C8.42592321,54.2546529 0,68.8533535 0,86"
            id="Stroke-1"
            stroke="#A3ABB6"
            strokeWidth="5.25"
            strokeLinejoin="round"
          />
          <path
            d="M126,25 C126,11.1935086 114.584717,0 100.5,0 C86.4173788,0 75,11.1935086 75,25"
            id="Stroke-3"
            stroke="#A3ABB6"
            strokeWidth="5.25"
            strokeLinejoin="round"
          />
          <path
            d="M201,86 C201,68.8533535 192.574386,54.2546529 180.727893,48.4655855 C189.132585,44.2897269 194.928202,35.6166284 194.928202,25.56348 C194.928202,11.4458014 183.527365,0 169.465147,0 C155.400837,0 144,11.4458014 144,25.56348"
            id="Stroke-5"
            stroke="#A3ABB6"
            strokeWidth="5.25"
            strokeLinejoin="round"
          />
          <path
            d="M89,45.6393435 C84.2848371,39.7662547 77.0196804,36 68.8685805,36 C54.6575448,36 43.136055,47.4458014 43.136055,61.5655806 C43.136055,71.6187289 48.9930061,80.2918275 57.4866425,84.4655855 C45.5168951,90.2546529 37,104.853354 37,122"
            id="Stroke-7"
            stroke="#5B6471"
            strokeWidth="5.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M164,122 C164,104.853354 155.67843,90.2546529 143.978223,84.4655855 C152.279129,80.2918275 158.003179,71.6187289 158.003179,61.5655806 C158.003179,47.4458014 146.743125,36 132.852488,36 C124.173422,36 116.519313,40.4720336 112,47.2714572"
            id="Stroke-9"
            stroke="#5B6471"
            strokeWidth="5.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="Group-14" transform="translate(68.000000, 59.000000)">
            <path
              d="M63.7962116,86.4723055 C63.7962116,69.2579055 55.27456,54.5976145 43.2930374,48.78496 C51.7956439,44.5941964 57.6552052,35.88576 57.6552052,25.7916509 C57.6552052,11.6143418 46.1244181,0.124014545 31.9019148,0.124014545 C17.6794116,0.124014545 6.14650839,11.6143418 6.14650839,25.7916509 C6.14650839,35.88576 12.0081858,44.5941964 20.5107923,48.78496 C8.52926968,54.5976145 0.00550193548,69.2579055 0.00550193548,86.4723055"
              id="Fill-11"
              fill="#FEFEFE"
            />
            <path
              d="M63.7962116,86.4723055 C63.7962116,69.2579055 55.27456,54.5976145 43.2930374,48.78496 C51.7956439,44.5941964 57.6552052,35.88576 57.6552052,25.7916509 C57.6552052,11.6143418 46.1244181,0.124014545 31.9019148,0.124014545 C17.6794116,0.124014545 6.14650839,11.6143418 6.14650839,25.7916509 C6.14650839,35.88576 12.0081858,44.5941964 20.5107923,48.78496 C8.52926968,54.5976145 0.00550193548,69.2579055 0.00550193548,86.4723055"
              id="Stroke-13"
              stroke="#F05B34"
              strokeWidth="6.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

PeopleGroupIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

PeopleGroupIcon.defaultProps = {
  alt: null,
  className: null,
  height: 152,
  name: 'PEOPLE_GROUP_ICON',
  width: 207,
};
