import React from 'react';
import { string, number, oneOfType } from 'prop-types';

export const AvatarsIcon = ({ title, height, width, color }) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 23 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title && <title>{title}</title>}
      <g fill="none">
        <path
          d="M19,0.5 C19.9664983,0.5 20.8414983,0.891750844 21.4748737,1.52512627 C22.1082492,2.15850169 22.5,3.03350169 22.5,4 L22.5,4 L22.5,14.7407407 C22.5,15.7072391 22.1082492,16.5822391 21.4748737,17.2156145 C20.8414983,17.8489899 19.9664983,18.2407407 19,18.2407407 L19,18.2407407 L4,18.2407407 C3.03350169,18.2407407 2.15850169,17.8489899 1.52512627,17.2156145 C0.891750844,16.5822391 0.5,15.7072391 0.5,14.7407407 L0.5,14.7407407 L0.5,4 C0.5,3.03350169 0.891750844,2.15850169 1.52512627,1.52512627 C2.15850169,0.891750844 3.03350169,0.5 4,0.5 L4,0.5 Z"
          stroke={color}
        />
        <g
          transform="translate(5.537037, 2.555556)"
          fill={color}
          stroke={color}
          strokeWidth="0.85"
        >
          <ellipse
            id="Oval"
            cx="9.4390681"
            cy="4.11728395"
            rx="1.77332736"
            ry="2.41450617"
          />
          <ellipse
            id="Oval"
            cx="2.7479092"
            cy="3.40740741"
            rx="2.3229092"
            ry="2.98240741"
          />
        </g>
        <path
          d="M13.9779625,14.4616013 C16.1527864,14.4619987 17.9524954,14.4662637 19.1666667,14.4673349 C19.1666667,12.419034 17.8018252,10.6996628 15.9593522,10.2246921 C15.7394215,10.1679962 15.3818841,11.0984914 14.8867398,11.8719022 C14.4460832,11.0987035 14.1154389,10.1685079 13.8948068,10.2255908 C13.2082393,10.4032228 12.8905505,10.5876513 12.3828201,11.0635185 C12.8548268,11.5162868 13.0761148,11.9108588 13.4813156,12.7182159 C13.8865164,13.5255731 13.8566008,14.124393 13.9779625,14.4616013 Z"
          fill={color}
        />
        <path
          d="M5.85096325,14.9945508 C8.77738186,15.0017887 11.6797592,14.9778968 13.2999999,14.9793263 C13.2999999,12.5767614 11.6991022,10.5600164 9.53796429,10.0028971 C9.27999546,9.93639534 8.86062006,11.0278243 8.27983808,11.9350004 C7.7629677,11.0280731 7.37513677,9.93699549 7.11634529,10.0039512 C5.97046195,10.3004195 4.98236935,11.0073186 4.30734458,11.9644254 C3.6323198,12.9215321 3.37900625,13.8920746 3.34876492,14.9710412 C4.00526026,14.9827173 4.83932637,14.9905539 5.85096325,14.9945508 Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

AvatarsIcon.defaultProps = {
  title: null,
  height: 19,
  width: 23,
  color: '#000',
};

AvatarsIcon.propTypes = {
  title: string,
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
  color: string,
};
