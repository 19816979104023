import React from 'react';
import styled from 'styled-components';
import { oneOf, string } from 'prop-types';

import { AxiomThemeZIndexes } from '../../../theme/axiom-theme-z-indexes';

import { LoaderBars } from './LoaderBars';
import { LoaderLogo } from './LoaderLogo';
import { LoaderSpinner } from './LoaderSpinner';

const TYPES = {
  bars: 'bars',
  spinner: 'spinner',
  logo: 'logo',
};

export const LoaderRoot = styled.div`
  ${({ expand }) => {
    return expand
      ? {
          position: expand === 'fullscreen' ? 'fixed' : 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          zIndex: AxiomThemeZIndexes.appScreenBlocker,
          minHeight: expand === 'cover' ? '100px' : 'initial',
        }
      : {
          position: 'relative',
        };
  }}
`;

export const Loader = ({ type, expand, className, name }) => {
  const LoaderType = {
    [TYPES.spinner]: <LoaderSpinner fullscreen={!!expand} />,
    [TYPES.bars]: <LoaderBars />,
    [TYPES.logo]: <LoaderLogo />,
  }[type];
  return (
    <LoaderRoot expand={expand} className={className} data-test={name}>
      {LoaderType}
    </LoaderRoot>
  );
};

Loader.propTypes = {
  type: oneOf(Object.values(TYPES)),
  expand: oneOf(['fullscreen', 'cover']),
  className: string,
  name: string,
};

Loader.defaultProps = {
  type: TYPES.spinner,
  expand: null,
  className: '',
  name: null,
};
