import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';

const RichTextWrapper = styled.div`
  & p {
    margin: 0;
  }
`;

const bulletRegex = /^-\s(.*)$/;
const numericRegex = /^\d+\.\s(.*)$/;
function isBulletLine(plainTextLine) {
  return bulletRegex.test(plainTextLine);
}
function isNumericLine(plainTextLine) {
  return numericRegex.test(plainTextLine);
}

export const RichText = ({ plainText }) => {
  if (!plainText) return <RichTextWrapper name="RICHTEXT" />;

  const children = [];
  const plainTextLines = plainText.split('\n');

  // create ul/ol, collect children, (close ul/ol), increment i
  for (let i = 0; i < plainTextLines.length; i += 1) {
    const keyValue = `key_${i}`;

    if (isBulletLine(plainTextLines[i])) {
      const indexOfNextNonBullet = plainTextLines.findIndex(
        (plainTextLine, j) => j > i && !bulletRegex.test(plainTextLine)
      );
      const childrenLines = plainTextLines.slice(
        i,
        indexOfNextNonBullet === -1 ? undefined : indexOfNextNonBullet
      );

      children.push(
        <ul key={keyValue}>
          {childrenLines.map((plainTextLine, idx) => {
            const subKey = `${keyValue}_${idx}`;
            return <li key={subKey}>{plainTextLine.match(bulletRegex)[1]}</li>;
          })}
        </ul>
      );
      i += childrenLines.length - 1;
    } else if (isNumericLine(plainTextLines[i])) {
      const indexOfNextNonNumeric = plainTextLines.findIndex(
        (plainTextLine, j) => j > i && !numericRegex.test(plainTextLine)
      );
      const childrenLines = plainTextLines.slice(
        i,
        indexOfNextNonNumeric === -1 ? undefined : indexOfNextNonNumeric
      );

      children.push(
        <ol key={keyValue}>
          {childrenLines.map((plainTextLine, idx) => {
            const subKey = `${keyValue}_${idx}`;
            return <li key={subKey}>{plainTextLine.match(numericRegex)[1]}</li>;
          })}
        </ol>
      );
      i += childrenLines.length - 1;
    } else {
      const row =
        plainTextLines[i] === '' ? (
          <br key={keyValue} />
        ) : (
          <p key={keyValue}>{plainTextLines[i]}</p>
        );
      children.push(row);
    }
  }

  return (
    <RichTextWrapper name="RICHTEXT" data-test="RICHTEXT">
      {children}
    </RichTextWrapper>
  );
};

RichText.defaultProps = {
  plainText: '',
};

RichText.propTypes = {
  plainText: string,
};
