import React, { useState } from 'react';
import { FormFieldProps } from 'semantic-ui-react';

import { IconName, IconNames } from '../../element/Icon/IconTypes';
import { IconButton } from '../../element/Button/IconButton';
import { FormField } from '../FormField/FormField';
import { useFormContext } from '../Form/useFormContext';

import { RawInput, RawInputProps, RawInputValueType } from './RawInput';

export type InputProps = {
  description?: string;
  disabled?: RawInputProps['disabled'];
  icon?: IconName;
  id?: RawInputProps['id'];
  label?: React.ReactNode;
  name: RawInputProps['name'];
  onBlur?: (value?: RawInputValueType) => void;
  onChange?: (value?: RawInputValueType) => void;
  onFocus?: (value?: RawInputValueType) => void;
  placeholder?: RawInputProps['placeholder'];
  Tooltip?: React.ReactNode;
};

export const Input = ({
  description,
  disabled,
  icon,
  id,
  label,
  name,
  onBlur = () => {},
  onChange = () => {},
  onFocus = () => {},
  placeholder,
  Tooltip,
}: InputProps) => {
  id = id || name;
  const schemaPropertyType = useFormContext().getSchemaProperty(name).type;
  const [displayAsPasswordType, setDisplayAsPasswordType] = useState(
    schemaPropertyType === 'SchemaPassword'
  );

  const getAppendButton = () => {
    switch (schemaPropertyType) {
      case 'SchemaPassword': {
        return (
          <IconButton
            pattern="secondary"
            variation="minimal"
            icon={displayAsPasswordType ? IconNames.eye : IconNames.eyeHidden}
            name="form-element-show-password"
            className="control-icon control-icon-hide-show-password fixed-dimension-inline"
            onClick={e => {
              e.stopPropagation();
              setDisplayAsPasswordType(!displayAsPasswordType);
            }}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <FormField
      {...{
        description,
        disabled,
        icon,
        id,
        name,
        label,
        onClear: (v: RawInputValueType) => onChange(v),
        Tooltip,
        AppendButton: getAppendButton(),
      }}
      renderField={({
        value,
        setFieldValue,
        onBlur: formOnBlur,
        invalid,
        schemaProperty,
      }: {
        value: RawInputProps['value'];
      } & FormFieldProps) => {
        return (
          <RawInput
            disabled={disabled}
            id={id}
            invalid={invalid}
            name={name}
            onBlur={(v, e) => {
              onBlur(v);
              formOnBlur(e);
            }}
            onChange={v => {
              onChange(v);
              setFieldValue(name, v);
            }}
            onFocus={() => {
              onFocus();
            }}
            placeholder={placeholder}
            type={
              !displayAsPasswordType && schemaProperty.type === 'SchemaPassword'
                ? 'SchemaString'
                : schemaProperty.type
            }
            value={value}
          />
        );
      }}
    />
  );
};
