import React from 'react';
import { arrayOf } from 'prop-types';

import { Gutter } from '../../layout/Gutter/Gutter';
import { Header4 } from '../../content/Header4/Header4';
import { DataTest } from '../../general/DataTest/DataTest';
import { LanguageShape } from '../../../models/language-model';
import { LanguageUtil } from '../../../utils/language-util';

export const Languages = ({ languages }) => {
  const groupedLanguages = LanguageUtil.groupByCategories(languages);
  const languageNames = Object.keys(groupedLanguages);

  return (
    <>
      {languageNames.map(lName => (
        <Gutter bottom="0.5rem" key={lName}>
          <DataTest className="pdf-no-break" value="language_item">
            <Header4 name="language_name">{lName}</Header4>
            {Object.keys(groupedLanguages[lName]).map(itemKey => (
              <DataTest value="language_proficiency" key={itemKey}>
                {LanguageUtil.buildLanguageDisplayBars(
                  groupedLanguages[lName][itemKey]
                )}
              </DataTest>
            ))}
          </DataTest>
        </Gutter>
      ))}
    </>
  );
};

Languages.propTypes = {
  languages: arrayOf(LanguageShape).isRequired,
};
