import React from 'react';
import { GridRow as SGridRow } from 'semantic-ui-react';

import {
  ResponsiveConfig,
  ViewportName,
} from '../../../configs/responsive-config';

type ElementType = 'div' | 'span';
type ResponsiveColumnOptions = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type TextAlignType = 'left' | 'center' | 'right' | 'justified';
type VerticalAlignType = 'top' | 'middle' | 'bottom';

export interface GridRowProps {
  as?: ElementType;
  children?: React.ReactNode;
  centered?: boolean;
  className?: string;
  columns?: ResponsiveColumnOptions;
  gutterTop?: string;
  gutterBottom?: string;
  gutterVertical?: string;
  name?: string;
  only?: string | ViewportName[];
  stretched?: boolean;
  textAlign?: TextAlignType;
  verticalAlign?: VerticalAlignType;
}

export const GridRow = ({
  children,
  as = 'div',
  centered,
  className,
  columns,
  gutterTop,
  gutterBottom,
  gutterVertical,
  name,
  only = [],
  stretched,
  textAlign,
  verticalAlign,
}: GridRowProps) => {
  const appendStyles: {
    paddingTop?: string;
    paddingBottom?: string;
  } = {
    paddingTop: null,
    paddingBottom: null,
  };

  if (gutterTop || gutterVertical) {
    appendStyles.paddingTop = gutterTop || gutterVertical;
  }

  if (gutterBottom || gutterVertical) {
    appendStyles.paddingBottom = gutterBottom || gutterVertical;
  }

  return React.createElement(
    SGridRow,
    {
      as,
      centered,
      'data-test': name,
      className,
      columns,
      style: appendStyles,
      only: ResponsiveConfig.convertOnlyToSemanticOnly(only),
      stretched,
      textAlign,
      verticalAlign,
    },
    children
  );
};
