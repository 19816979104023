import { shape } from 'prop-types';

import GenericStore from '../classes/generic-store';
import { PendoUtil } from '../utils/pendo-util';

const DEFAULT_FLAGS = {};

class PreloadedFeatureFlagsStoreClass extends GenericStore {
  bootstrap() {
    this.load();

    PendoUtil.initializePendo();
  }

  getDataShape() {
    return shape({});
  }

  /**
   *
   * @param flags - comes in as {"enable-login-client-signup": value}
   */
  load(flags = {}) {
    return this.setState({
      ...DEFAULT_FLAGS,
      ...flags,
    });
  }

  select(state) {
    return super.select(state).data || { ...DEFAULT_FLAGS };
  }
}

export const PreloadedFeatureFlagsStore = new PreloadedFeatureFlagsStoreClass();
