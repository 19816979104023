import React from 'react';
import { string, number } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const EmailIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 88 88"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <circle fill="#75b73b" cx="39" cy="39" r="39" />
    <path
      d="M-7102.2-12538h-42.583l10.215-10.216a1.007,1.007,0,0,0,0-1.415.992.992,0,0,0-.706-.294,1,1,0,0,0-.709.294l-10.014,10.017v-21.708l18.435,12.444c1.568,1.16,2.636,1.634,3.689,1.634a7.748,7.748,0,0,0,3.979-1.634l18.9-12.344v21.6l-10.007-10.009a.993.993,0,0,0-.708-.294.985.985,0,0,0-.705.294,1,1,0,0,0-.293.708,1,1,0,0,0,.293.707l10.213,10.214Zm-21.8-11.01a3.348,3.348,0,0,1-1.852-.729l-.009-.006-.019-.012-.015-.01c-.2-.129-.427-.274-.685-.42-1-.574-6.976-4.658-17.271-11.811-.9-.624-1.441-1-1.441-1h43.964l-.212.141-.171.112-1.136.746H-7101l-1.854,0-.034.022c-3.54,2.332-15.306,10.074-18.111,11.788A6.224,6.224,0,0,1-7124-12549.011Z"
      fill="#fff"
      transform="translate(7163 12590)"
    />
  </svg>
);

EmailIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

EmailIcon.defaultProps = {
  alt: null,
  className: null,
  height: 88,
  name: 'EMAIL_ICON',
  width: 88,
};
