import React, { useState } from 'react';
import { Menu } from 'semantic-ui-react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { Layout } from '../../layout/Layout/Layout';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { Icon } from '../Icon/Icon';

import { ContextMenuHeader } from './ContextMenuHeader';
import { ContextMenuDivider } from './ContextMenuDivider';

export const ContextMenuCollapsibleSection = ({
  children,
  className,
  expanded,
  name,
  title,
}: {
  children: React.ReactNode;
  className?: string;
  expanded?: boolean;
  name?: string;
  title: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(!!expanded);
  return (
    <>
      <ContextMenuHeader>
        <div
          className={AttrsHelper.formatClassname(
            'context-menu-item-section',
            className
          )}
          data-test={name}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          <Layout position="middle" horizontalGutter="8px">
            <Icon name={isOpen ? 'arrow-down' : 'arrow-right'} />
            <LayoutItem fluid>{title}</LayoutItem>
          </Layout>
        </div>
      </ContextMenuHeader>
      {isOpen && (
        <Menu
          borderless
          vertical
          fixed="top"
          floated={false}
          className="context-menu-section-children"
          data-test="collapsible-children"
        >
          {children}
        </Menu>
      )}
      <ContextMenuDivider />
    </>
  );
};
