import React from 'react';
import { number, string } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const SearchIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 22.51 23.98"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <path d="M9 0a9 9 0 104.748 16.637l7.346 7.343 1.414-1.414-7.176-7.173A9 9 0 0018 8.939 9 9 0 009 0zm0 .92a8 8 0 018 8.02 8 8 0 11-8-8V.92z" />
  </svg>
);

SearchIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

SearchIcon.defaultProps = {
  alt: null,
  className: null,
  height: 22.51,
  name: 'SEARCH_ICON',
  width: 23.98,
};
