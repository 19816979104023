import React from 'react';
import { node } from 'prop-types';
import { Gutter, Text } from '@axiom/ui';

import CommonCard from '../CommonCard/CommonCard';

/* eslint-disable react/jsx-props-no-spreading */
const CommonCardConfirmation = ({ children, ...props }) => (
  <CommonCard center {...props}>
    <Gutter top="1.5rem" bottom="2.5rem">
      <Text className="text-align-center">{children}</Text>
    </Gutter>
  </CommonCard>
);

CommonCardConfirmation.defaultProps = {
  children: null,
};

CommonCardConfirmation.propTypes = {
  children: node,
};

export default CommonCardConfirmation;
