// https://github.com/matthewmueller/next-cookies

/* eslint-disable */
/**
 * Module dependencies
 */

const parser = require('./server-cookies');

/**
 * Export `Cookies`
 */

module.exports = Cookies;

/**
 * Cookies function
 */

function Cookies(ctx, options) {
  options = options || {};
  if (ctx.req) {
    // server
    const cookies = ctx.req.headers.cookie;
    if (!cookies) return {};
    return parser.parse(cookies, options);
  } else {
    // browser
    return require('./browser-cookies')();
  }
}
/* eslint-enable */
