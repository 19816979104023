import moment from 'moment';
import { oneOfType, string, instanceOf } from 'prop-types';

const monthOptions = moment.months().map((name, index) => ({
  label: name,
  value: String(index),
}));

export const DatePropType = oneOfType([
  string,
  instanceOf(Date),
  instanceOf(moment),
]);

export const DateUtil = {
  castAsMoment(date) {
    if (date instanceof moment) {
      return date;
    }

    if (typeof date === 'string') {
      if (/^\d{2}\/\d{2}\/\d{4}$/.test(date)) {
        return moment(date, 'MM/DD/YYYY');
      }
      if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
        return moment(date, 'YYYY-MM-DD');
      }
      if (/^\d{4}-\d{2}-\d{2}T.+$/.test(date)) {
        return moment(date);
      }
    }

    return moment(date);
  },
  getMonthOptions() {
    return monthOptions;
  },
  getMonthName(date) {
    return this.castAsMoment(date).format('MMMM');
  },
  getMonthValue(date) {
    return this.castAsMoment(date).month().toString();
  },
  getYearOptionsByRange(startYear, endYear) {
    if (!Number.isInteger(startYear) || !Number.isInteger(endYear)) {
      throw new Error('Start and End years must be numbers');
    }

    const isPastToFuture = startYear < endYear;
    return Array.from({ length: Math.abs(startYear - endYear) + 1 })
      .fill(null)
      .map((obj, index) => {
        const year = isPastToFuture ? startYear + index : startYear - index;

        return {
          label: year,
          value: year,
        };
      });
  },
  /**
   * Returns the following structure and order
   * [
   *  { label: "2019", value: "2019"}
   *  { label: "2020", value: "2020"}
   *  { label: "2021", value: "2021"}
   * ]
   */
  getYearOptionsByAmount(
    allowCurrentYear = false,
    yearsIntoFuture = 0,
    yearsIntoPast = 0
  ) {
    const yearOptions = [];
    const currentYear = moment().year();

    if (yearsIntoPast > 0) {
      yearsIntoPast *= -1;
    }

    for (let i = yearsIntoPast; i <= yearsIntoFuture; i++) {
      const yearOption = currentYear + i;

      if (
        yearOption !== currentYear ||
        (yearOption === currentYear && allowCurrentYear)
      ) {
        yearOptions.push({
          label: yearOption,
          value: yearOption,
        });
      }
    }

    return yearOptions;
  },
  formatAsDate(date) {
    return date ? DateUtil.castAsMoment(date).format('YYYY-MM-DD') : null; // 2019-02-12
  },
  getTimeFormat() {
    return 'HH:mm:ssZZ';
  },
  formatAsTime(date) {
    return date
      ? DateUtil.castAsMoment(date).format(DateUtil.getTimeFormat())
      : null; // 22:44:30-0600
  },
  formatAsTimestamp(date) {
    return date ? DateUtil.castAsMoment(date).toISOString() : null; // 2013-02-04T22:44:30.652Z
  },
  formatAsYear(date) {
    return date ? DateUtil.castAsMoment(date).format('YYYY') : null; // 2020
  },
  displayLongMonth(date) {
    return date ? DateUtil.castAsMoment(date).format('MMMM') : null; // January
  },
  displayLongDay(date) {
    return date ? DateUtil.castAsMoment(date).format('dddd') : null; // Friday
  },
  displayShortDate(date) {
    return date ? DateUtil.castAsMoment(date).format('l') : null; // 2/2/22
  },
  displayDate(date) {
    return date ? DateUtil.castAsMoment(date).format('MM/DD/YY') : null; // 02/12/20
  },
  displayDateRange(startDate, endDate) {
    if (startDate) {
      return endDate && DateUtil.castAsMoment(endDate).isBefore(new Date())
        ? `${DateUtil.formatAsYear(startDate)} - ${DateUtil.formatAsYear(
            endDate
          )}`
        : `${DateUtil.formatAsYear(startDate)} - Present`;
    }
    return null;
  },
  displayDateFullYearShortMonth(date) {
    return date ? DateUtil.castAsMoment(date).format('M/DD/YYYY') : null; // 2/12/2020
  },
  displayDateFullYearShortMonthDayName(date) {
    return date ? DateUtil.castAsMoment(date).format('dddd M/DD/YYYY') : null; // Wednesday 2/12/2020
  },
  getMonthYearFormat() {
    return 'MM/YYYY';
  },
  displayMonthYear(date) {
    return date
      ? DateUtil.castAsMoment(date).format(DateUtil.getMonthYearFormat())
      : null; // 02/2019
  },
  displayLongMonthYear(date) {
    return date ? DateUtil.castAsMoment(date).format('MMMM YYYY') : null; // July 2019
  },
  displayLongMonthDayYear(date) {
    return date ? DateUtil.castAsMoment(date).format('MMMM D, YYYY') : null; // July 7, 2019
  },
  displayLongMonthDayTime(date) {
    return date ? DateUtil.castAsMoment(date).format('MMMM Do - LT') : null; // March 14th - 4:06 PM
  },
  displayTimestamp(date) {
    return date ? DateUtil.castAsMoment(date).format('ddd. L LT') : null; // Fri. 07/12/2019 10:22 AM
  },
  displayFullTime(date) {
    return date ? DateUtil.castAsMoment(date).format('LT') : null;
  }, // 10:22 AM
  displayFullTimeRange(startDate, endDate) {
    return startDate && endDate
      ? `${DateUtil.castAsMoment(startDate).format(
          'LT'
        )} - ${DateUtil.castAsMoment(endDate).format('LT')}`
      : null;
  }, // 10:22 AM - 10:52 AM
  displayTimezoneAbbreviation(date) {
    return date ? DateUtil.castAsMoment(date).format('z') : null;
  }, // PST
  displayDayAndDate(date) {
    return date ? DateUtil.castAsMoment(date).format('ddd. L') : null; // Fri. 07/12/2019
  },
  displayBannerTimestamp(startDate, endDate, timezone) {
    return startDate && endDate
      ? `${DateUtil.castAsMoment(startDate)
          .tz(timezone.iana)
          .format(`dddd L LT`)} - ${DateUtil.castAsMoment(endDate)
          .tz(timezone.iana)
          .format('LT')}`
      : null; // Friday 07/12/2019 10:22 AM - 10:52AM
  },
  displayDatestamp(date) {
    return date
      ? DateUtil.castAsMoment(date).tz(moment.tz.guess()).format('L LT')
      : null; // 07/12/2019 10:22 AM
  },
  displayEndDate(endDate) {
    return endDate && DateUtil.castAsMoment(endDate).isBefore(new Date())
      ? DateUtil.displayLongMonthYear(endDate)
      : 'Present';
  },
  yearToYearsCount(year) {
    return year === null ? null : new Date().getFullYear() - year;
  },
  yearsCountToYear(years) {
    const yrs = Number.parseInt(years, 10);
    return Number.isNaN(yrs) ? null : new Date().getFullYear() - yrs;
  },
  addDaysToNow(daysToAdd) {
    return moment().startOf('day').add(daysToAdd, 'd').format('YYYY-MM-DD'); // 2019-02-12
  },
  displayDateLongYear(date) {
    return date ? DateUtil.castAsMoment(date).format('MM/DD/YYYY') : null; // 02/12/2024
  },
  getDaysFromNow(futureDate) {
    return DateUtil.castAsMoment(futureDate).diff(
      moment().startOf('day'),
      'days'
    ); // returns integer
  },
  getCountFromDateToDate(fromDate, toDate, unitOfTime = 'days') {
    return Math.abs(
      moment(fromDate).diff(this.castAsMoment(toDate), unitOfTime)
    );
  },
  displayRelativeTime(date, withSuffix = true) {
    const defaultRounding = moment.relativeTimeRounding();
    // round down for this calculation
    moment.relativeTimeRounding(Math.floor);
    const relativeTime = date
      ? DateUtil.castAsMoment(date).utc().fromNow(!withSuffix)
      : null;
    // restore the default rounding
    moment.relativeTimeRounding(defaultRounding);
    return relativeTime; // e.g. ten minutes ago, a month ago
  },
  isDateBeforeDate(
    beforeDate,
    afterDate = new Date(),
    isSameOr = false,
    granularity = 'day'
  ) {
    return moment(beforeDate)[isSameOr ? 'isSameOrBefore' : 'isBefore'](
      moment(afterDate),
      granularity
    );
  },
  isDateAfterDate(
    afterDate,
    beforeDate = new Date(),
    isSameOr = false,
    granularity = 'day'
  ) {
    return moment(afterDate)[isSameOr ? 'isSameOrAfter' : 'isAfter'](
      moment(beforeDate),
      granularity
    );
  },
  getYearsPeriod(date, yearItemNumber = 12) {
    const endPeriod =
      Math.ceil(moment(date).year() / yearItemNumber) * yearItemNumber;
    const startPeriod = endPeriod - (yearItemNumber - 1);
    return { startPeriod, endPeriod };
  },
};
