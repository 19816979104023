import React from 'react';
import { number, string } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const ShareBenchIcon = ({
  alt,
  bgcolor,
  className,
  height,
  name,
  width,
}) => {
  return (
    <svg
      className={className}
      data-test={DataTestUtil.format(name)}
      focusable="false"
      height={height}
      role={alt ? 'img' : 'presentation'}
      viewBox="0 0 300 300"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      {alt && <title>{alt}</title>}
      <circle cx="150" cy="150" r="150" fill={bgcolor} />
      <path
        d="M74.8 81.8a14 14 0 00-14 14v100.5H41.3v10l13 12h191.4l13-12v-10h-19V95.7a14 14 0 00-14-14z"
        fill="#fff"
      />
      <path
        d="M41.3 196.3v10l13 12h191.4l13-12v-10h-86l-7 9h-31.4l-7-9zm19.4 0V95.7a14 14 0 0114-14h151a14 14 0 0114 14v100.5"
        fill="none"
        stroke="#313944"
        strokeWidth="5"
      />
      <path
        d="M119.8 146.8c10 .1 19-14.6 18.4-23.9-.5-9.2-4-18.2-18-18.2-14.1 0-17.3 8.6-18 17.8-.8 9.2 7.6 24.1 17.6 24.3z"
        fill="#f4f4f4"
      />
      <path
        d="M119.8 146.8c10 .1 19-14.6 18.4-23.9-.5-9.2-4-18.2-18-18.2-14.1 0-17.3 8.6-18 17.8-.8 9.2 7.6 24.1 17.6 24.3zm-8.5-2.6l-25 12v7c0 6.1 4.9 11 11 11h46c6 0 11-4.9 11-11v-5l-25-14"
        fill="none"
        stroke="#f15b35"
        strokeWidth="5"
      />
      <path
        d="M172.3 134.2h32m-32 13h32m-32 13h32m-37 13h48v-50"
        fill="none"
        stroke="#7d8794"
        strokeWidth="5"
        strokeLinecap="round"
      />
    </svg>
  );
};

ShareBenchIcon.propTypes = {
  alt: string,
  bgcolor: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

ShareBenchIcon.defaultProps = {
  alt: null,
  bgcolor: '#fff',
  className: null,
  height: 82,
  name: 'SHARE_BENCH_ICON',
  width: 82,
};
