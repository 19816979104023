import React from 'react';
import styled from 'styled-components';

import { Icon } from '../../element/Icon/Icon';

export const FormErrorRoot = styled.div``;

export const FormError = ({ error = null }: { error?: string }) => {
  if (!error) {
    return null;
  }
  return (
    <FormErrorRoot data-test="form-error" className="form-error">
      <span className="form-error-icon">
        <Icon name="alert-filled" />
      </span>
      <span className="form-error-text">{error}</span>
    </FormErrorRoot>
  );
};
