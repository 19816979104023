import React from 'react';

import { DefaultLayout } from '../layouts/DefaultLayout';
import RequestPassword from '../components/RequestPassword/RequestPassword';
import { Page } from '../components/Page/Page';

export const RequestPasswordPage = () => (
  <Page title="Axiom Law - Request Password">
    <DefaultLayout>
      <RequestPassword />
    </DefaultLayout>
  </Page>
);
