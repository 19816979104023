import React from 'react';
import { Subject } from 'rxjs';
import { CalendarEventType } from '@axiom/validation';
import once from 'lodash/once';

/**
 * ONLY TESTS WORK WITH THIS CONTEXT
 *
 * This is only used by testing unit helpers when we have no other option.
 * Do not use flippantly and only use if there are no other options.
 *
 * calendarCreationEvent: Signals when tests need creation events.
 * We need this because jsdom doesn't set a width/height for the calendar component
 * to be clickable to make events so we have to circumvent for testing.
 */
export const InternalEvents = {
  calendarCreationEvent: new Subject<CalendarEventType>(),
};

export type InternalEventContextType = {
  calendarCreationEvent: Subject<CalendarEventType>;
};

const invokeInternalEventContext = once(() =>
  React.createContext(InternalEvents)
);

export const useInternalEventContext = () => {
  return React.useContext(invokeInternalEventContext());
};

export const InternalEventProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const InternalEventContext = invokeInternalEventContext();
  return (
    <InternalEventContext.Provider value={InternalEvents}>
      {children}
    </InternalEventContext.Provider>
  );
};
