import styled from 'styled-components';

import { PALETTE } from '../../themes/constants';

const { passwordStrength } = PALETTE;

const PASSWORD_STRENGTH_COLORS = [
  passwordStrength.veryWeak,
  passwordStrength.weak,
  passwordStrength.fair,
  passwordStrength.strong,
  passwordStrength.veryStrong,
];

export const StrengthMeter = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
`;

export const StrengthBlock = styled.div<{ full: boolean; score: number }>`
  border: ${({ full, theme }) =>
    full ? 'none' : `1px solid ${theme.colors.contentBreak}`};
  width: 20%;
  height: 5px;
  background-color: ${({ full, score }) =>
    full ? PASSWORD_STRENGTH_COLORS[score] : 'transparent'};

  :first-child {
    border-radius: 2px 0 0 2px;
  }

  :last-child {
    border-radius: 0 2px 2px 0;
  }
`;
