/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { string, node } from 'prop-types';

import { DefaultCardHeader } from './DefaultCardHeader';
import { DefaultCardBody } from './DefaultCardBody';
import { RSCardWrapper } from './CardLayoutStyles';

export const CardLayout = ({ header, body, footer, ...rest }) => (
  <RSCardWrapper {...rest}>
    <DefaultCardHeader header={header} />
    <DefaultCardBody body={body} />
  </RSCardWrapper>
);

CardLayout.defaultProps = {
  className: 'panel',
  header: null,
  body: null,
  footer: null,
};
CardLayout.propTypes = {
  className: string,
  header: node,
  body: node,
  footer: node,
};
