import styled, { css } from 'styled-components';
import { MediaQueryUtil } from '@axiom/ui';

export const TermsContainer = styled.div`
  background-color: ${({ theme }) => theme.neutrals.neutral10};
  display: flex;
  flex-direction: column;
  height: calc(100vh - 85px); /* site header height */
  padding: 0 max(1rem, calc(50% - 35rem));
`;

export const TermsHeader = styled.div`
  flex: none;
  padding-top: 1.75rem;
  padding-bottom: 0.75rem;

  ${MediaQueryUtil.largeScreenOnly(css`
    padding-top: 2.9375rem;
    padding-bottom: 1.75rem;
  `)}

  ${MediaQueryUtil.widescreenOnly(css`
    padding-top: 5.875rem;
  `)}
`;

export const TermsFooter = styled.form`
  padding-bottom: 6.625rem;

  ${MediaQueryUtil.largeScreenOnly(css`
    padding-top: 1.75rem;
    padding-bottom: 3.625rem;
  `)}
`;

export const TermsTextOuter = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.neutrals.lightest};
  border-radius: 7px;
  flex: 1 1 auto;
  max-height: 90vh;
  min-height: min(90vh, 24rem); /* ensure it's still usable on small screens */
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;

  ${MediaQueryUtil.mobileOnly(css`
    padding-top: 2.375rem;
    padding-bottom: 2.5625rem;
  `)}
`;

export const TermsTextInner = styled.div`
  overflow-y: scroll;
  padding-left: 1rem;
  padding-right: 1.25rem;

  /* begin styles copied from HubSpot */
  p {
    font-size: 18px;
    letter-spacing: 0;
    font-weight: normal;
    font-style: normal;
    line-height: 29px;
    color: #5b6471;
    margin: 0 0 30px;
  }
  a {
    color: #ff5a2d;
    text-decoration: none;
  }
  a:hover {
    color: #1c222e;
  }
  ul,
  ol {
    padding-left: 40px;
    letter-spacing: 0;
    font-weight: normal;
    font-style: normal;
    color: #5b6471;
    line-height: 26px;
    margin: 0;
    padding: 0 0 0 40px;
  }
  ul li {
    display: list-item;
    margin: 0 0 10px 0;
    padding: 0;
  }
  ul li:last-child {
    margin-bottom: 0;
  }
  /* end styles copied from HubSpot */
  ${MediaQueryUtil.mobileOnly(css`
    padding-left: 2.125rem;
    padding-right: 2.6875rem;
  `)}
`;
