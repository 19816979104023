import { ZodError, ZodIssue } from 'zod';
import get from 'lodash/get';

const setStructure = (
  structure: Record<string, unknown> | Array<unknown>,
  keys: Array<string | number>,
  value: unknown
): Record<string, unknown> | Array<unknown> | unknown => {
  if (keys.length === 0) {
    return value;
  }

  const key = keys.shift();

  if (Number.isInteger(key)) {
    const arrKey = key as number;
    if (!Array.isArray(structure)) {
      structure = [];
    }
    structure[arrKey] = setStructure(
      structure[arrKey] as Array<number>,
      keys,
      value
    );
  } else {
    if (!structure) {
      structure = {} as Record<string, unknown>;
    }

    const strutKey = key as string;
    let innerValue = (structure as Record<string, unknown>)[strutKey];
    innerValue = setStructure(
      innerValue as Record<string, unknown>,
      keys,
      value
    );
    (structure as Record<string, unknown>)[strutKey] = innerValue;
  }

  return structure;
};

const formatErrors = (errors: Array<ZodIssue>) => {
  return errors?.reduce((acc: Record<string, string>, err) => {
    let errorMessage = err.message;

    if (
      err.code === 'invalid_type' &&
      err.received === 'null' &&
      err.message.startsWith('Expected')
    ) {
      errorMessage = 'Required';
    }

    if (
      err.code === 'too_big' &&
      err.type === 'string' &&
      err.message.startsWith('String')
    ) {
      errorMessage = `Maximum character limit reached: ${err.maximum}`;
    }

    setStructure(acc, err.path, errorMessage);

    return acc;
  }, {});
};

export const FormSchemaUtil = {
  findNode: (schema: SchemaDefinition, propPath: string) => {
    const path = propPath
      .split('.')
      .map((part, idx) => {
        const prependJoin = idx > 0 ? '.' : '';
        if (!Number.isNaN(+part)) {
          return `${prependJoin}arrayType`;
        }
        return `${prependJoin}children.${part}`;
      })
      .join('');
    const prop = get(schema, path);
    if (!prop) {
      throw new Error(`Unknown property: ${propPath}`);
    }
    return prop;
  },
  getRoot: (schema: ZodMergedType) => {
    return schema._def as { typeName: string };
  },
  isObjectSchema: (schema: ZodMergedType) => {
    return FormSchemaUtil.getRoot(schema).typeName === 'ZodObject';
  },
  isArraySchema: (schema: ZodMergedType) => {
    return FormSchemaUtil.getRoot(schema).typeName === 'ZodArray';
  },
  validate: (schema: ZodMergedType, formData: unknown) => {
    const parsedStructure = schema.safeParse(formData) as {
      success: boolean;
      data?: unknown;
      error?: ZodError;
    };
    return {
      values: parsedStructure.data,
      errors: formatErrors(parsedStructure.error?.issues) || null,
    };
  },
};
