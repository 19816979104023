import styled, { css } from 'styled-components';
import { MediaQueryUtil } from '@axiom/ui';

export const LeftColumn = styled.div``;
export const RightColumn = styled.div<{ imageUrl: string }>`
  height: 576px;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
export const TwoColumnLayout = styled.div<{ leftColumnOnly: boolean }>`
  display: flex;

  ${LeftColumn}, ${RightColumn} {
    width: 50%;
  }

  ${({ leftColumnOnly }) =>
    leftColumnOnly
      ? css`
          ${LeftColumn} {
            width: 100%;
          }
          ${RightColumn} {
            display: none;
          }
        `
      : ''}

  ${MediaQueryUtil.smallScreenOnly(css`
    ${LeftColumn} {
      width: 100%;
    }
    ${RightColumn} {
      display: none;
    }
  `)}
`;
