import { z } from 'zod';
import { Api } from '@axiom/ui';
import { SchemaEmail, SchemaPassword } from '@axiom/types';
import { AuthConstFlowTypesValueTypes } from '@axiom/const';

import ApiHelper from '../../lib/api-helper';
import { EnvUtil } from '../../utils/env-util';

export const readAuthRequestSchema = z.object({ email: SchemaEmail });
export type ReadAuthRequest = Required<z.infer<typeof readAuthRequestSchema>>;
export type ReadAuthResponse = {
  flowType?: AuthConstFlowTypesValueTypes;
  authRedirectUrl?: string;
};
export type LoginFlowType = 'Talent' | 'Client';

export const createAuthPasswordRequestSchema = z.object({
  email: SchemaEmail,
  password: SchemaPassword,
});
export const createAuthTokenRequestSchema = z.object({
  token: z.string(),
});
export const updateTokenRequestSchema = z.object({
  password: SchemaPassword,
});
export type CreateAuthPasswordRequest = Required<
  z.infer<typeof createAuthPasswordRequestSchema>
>;
export type CreateAuthTokenRequest = Required<
  z.infer<typeof createAuthTokenRequestSchema>
>;
export type UpdateTokenRequest = Required<
  z.infer<typeof updateTokenRequestSchema>
>;
export type CreateAuthResponse = string;

class AuthApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readAuthForEmail(email: string, queryObject?: { [key: string]: string }) {
    return super.read<{ data: ReadAuthResponse }>({
      endpoint: `/auth/${email}`,
      query: queryObject,
      method: 'GET',
    });
  }

  createAuth(body: CreateAuthPasswordRequest | CreateAuthTokenRequest) {
    return super.write<{ data: CreateAuthResponse }>({
      endpoint: `/auth`,
      method: 'POST',
      body,
    });
  }

  readTokenIsValid(token: string) {
    return super.read<{ data: never }>({
      endpoint: `/auth/resets/${token}`,
      method: 'GET',
    });
  }

  updateTokenSetPassword(token: string, body: UpdateTokenRequest) {
    return super.write<{ data: never }>({
      endpoint: `/auth/resets/${token}`,
      method: 'PATCH',
      body,
    });
  }

  createForgotPassword(body: {
    email: string;
    RelayState?: string;
    loginFlow: LoginFlowType;
  }) {
    return super.write<{ data: never }>({
      endpoint: `/auth/resets/`,
      method: 'POST',
      body,
    });
  }
}

export const AuthApi = new AuthApiClass();

const api = new ApiHelper('AUTH CRUD');

export const getAuthInfoForEmail = async (
  email: string,
  loginFlow: LoginFlowType
) => {
  const { data } = await api.GET(`/auth/${email}?loginFlow=${loginFlow}`);
  return data;
};

export const requestMagicLink = async (email: string, RelayState: string) => {
  const { data } = await api.POST(`/auth/tokenLogin`, {
    email,
    ...(RelayState ? { RelayState } : {}),
  });
  return data;
};

export const loginAs = async (loginObj: {
  email: string;
  password: string;
}) => {
  const { data } = await api.POST(`/auth`, loginObj);
  return data;
};

export const loginWithToken = async (token: string) => {
  const { data } = await api.PATCH(`/auth/tokenLogin/${token}`);
  return data;
};

export const requestPassword = async (
  email: string,
  loginFlow: LoginFlowType
) => {
  const { data } = await api.POST(`/auth/resets`, { email, loginFlow });
  return data;
};

export const validateResetToken = async (token: string) => {
  const { data } = await api.GET(`/auth/resets/${token}`);
  return !!data;
};

export const resetPassword = async (resetPasswordForm: {
  token: string;
  password: string;
  passwordConfirm: string;
}) => {
  const { data } = await api.PATCH(
    `/auth/resets/${resetPasswordForm.token}`,
    resetPasswordForm
  );
  return data;
};
