import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';

import { Loader } from '../../element/Loader/Loader';

const BlockerEvent = new Subject();
const blockerEvents = new Set();
export const BlockerUtil = {
  block() {
    const eventTimestamp = new Date().toISOString();
    blockerEvents.add(eventTimestamp);
    BlockerEvent.next(eventTimestamp);
    return eventTimestamp;
  },
  unblock(eventTimestamp: string) {
    blockerEvents.delete(eventTimestamp);
    BlockerEvent.next(eventTimestamp);
  },
  unblockAll() {
    blockerEvents.clear();
  },
};

export const Blocker = ({ children }: { children: React.ReactNode }) => {
  const [showBlocker, setShowBlocker] = useState(false);
  useEffect(() => {
    const subscription = BlockerEvent.subscribe({
      next: () => {
        setShowBlocker(blockerEvents.size > 0);
      },
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <>
      {showBlocker && <Loader expand="fullscreen" name="blocking-loader" />}
      {children}
    </>
  );
};
