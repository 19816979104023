export const ViewportNames = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktop: 'desktop',
  widescreen: 'widescreen',
  largeScreen: 'largeScreen',
  smallScreen: 'smallScreen',
} as const;
export type ViewportName = (typeof ViewportNames)[keyof typeof ViewportNames];
export const ResponsiveConfig = {
  responsiveColumnCount: 12,
  responsiveColumnOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'equal'],
  // These have a relating semantic css compiler set that need to be changed in tandem
  // These also have a relating set in css-helpers.css unfortunately
  breakpoints: {
    mobile: 360,
    tablet: 768,
    desktop: 1025,
    widescreen: 1920,
  },
  hasMobile: (viewports: ViewportName[]) => {
    return (
      viewports.includes(ViewportNames.smallScreen) ||
      viewports.includes(ViewportNames.mobile)
    );
  },
  hasTablet: (viewports: ViewportName[]) => {
    return (
      viewports.includes(ViewportNames.smallScreen) ||
      viewports.includes(ViewportNames.tablet)
    );
  },

  hasDesktop: (viewports: ViewportName[]) => {
    return (
      viewports.includes(ViewportNames.largeScreen) ||
      viewports.includes(ViewportNames.desktop)
    );
  },

  hasWidescreen: (viewports: ViewportName[]) => {
    return (
      viewports.includes(ViewportNames.largeScreen) ||
      viewports.includes(ViewportNames.widescreen)
    );
  },

  viewports: ViewportNames,
  convertOnlyToSemanticOnly: (onlyProps: ViewportName[] | string) => {
    if (!Array.isArray(onlyProps) && typeof onlyProps !== 'string') {
      throw new Error(
        'Must pass a string list of viewports: "mobile tablet" or an array of viewports: ["mobile", "tablet"]'
      );
    }

    const semanticViewportNamespaces = {
      mobile: 'mobile',
      tablet: 'tablet',
      largeScreen: 'large screen',
      widescreen: 'widescreen',
      computer: 'computer',
    };

    const semanticBreakpointTranslations = {
      [ViewportNames.mobile]: semanticViewportNamespaces.mobile,
      [ViewportNames.tablet]: semanticViewportNamespaces.tablet,
      [ViewportNames.desktop]: semanticViewportNamespaces.largeScreen,
      [ViewportNames.widescreen]: semanticViewportNamespaces.widescreen,
      [ViewportNames.largeScreen]: semanticViewportNamespaces.computer,
      [ViewportNames.smallScreen]: `${semanticViewportNamespaces.mobile} ${semanticViewportNamespaces.tablet}`,
    };

    if (typeof onlyProps === 'string') {
      onlyProps = onlyProps.split(' ') as ViewportName[];
    }
    if (
      (onlyProps.includes(ViewportNames.largeScreen) &&
        onlyProps.some(viewport =>
          (
            [ViewportNames.desktop, ViewportNames.widescreen] as ViewportName[]
          ).includes(viewport as ViewportName)
        )) ||
      (onlyProps.includes(ViewportNames.smallScreen) &&
        onlyProps.some(viewport =>
          (
            [ViewportNames.mobile, ViewportNames.tablet] as ViewportName[]
          ).includes(viewport as ViewportName)
        ))
    ) {
      throw new Error(
        `You cannot use ${ViewportNames.largeScreen} in conjunction with ${ViewportNames.desktop} / ${ViewportNames.widescreen} and ${ViewportNames.smallScreen} in conjunction with ${ViewportNames.mobile} / ${ViewportNames.tablet} in the only prop`
      );
    }

    const translatedBreaks = onlyProps.reduce((acc, prop) => {
      acc.push(semanticBreakpointTranslations[prop]);
      return acc;
    }, []);

    return translatedBreaks.length > 0 ? translatedBreaks.join(' ') : null;
  },
};
