import React from 'react';
import { arrayOf, bool, node, object, oneOfType, string } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const DataTest = ({ children, className, inline, style, value }) => {
  const testId = DataTestUtil.format(value);
  const Wrapper = inline ? 'span' : 'div';

  return (
    <Wrapper className={className} style={style} data-test={testId}>
      {children}
    </Wrapper>
  );
};

DataTest.defaultProps = {
  className: null,
  inline: false,
  style: null,
};

DataTest.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
  inline: bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: object,
  value: string.isRequired,
};
