// eslint-disable-next-line max-classes-per-file
import { Enum } from 'enumify';
import { z } from 'zod';

import { ClientRequestConst, AfcRejectionLossCodes } from './clientRequests';

const CandidateStatuses = {
  Completed: 'Completed',
  Cooled: 'Cooled',
  Engaged: 'Engaged',
  FalseStart: 'FalseStart',
  Interested: 'Interested',
  InterestedRejected: 'InterestedRejected',
  Interviewing: 'Interviewing',
  Rejected: 'Rejected',
  Removed: 'Removed',
  RepBioShare: 'RepBioShare',
  Selected: 'Selected',
  ShortList: 'ShortList',
  Submitted: 'Submitted',
  Suggested: 'Suggested',
  TalentOptOut: 'TalentOptOut',
  Warmed: 'Warmed',
  WarmedUnsure: 'WarmedUnsure',
  WarmedYes: 'WarmedYes',
};

const CandidateStatusValues = Object.values(
  CandidateStatuses
) as NonEmptyArray<string>;

const CandidateStatusLabels = {
  Completed: 'Completed',
  Cooled: 'Cooled',
  Engaged: 'Engaged',
  FalseStart: 'False Start',
  Interested: 'Interested',
  InterestedRejected: 'Interested - Rejected',
  Interviewing: 'Interviewing',
  Rejected: 'Not Selected',
  Removed: 'Removed',
  RepBioShare: 'Rep Bio Shared',
  Selected: 'Selected',
  ShortList: 'Short Listed',
  Submitted: 'Submitted',
  Suggested: 'Suggested',
  TalentOptOut: 'Talent Opted Out',
  Warmed: 'Warmed',
  WarmedUnsure: 'Warmed - Unsure',
  WarmedYes: 'Talent Opted In',
};
const CandidateRejectionLossCodes = {
  ExperienceOverqualified: 'ExperienceOverqualified',
  ExperienceUnderqualified: 'ExperienceUnderqualified',
  ExperienceLackingIndustrySpecificExperience:
    'ExperienceLackingIndustrySpecificExperience',
  ExperiencePoorPracticeArea: 'ExperiencePoorPracticeArea',
  CostTooExpensive: 'CostTooExpensive',
  ConflictOfInterest: 'ConflictOfInterest',
  WrongTalentType: 'WrongTalentType',
  TimeZoneLocation: 'TimeZoneLocation',
  InsufficientAvailability: 'InsufficientAvailability',
  CannotWorkOnAdhocEngagements: 'CannotWorkOnAdhocEngagements',
  IncorrectLanguage: 'IncorrectLanguage',
  QualifiedCandidateThatWasNotSelected: 'QualifiedCandidateThatWasNotSelected',
  ExpressedInterestTooLateIntoProcess: 'ExpressedInterestTooLateIntoProcess',
  ProfileNeedsMoreDetailForSubmission: 'ProfileNeedsMoreDetailForSubmission',
  ClientDriven: 'ClientDriven',
  Other: 'Other',
};

const CandidateRejectionLossCodesValues = Object.values(
  CandidateRejectionLossCodes
) as NonEmptyArray<string>;

const CandidateRejectionLossCodesLabels = {
  [CandidateRejectionLossCodes.ExperienceOverqualified]:
    'Experience - Overqualified',
  [CandidateRejectionLossCodes.ExperienceUnderqualified]:
    'Experience - Underqualified',
  [CandidateRejectionLossCodes.ExperienceLackingIndustrySpecificExperience]:
    'Experience - Lacking industry-specific experience',
  [CandidateRejectionLossCodes.ExperiencePoorPracticeArea]:
    'Experience - Poor practice area',
  [CandidateRejectionLossCodes.CostTooExpensive]: 'Cost - Too expensive',
  [CandidateRejectionLossCodes.ConflictOfInterest]: 'Conflict of interest',
  [CandidateRejectionLossCodes.WrongTalentType]: 'Wrong talent type',
  [CandidateRejectionLossCodes.TimeZoneLocation]: 'Time zone/location',
  [CandidateRejectionLossCodes.InsufficientAvailability]:
    'Insufficient availability',
  [CandidateRejectionLossCodes.CannotWorkOnAdhocEngagements]:
    'Cannot work on ad-hoc engagements',
  [CandidateRejectionLossCodes.IncorrectLanguage]: 'Incorrect language',
  [CandidateRejectionLossCodes.QualifiedCandidateThatWasNotSelected]:
    'Qualified candidate that was not selected',
  [CandidateRejectionLossCodes.ExpressedInterestTooLateIntoProcess]:
    'Expressed interest too late into process',
  [CandidateRejectionLossCodes.ProfileNeedsMoreDetailForSubmission]:
    'Profile needs more detail for submission',
  [CandidateRejectionLossCodes.ClientDriven]: 'Client-driven',
  [CandidateRejectionLossCodes.Other]: 'Other',
};

const CandidateOpportunityLossReasons = {
  ...CandidateRejectionLossCodes,
  Unremediated: 'Unremediated',
};

const CandidateOpportunityLossReasonMessages = {
  [CandidateOpportunityLossReasons.ExperienceOverqualified]: `Your profile was not submitted for this opportunity as it didn't match the required level of experience as determined by the client.`,
  [CandidateOpportunityLossReasons.ExperienceUnderqualified]: `Your profile was not submitted for this opportunity as the client was looking for more experience.`,
  [CandidateOpportunityLossReasons.ExperienceLackingIndustrySpecificExperience]: `Your profile was not submitted for this opportunity as your background didn't have the relevant industry experience`,
  [CandidateOpportunityLossReasons.ExperiencePoorPracticeArea]: `Your profile was not submitted for this opportunity because your background didn't have the relevant practice area.`,
  [CandidateOpportunityLossReasons.CostTooExpensive]: `Your profile was not submitted for this opportunity due to client budget constraints.`,
  [CandidateOpportunityLossReasons.ConflictOfInterest]: `Your profile was not submitted for this opportunity due to a conflict of interest that might prevent you from performing the work.`,
  [CandidateOpportunityLossReasons.WrongTalentType]: `Your profile was not submitted for this opportunity because your legal role is different than what the client requires.`,
  [CandidateOpportunityLossReasons.TimeZoneLocation]: `Your profile was not submitted for this opportunity because your location or time zone doesn't match up with what the client is looking for.`,
  [CandidateOpportunityLossReasons.InsufficientAvailability]: `Your profile was not submitted for this opportunity because your availability doesn't match up with the client's requirements.`,
  [CandidateOpportunityLossReasons.CannotWorkOnAdhocEngagements]: `Your profile was not submitted for this opportunity because the hours required for this engagement do not meet the minimums required for your state.`,
  [CandidateOpportunityLossReasons.IncorrectLanguage]: `Your profile was not submitted for this opportunity because you don't have the required language skill set.`,
  [CandidateOpportunityLossReasons.QualifiedCandidateThatWasNotSelected]: `You weren't selected for this opportunity since the client has selected another legal professional for this opportunity.`,
  [CandidateOpportunityLossReasons.ExpressedInterestTooLateIntoProcess]: `You weren't selected for this opportunity because it closed before your profile could be shared with the client.`,
  [CandidateOpportunityLossReasons.ProfileNeedsMoreDetailForSubmission]: `You weren't selected for this opportunity because your Axiom for Talent profile is incomplete.`,
  [CandidateOpportunityLossReasons.Unremediated]: `This opportunity is no longer available due to a change in the opportunity status. We encourage you to continue reviewing the feed as new opportunities are added daily.`,
  [CandidateOpportunityLossReasons.ClientDriven]: `The opportunity is no longer available due to a change in client needs. This usually happens when a client decides to go in a different direction with the planned work.`,
  [CandidateOpportunityLossReasons.Other]: ``,
};

// TODO this is the beginning of changing to a new format for constants
class CandidateStatusEnum extends Enum {}
CandidateStatusEnum.initEnum({
  [CandidateStatuses.Completed]: { v: CandidateStatusLabels.Completed },
  [CandidateStatuses.Cooled]: { v: CandidateStatusLabels.Cooled },
  [CandidateStatuses.Engaged]: { v: CandidateStatusLabels.Engaged },
  [CandidateStatuses.Interested]: { v: CandidateStatusLabels.Interested },
  [CandidateStatuses.InterestedRejected]: {
    v: CandidateStatusLabels.InterestedRejected,
  },
  [CandidateStatuses.Interviewing]: { v: CandidateStatusLabels.Interviewing },
  [CandidateStatuses.Rejected]: { v: CandidateStatusLabels.Rejected },
  [CandidateStatuses.Removed]: { v: CandidateStatusLabels.Removed },
  [CandidateStatuses.RepBioShare]: { v: CandidateStatusLabels.RepBioShare },
  [CandidateStatuses.Selected]: { v: CandidateStatusLabels.Selected },
  [CandidateStatuses.ShortList]: { v: CandidateStatusLabels.ShortList },
  [CandidateStatuses.Submitted]: { v: CandidateStatusLabels.Submitted },
  [CandidateStatuses.Suggested]: { v: CandidateStatusLabels.Suggested },
  [CandidateStatuses.TalentOptOut]: { v: CandidateStatusLabels.TalentOptOut },
  [CandidateStatuses.Warmed]: { v: CandidateStatusLabels.Warmed },
  [CandidateStatuses.WarmedUnsure]: { v: CandidateStatusLabels.WarmedUnsure },
  [CandidateStatuses.WarmedYes]: { v: CandidateStatusLabels.WarmedYes },
});
CandidateStatusEnum.v = v =>
  CandidateStatusEnum.enumValues.find(x => x.v === v);

// Note: These should not conflict with CandidateStatuses otherwise we will have issues
const OppCandMlRecStatuses = {
  AiAccepted: 'AI Accepted', // decoration on opp_cand_ml_recs only
  AiRemoved: 'AI Removed', // decoration on opp_cand_ml_recs only
  AiSuggested: 'AI Suggested', // valid previousCandidateStatus in candidate_opportunities
};

const OppCandMlRecStatusLabels = {
  [OppCandMlRecStatuses.AiSuggested]: 'AI Match',
  [OppCandMlRecStatuses.AiRemoved]: 'AI Match - Removed',
};

const ClientRequestSudoStatus = {
  [ClientRequestConst.RequestTypes.InterviewRequested]:
    'AFC Interview Requested',
  [ClientRequestConst.RequestTypes.ClientPassed]: 'AFC Pass',
  [ClientRequestConst.RequestTypes.HireRequested]: 'AFC Selected',
};

const AfcRequestTypeToCandidateStatus = {
  [ClientRequestConst.RequestTypes.ClientPassed]: CandidateStatuses.Rejected,
  [ClientRequestConst.RequestTypes.HireRequested]: CandidateStatuses.Selected,
  [ClientRequestConst.RequestTypes.InterviewRequested]:
    CandidateStatuses.Interviewing,
};

const AfcLossCodesToCandidateOpportunityLossCodes: { [x: string]: string } = {
  [AfcRejectionLossCodes.ExperienceOverqualified]:
    CandidateRejectionLossCodes.ExperienceOverqualified,
  [AfcRejectionLossCodes.ExperienceUnderqualified]:
    CandidateRejectionLossCodes.ExperienceUnderqualified,
  [AfcRejectionLossCodes.ExperienceLackingIndustrySpecificExperience]:
    CandidateRejectionLossCodes.ExperienceLackingIndustrySpecificExperience,
  [AfcRejectionLossCodes.CostTooExpensive]:
    CandidateRejectionLossCodes.CostTooExpensive,
  [AfcRejectionLossCodes.ConflictOfInterest]:
    CandidateRejectionLossCodes.ConflictOfInterest,
  [AfcRejectionLossCodes.TimeZoneLocation]:
    CandidateRejectionLossCodes.TimeZoneLocation,
  [AfcRejectionLossCodes.IncorrectLanguage]:
    CandidateRejectionLossCodes.IncorrectLanguage,
  [AfcRejectionLossCodes.QualifiedCandidateThatWasNotSelected]:
    CandidateRejectionLossCodes.QualifiedCandidateThatWasNotSelected,
  [AfcRejectionLossCodes.ProfileNeedsMoreDetail]:
    CandidateRejectionLossCodes.ProfileNeedsMoreDetailForSubmission,
  [AfcRejectionLossCodes.Other]: null,
};

class CandidateRejectionLossCodesEnum extends Enum {}
CandidateRejectionLossCodesEnum.initEnum({
  [CandidateRejectionLossCodes.ExperienceOverqualified]: {
    v: CandidateRejectionLossCodesLabels.ExperienceOverqualified,
  },
  [CandidateRejectionLossCodes.ExperienceUnderqualified]: {
    v: CandidateRejectionLossCodesLabels.ExperienceUnderqualified,
  },
  [CandidateRejectionLossCodes.ExperienceLackingIndustrySpecificExperience]: {
    v: CandidateRejectionLossCodesLabels.ExperienceLackingIndustrySpecificExperience,
  },
  [CandidateRejectionLossCodes.ExperiencePoorPracticeArea]: {
    v: CandidateRejectionLossCodesLabels.ExperiencePoorPracticeArea,
  },
  [CandidateRejectionLossCodes.CostTooExpensive]: {
    v: CandidateRejectionLossCodesLabels.CostTooExpensive,
  },
  [CandidateRejectionLossCodes.ConflictOfInterest]: {
    v: CandidateRejectionLossCodesLabels.ConflictOfInterest,
  },
  [CandidateRejectionLossCodes.WrongTalentType]: {
    v: CandidateRejectionLossCodesLabels.WrongTalentType,
  },
  [CandidateRejectionLossCodes.TimeZoneLocation]: {
    v: CandidateRejectionLossCodesLabels.TimeZoneLocation,
  },
  [CandidateRejectionLossCodes.InsufficientAvailability]: {
    v: CandidateRejectionLossCodesLabels.InsufficientAvailability,
  },
  [CandidateRejectionLossCodes.CannotWorkOnAdhocEngagements]: {
    v: CandidateRejectionLossCodesLabels.CannotWorkOnAdhocEngagements,
  },
  [CandidateRejectionLossCodes.IncorrectLanguage]: {
    v: CandidateRejectionLossCodesLabels.IncorrectLanguage,
  },
  [CandidateRejectionLossCodes.QualifiedCandidateThatWasNotSelected]: {
    v: CandidateRejectionLossCodesLabels.QualifiedCandidateThatWasNotSelected,
  },
  [CandidateRejectionLossCodes.ExpressedInterestTooLateIntoProcess]: {
    v: CandidateRejectionLossCodesLabels.ExpressedInterestTooLateIntoProcess,
  },
  [CandidateRejectionLossCodes.ProfileNeedsMoreDetailForSubmission]: {
    v: CandidateRejectionLossCodesLabels.ProfileNeedsMoreDetailForSubmission,
  },
  [CandidateRejectionLossCodes.ClientDriven]: {
    v: CandidateRejectionLossCodesLabels.ClientDriven,
  },
  [CandidateRejectionLossCodes.Other]: {
    v: CandidateRejectionLossCodesLabels.Other,
  },
});
CandidateRejectionLossCodesEnum.v = v =>
  CandidateRejectionLossCodesEnum.enumValues.find(x => x.v === v);

const RollOffReasonsCodes = {
  TalentLeftAxiom: 'TalentLeftAxiom',
  TalentLeftEngagement: 'TalentLeftEngagement',
  TalentPerformanceIssue: 'TalentPerformanceIssue',
  BudgetLost: 'BudgetLost',
  CoEmploymentCap: 'CoEmploymentCap',
  Conversion: 'Conversion',
  CleanUp: 'CleanUp',
  TransitionToAnother: 'TransitionToAnother',
  ProjectCompleted: 'ProjectCompleted',
  WrongFit: 'WrongFit',
  FalseStart: 'FalseStart',
  Covid19: 'Covid19',
  GapFill: 'GapFill',
  MaternityLeave: 'MaternityLeave',
  OverForecastingError: 'OverForecastingError',
  ProjectDeScoped: 'ProjectDeScoped',
  ProjectDefunded: 'ProjectDefunded',
};
const RollOffReasons = {
  [RollOffReasonsCodes.TalentLeftAxiom]: 'Talent Left Axiom',
  [RollOffReasonsCodes.TalentLeftEngagement]: 'Talent Left Engagement',
  [RollOffReasonsCodes.TalentPerformanceIssue]: 'Talent Performance Issue',
  [RollOffReasonsCodes.BudgetLost]: 'Budget Lost',
  [RollOffReasonsCodes.CoEmploymentCap]: 'Co-Employment Cap',
  [RollOffReasonsCodes.Conversion]: 'Conversion',
  [RollOffReasonsCodes.CleanUp]: 'Data/BoB Clean-up',
  [RollOffReasonsCodes.TransitionToAnother]:
    'Work transitioned to new Headcount',
  [RollOffReasonsCodes.ProjectCompleted]: 'Project/ Workstream Completed',
  [RollOffReasonsCodes.WrongFit]: 'Talent Wrong Skillset/Fit',
  [RollOffReasonsCodes.FalseStart]: 'False Start',
  [RollOffReasonsCodes.Covid19]: 'COVID-19',
  [RollOffReasonsCodes.GapFill]: 'Gap Fill',
  [RollOffReasonsCodes.MaternityLeave]: 'Maternity Leave',
  [RollOffReasonsCodes.OverForecastingError]: 'Over Forecasting Error',
  [RollOffReasonsCodes.ProjectDeScoped]: 'Project DeScoped',
  [RollOffReasonsCodes.ProjectDefunded]: 'Project Defunded',
};

export const CandidateOpportunitiesConst = {
  AfcRequestTypeToCandidateStatus,
  AfcLossCodesToCandidateOpportunityLossCodes,
  AllStatusLabels: {
    ...CandidateStatusLabels,
    ...OppCandMlRecStatusLabels,
    ...ClientRequestSudoStatus,
  },
  CandidateRejectionLossCode: CandidateRejectionLossCodesEnum,
  CandidateRejectionLossCodes,
  CandidateRejectionLossCodesLabels,
  ZodCandidateRejectionLossCodes: z.enum(CandidateRejectionLossCodesValues),
  CandidateOpportunityLossReasons,
  CandidateOpportunityLossReasonMessages,
  CandidateStatus: CandidateStatusEnum,
  // statuses persisted and used internally
  CandidateStatuses,
  ZodCandidateStatuses: z.enum(CandidateStatusValues),
  // status labels to show the user
  CandidateStatusLabels,
  CompensationTypes: {
    EngagementSpecific: 'Engagement Specific',
    Premium: 'Premium',
    Standard: 'Standard',
  },
  DisplayBillingRate: {
    hourly: 'hourly',
    daily: 'daily',
    weekly: 'weekly',
    none: 'none',
  },
  OppCandMlRecFilters: {
    notMlAccepted: 'notMlAccepted',
  },
  OppCandMlRecStatuses,
  OppCandMlRecStatusLabels,
  ReasonForNo: {
    DontHaveTime: 'Dont Have Time',
    WrongAreaOfExpertise: 'Wrong Area of Expertise',
    InconvenientLocation: 'Inconvenient Location',
    Other: 'Other',
  },
  OptInQuestions: {
    hasNoConflictOfInterest:
      'I certify that this opportunity does not present any actual or potential conflicts of interest that would prevent me from acting for the client on this matter.',
    candidateExploringOutsideRoles:
      'Are you currently exploring opportunities outside of Axiom?',
    candidatePlannedTimeOffNotes:
      'Do you have any extended time off planned over the next few months? If so, when exactly will you be unavailable?',
    candidateAvailabilityNotes:
      'How soon would you be available to start this engagement?',
    candidateExperienceNotes:
      "Is there anything specific in your experience that you'd like us to highlight with the client?",
  },
  RollOffReasons,
  RollOffReasonsCodes,
  UnremediatedStatuses: [
    CandidateStatuses.Interested,
    CandidateStatuses.ShortList,
    CandidateStatuses.Suggested,
  ],
  /** field names */
  ANNUAL_SALARY: 'annualSalary',
  CANDIDATE_AVAILABILITY_NOTES: 'candidateAvailabilityNotes',
  CANDIDATE_EXPERIENCE_NOTES: 'candidateExperienceNotes',
  CANDIDATE_ID: 'candidateId',
  CANDIDATE_REASON_FOR_NO: 'candidateReasonForNo',
  CANDIDATE_STATUS: 'candidateStatus',
  CANDIDATE_STATUS_MESSAGE: 'candidateStatusMessage',
  CREATED_AT: 'createdAt',
  DISPLAY_BILLING_RATE: 'displayBillingRate',
  HOURLY_SALARY: 'hourlySalary',
  ID: 'id',
  OPPORTUNITY_ID: 'opportunityId',
  PREVIOUS_CANDIDATE_STATUS: 'previousCandidateStatus',
  PRPOSED_HOURLY_RATE: 'proposedHourlyRate',
  SUBMISSION_DESCRIPTION: 'submissionDescription',
  UPDDATED_AT: 'updatedAt',
};

export const CandidateOpportunitiesConstWarmedStatuses = [
  CandidateStatuses.Warmed,
  CandidateStatuses.Selected,
  CandidateStatuses.WarmedYes,
  CandidateStatuses.Submitted,
  CandidateStatuses.Interviewing,
];

export type CandidateStatusType = keyof typeof CandidateStatuses;

export const InConsiderationCandidateStatuses = [
  CandidateStatuses.Interested,
  CandidateStatuses.Interviewing,
  CandidateStatuses.RepBioShare,
  CandidateStatuses.Selected,
  CandidateStatuses.ShortList,
  CandidateStatuses.Submitted,
  CandidateStatuses.Suggested,
  CandidateStatuses.Warmed,
  CandidateStatuses.WarmedUnsure,
  CandidateStatuses.WarmedYes,
];

export const CurrentEngagementCandidateStatuses = [CandidateStatuses.Engaged];

export const PreviousEngagementCandidateStatuses = [
  CandidateStatuses.Completed,
];

export const NoLongerInConsiderationCandidateStatuses = [
  CandidateStatuses.Cooled,
  CandidateStatuses.FalseStart,
  CandidateStatuses.InterestedRejected,
  CandidateStatuses.Rejected,
  CandidateStatuses.Removed,
  CandidateStatuses.TalentOptOut,
];

export const CandidateStatusLabelsMap = new Map(
  Object.entries(CandidateStatusLabels)
);
