import styled, { css } from 'styled-components';

import { MediaQueryUtil } from '../../../utils/media-query-util';

export const LayoutBleed = styled.div`
  width: 100%;
  margin: auto;
  padding-left: ${({ theme }) => theme.spacing.desktopPageBleed};
  padding-right: ${({ theme }) => theme.spacing.desktopPageBleed};

  ${MediaQueryUtil.largeScreenOnly(css`
    max-width: ${({ theme }) => theme.spacing.desktopMaxWidth};
  `)};

  ${MediaQueryUtil.tabletOnly(css`
    padding-left: ${({ theme }) => theme.spacing.tabletPageBleed};
    padding-right: ${({ theme }) => theme.spacing.tabletPageBleed};
  `)};

  ${MediaQueryUtil.mobileOnly(css`
    padding-left: ${({ theme }) => theme.spacing.mobilePageBleed};
    padding-right: ${({ theme }) => theme.spacing.mobilePageBleed};
  `)};
`;
