import orderBy from 'lodash/orderBy';
import { Experience } from '@axiom/validation';

type Props = Experience[];

export class CandidateExperiencesModel {
  props: Props = [];

  sortNames: string[];

  sortOrder: ('desc' | 'asc')[];

  constructor(props: Props) {
    this.props = props;
    this.sortNames = ['endDate', 'startDate', 'client'];
    this.sortOrder = ['desc', 'desc', 'asc'];
  }

  private sort(sortByHighlighted = false) {
    if (sortByHighlighted) {
      this.sortNames.unshift('isHighlighted');
      this.sortOrder.unshift('desc');
    }

    return orderBy(this.props, this.sortNames, this.sortOrder);
  }

  sortExperiences() {
    return this.sort();
  }

  sortExperiencesByHighlights() {
    return this.sort(true);
  }
}
