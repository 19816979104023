import { loadLightPalette } from '@axiom/ui';

export default {
  main: {
    primaryText: '#000000',
    colorPrimary: '#2B2B2B',
    colorSecondary: '#707070',
    appBackground: '#F6F6F6',
    disabledColor: '#adadad',
    contentBackground: '#FFFFFF',
    positiveColor: '#C7D66D',
    negativeColor: '#800000',
    ready: '#92B5A5',
    unqualified: '#93A3B1',
    qualified: '#4D7C8A',
    interviewing: '#B1B89C',
    contracting: '#C7D66D',
    axiomOrange: '#ec653e',
    ...loadLightPalette(),
  },
  dark: {
    primaryText: '#ffffff',
  },
};

export const getThemeProp =
  property =>
  ({ theme }) =>
    theme[property];
