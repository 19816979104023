/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { shape, string } from 'prop-types';

import { FFError, isErroring } from '../FFError/FFError';
import { formatDataTestId } from '../../utils/dataTest';

import { FFInputErrorWrapper, FFInputStyled } from './FFInputStyles';

export const FFInput = ({ input, meta, type, ...rest }) => {
  const value = input.value || '';

  if (isErroring(meta)) {
    rest.invalid = true;
  }

  return (
    <>
      <FFInputStyled
        type={type}
        {...input}
        {...rest}
        value={value}
        onChange={event => input.onChange(event.target.value)}
      />
      <FFInputErrorWrapper data-test={formatDataTestId('FORM_ERROR')}>
        <FFError finalFormElementMeta={meta} />
      </FFInputErrorWrapper>
    </>
  );
};

FFInput.propTypes = {
  input: shape({}).isRequired,
  meta: shape({}).isRequired,
  type: string,
};

FFInput.defaultProps = {
  type: 'text',
};
