import { shape, string, number } from 'prop-types';

export const CertificationShape = shape({
  id: string,
  candidateId: string,
  institution: string,
  award: string,
  createdAt: string,
  updatedAt: string,
  year: number,
});
