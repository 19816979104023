import React from 'react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export const CardSection = ({
  background = 'light',
  className,
  children,
  divider = false,
  name,
}: {
  background?:
    | 'light'
    | 'blue'
    | 'orange'
    | 'light orange'
    | 'light blue'
    | CssBackgroundImage;
  children?: React.ReactNode;
  className?: string;
  divider?: boolean;
  name?: string;
}) => {
  return (
    <div
      className={AttrsHelper.formatClassname(
        'card-section-container',
        typeof background === 'string' &&
          `background-${background.replace(' ', '-')}`,
        divider && 'section-divider',
        className
      )}
      data-test={name}
      style={
        typeof background === 'object' && background?.image
          ? {
              backgroundImage: `url("${background.image}")`,
              backgroundAttachment: background.attachment,
              backgroundClip: background.clip,
              backgroundColor: background.color,
              backgroundPosition: background.position || 'center right',
              backgroundRepeat: background.repeat || 'no-repeat',
            }
          : {}
      }
    >
      {children}
    </div>
  );
};
