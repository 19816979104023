import { Subject, Subscription } from 'rxjs';

import { BlockerUtil } from '../components/app/Blocker/Blocker';

import { ApiError } from './api-error';
import { apiRequest } from './api-request';
import { RequestProps } from './api-types';

export type FetchWatcher<ReturnType> = {
  refetch: (fireInvalidateEvent?: boolean) => Promise<ReturnType>;
  getData: () => ReturnType | null;
  getError: () => ApiError;
  hasError: () => boolean;
  hasResolved: () => boolean;
  listen: (evt: (data: ReturnType) => void) => Subscription;
  listenForInvalidate: (evt: () => void) => Subscription;
  toPromise: () => Promise<ReturnType>;
};

export class EndpointCache<ReturnType> {
  private resolved = false;

  private response: unknown = null;

  private errorResponse?: ApiError = null;

  private prevResponse?: unknown = null;

  private nextRequestProps?: RequestProps = null;

  private requestProps: RequestProps;

  private prevRequestProps?: RequestProps;

  private subject = new Subject<ReturnType>();

  private invalidateListener = new Subject<() => void>();

  private watchRequest(nextRequestProps: RequestProps) {
    if (
      !this.nextRequestProps ||
      this.resolved ||
      this.getComparableResult(nextRequestProps) !==
        this.getComparableResult(this.nextRequestProps)
    ) {
      this.resolved = false;
      this.nextRequestProps = nextRequestProps;
      return apiRequest(nextRequestProps)
        .then((data: ReturnType) => {
          if (
            this.getComparableResult(nextRequestProps) ===
            this.getComparableResult(this.nextRequestProps)
          ) {
            this.nextRequestProps = null;
            this.resolved = true;
            this.response = data;
            this.requestProps = nextRequestProps;
            this.subject.next(data);
            /**
             * React's defer value seems to request old and new values
             * in a weird order, so this clears out data from old request after
             * everything settles better
             */

            setTimeout(() => {
              this.prevRequestProps = null;
              this.prevResponse = null;
              /**
               * Note to Brutus in the future:
               * We were still getting prev requests after new response finished and
               * prevRequest cleared.
               *
               * So it doesn't look like we can just put it outside the
               * lifecycle with a 0 timeout. Doing 1 sec here because it seems reasonable
               * that if someone clicks back and forth quickly,
               * we should just give them cache anyway, so that they don't spam our servers.
               *
               * If this doesn't work, we'll need to look at a full cache clearing
               * mechanism based on a TTD - Time To Donut (Anew)
               */
            }, 1000);
            return data;
          }
          return this.response;
        })
        .catch(e => {
          if (
            this.getComparableResult(nextRequestProps) ===
            this.getComparableResult(this.nextRequestProps)
          ) {
            this.errorResponse = e;
            this.subject.next(null);
          }
        });
    }
    return Promise.resolve();
  }

  async invalidate(fireInvalidateEvent = true) {
    if (this.resolved) {
      this.prevRequestProps = this.requestProps;
      this.prevResponse = this.response;
      this.response = null;
      this.requestProps = null;
    }

    const blockerEvent = BlockerUtil.block();
    try {
      const data = await this.watchRequest(this.prevRequestProps);
      BlockerUtil.unblock(blockerEvent);
      if (fireInvalidateEvent) {
        this.invalidateListener.next(null);
      }
      return data;
    } catch (e) {
      BlockerUtil.unblock(blockerEvent);
      if (fireInvalidateEvent) {
        this.invalidateListener.next(null);
      }
      throw e;
    }
  }

  clear() {
    this.prevRequestProps = null;
    this.prevResponse = null;
    this.response = null;
    this.requestProps = null;
    this.resolved = false;
    this.errorResponse = null;
    this.nextRequestProps = null;
  }

  private getComparableResult(request: RequestProps) {
    const query: Record<string, unknown> =
      request?.method === 'POST'
        ? (request?.body as Record<string, unknown>)
        : request?.query;
    if (query === null || query === undefined) {
      return query;
    }

    try {
      return JSON.stringify(
        Object.keys(query)
          .sort()
          .reduce(function (acc: Record<string, unknown>, key: string) {
            acc[key] = query[key];
            return acc;
          }, {})
      );
    } catch {
      return query;
    }
  }

  hasResolved() {
    return !!(this.resolved || this.errorResponse);
  }

  getError() {
    return this.errorResponse;
  }

  getData(requestParams: RequestProps) {
    let foundResponse: unknown = null;
    if (
      this.requestProps &&
      this.getComparableResult(requestParams) ===
        this.getComparableResult(this.requestProps)
    ) {
      foundResponse = this.response;
    } else if (
      this.prevRequestProps &&
      this.getComparableResult(requestParams) ===
        this.getComparableResult(this.prevRequestProps)
    ) {
      foundResponse = this.prevResponse;
    }

    return foundResponse;
  }

  watch(requestParams: RequestProps) {
    if (!this.getData(requestParams)) {
      if (this.requestProps) {
        this.prevRequestProps = this.requestProps;
        this.prevResponse = this.response;
      }

      this.watchRequest(requestParams);
    }

    const FetchWatcherApi = {
      hasResolved: () => {
        return this.hasResolved();
      },
      getData: () => {
        return this.getData(requestParams);
      },
      hasError: () => {
        return !!this.getError();
      },
      getError: () => {
        return this.getError();
      },
      toPromise: () => {
        return new Promise(resolve => {
          FetchWatcherApi.listen((data: ReturnType) => resolve(data));
        });
      },
      listen: (evt: (data: ReturnType) => void) => {
        return this.subject.subscribe({
          next: (data: ReturnType) => evt(data),
        });
      },
      listenForInvalidate: (evt: () => void) => {
        return this.invalidateListener.subscribe(evt);
      },
      refetch: async (fireInvalidateEvent?: boolean) => {
        return this.invalidate(fireInvalidateEvent);
      },
    } as const as FetchWatcher<ReturnType>;

    return FetchWatcherApi;
  }
}
