import React from 'react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export const Well = ({
  background = 'gray',
  className,
  children,
  name,
}: {
  background?: 'light' | 'gray' | 'sky blue' | 'light blue' | 'blue';
  children: React.ReactNode;
  className?: string;
  name?: string;
}) => (
  <div
    className={AttrsHelper.formatClassname(
      'well',
      `background-${background.replace(' ', '-')}`,
      className
    )}
    data-test={name}
  >
    {children}
  </div>
);
