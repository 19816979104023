import React from 'react';
import { oneOf, node, string, bool } from 'prop-types';
import styled from 'styled-components';

import { DataTestUtil } from '../../../utils/data-test-util';

const H6 = ({ as, children, dataTest }) =>
  React.createElement(as, { dataTest }, children);

H6.propTypes = {
  as: string.isRequired,
  children: node.isRequired,
  dataTest: string.isRequired,
};

export const Header6StaticSketchStyles = ({ theme, accent, subtle }) => ({
  fontSize: '0.875rem', // * 16px = 14px
  fontWeight: 'bold',
  lineHeight: '1.5rem', // * 16px = 24px
  textTransform: 'uppercase',
  fontFamily: accent ? 'Caslon' : 'Walsheim',
  color: subtle ? theme.text.subtle : theme.text.default,
});

const StyledH6 = styled(H6)`
  ${() => Header6StaticSketchStyles}
  color: ${({ theme, subtle }) =>
    subtle ? theme.text.subtle : theme.text.default};
  margin: 0;
`;

export const Header6 = ({ as, accent, children, className, name, subtle }) => (
  <StyledH6
    as={as}
    accent={accent}
    subtle={subtle}
    className={className}
    data-test={DataTestUtil.format(name)}
  >
    {children}
  </StyledH6>
);

Header6.propTypes = {
  as: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div', 'span']),
  children: node,
  className: string,
  name: string,
  accent: bool,
  subtle: bool,
};

Header6.defaultProps = {
  as: 'h6',
  children: null,
  className: '',
  name: null,
  accent: false,
  subtle: false,
};
