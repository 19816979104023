import throttle from 'lodash/throttle';
import { BehaviorSubject } from 'rxjs';
import { useEffect, useState } from 'react';

export const windowResizeEvent = new BehaviorSubject({
  width: window.innerWidth,
  height: window.innerHeight,
});

const throttledWindowResize = throttle(event => {
  windowResizeEvent.next({
    width: event.target.innerWidth,
    height: event.target.innerHeight,
  });
}, 250);

window.addEventListener('resize', evt => {
  throttledWindowResize(evt);
});

export const useWindowResize = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const subscription = windowResizeEvent.subscribe(dimensions => {
      if (
        dimensions.width !== windowDimensions.width ||
        dimensions.height !== windowDimensions.height
      ) {
        setWindowDimensions(dimensions);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return windowDimensions;
};
