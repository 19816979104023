export const PositionsConst = {
  // end date statuses persisted and used internally
  EndDateStatuses: {
    Confirmed: 'Confirmed',
    NotConfirmed: 'Not Confirmed',
  },

  Worksites: {
    Onsite: 'Onsite',
    Remote: 'Remote',
    OnsiteRemote: 'Onsite/Remote',
  },

  ReservedType: {
    Reserved: 'Reserved',
    NotReserved: 'Not Reserved',
  },

  CalculatedTalentTypes: {
    Lawyer: 'Lawyer',
    NonLawyer: 'Paralegal or other',
  },

  TalentTypes: {
    Lawyer: 'Lawyer',
    LawyerNonLegalWork: 'Lawyer - Non Legal Work',
    LegalSupport: 'Legal Support',
  },

  Roles: {
    teamLead: 'Team Lead',
    teamSecondment: 'Team Secondment',
    iSecondment: 'Individual Secondment',
    counsel: 'Counsel',
    other: 'Other',
  },
  Expansions: {
    opportunityInfo: 'opportunityInfo',
    candidatesInfo: 'candidatesInfo',
    tagsInfo: 'tagsInfo',
  },
} as const;

export type CalculatedTalentType =
  (typeof PositionsConst.CalculatedTalentTypes)[keyof typeof PositionsConst.CalculatedTalentTypes];

export type WorksitesType =
  (typeof PositionsConst.Worksites)[keyof typeof PositionsConst.Worksites];
export type RolesType =
  (typeof PositionsConst.Roles)[keyof typeof PositionsConst.Roles];
export type TalentType =
  (typeof PositionsConst.TalentTypes)[keyof typeof PositionsConst.TalentTypes];
