import { Certification } from '@axiom/validation';
import { Certification as CertificationConst } from '@axiom/const';

const {
  QUALIFICATION_AWARDS: { QUALIFICATION, YEAR_OF_CALL, STATE_EXAM },
} = CertificationConst;

export class CertificationModel {
  props: Certification = {};

  constructor(props: Certification = {}) {
    this.props = props;
  }

  isQualification() {
    return (
      this.props.award === QUALIFICATION ||
      this.props.award === YEAR_OF_CALL ||
      this.props.award === STATE_EXAM
    );
  }

  isAccomplishment() {
    return !!this.props.award && !this.isQualification();
  }
}
