export const IconNames = {
  addUser: 'add-user',
  alertFilled: 'alert-filled',
  arrowDown: 'arrow-down',
  arrowLeft: 'arrow-left',
  arrowRight: 'arrow-right',
  arrowUp: 'arrow-up',
  avatar: 'avatar',
  bell: 'bell',
  bold: 'bold',
  bulletPoint: 'bullet-point',
  calendar: 'calendar',
  centerAlign: 'center-align',
  checkmark: 'checkmark',
  circleCheckFilled: 'circle-check-filled',
  circleInfo: 'circle-info',
  circleInfoFilled: 'circle-info-filled',
  circleWaitingFilled: 'circle-waiting-filled',
  clock: 'clock',
  clockFilled: 'clock-filled',
  collapseAll: 'collapse-all',
  copy: 'copy',
  dollar: 'dollar',
  dollarFilled: 'dollar-filled',
  download: 'download',
  envelope: 'envelope',
  expandAll: 'expand-all',
  eye: 'eye',
  eyeHidden: 'eye-hidden',
  filter: 'filter',
  filterFilled: 'filter-filled',
  handle: 'handle',
  heart: 'heart',
  heartFilled: 'heart-filled',
  italic: 'italic',
  leftAlign: 'left-align',
  menu: 'menu',
  moveDown: 'move-down',
  moveUp: 'move-up',
  pencil: 'pencil',
  placeholder: 'placeholder',
  popout: 'popout',
  plus: 'plus',
  open: 'open',
  reset: 'reset',
  rightAlign: 'right-align',
  settings: 'settings',
  search: 'search',
  share: 'share',
  sort: 'sort',
  sortAscending: 'sort-ascending',
  sortDescending: 'sort-descending',
  spacerVertical: 'spacer-vertical',
  spinner: 'spinner',
  star: 'star',
  starFilled: 'star-filled',
  strikeThrough: 'strike-through',
  tableau: 'tableau',
  trash: 'trash',
  underline: 'underline',
  verticalEllipsis: 'vertical-ellipsis',
  waypoint: 'waypoint',
  xMark: 'x-mark',
} as const;

export type IconName = (typeof IconNames)[keyof typeof IconNames];
