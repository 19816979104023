import React from 'react';
import styled from 'styled-components';

import { colors } from '../../../theme/axiom-theme';

export type FlashyProps = {
  children?: React.ReactNode;
  backgroundColor?: keyof typeof colors;
  bold?: boolean;
  color?: keyof typeof colors;
  italic?: boolean;
  name?: string;
  strikethrough?: boolean;
  underline?: boolean;
};

const FlashyStyled = styled.span`
  ${({
    backgroundColor,
    bold,
    color,
    italic,
    strikethrough,
    underline,
  }: FlashyProps & { dataTest?: string }) => ({
    background: colors[backgroundColor],
    color: colors[color],
    fontWeight: bold && 'bold',
    fontStyle: italic && 'italic',
    textDecoration:
      (strikethrough || underline) &&
      `${strikethrough ? 'line-through' : ''} ${
        underline ? 'underline' : ''
      }`.trim(),
  })}
`;
export const Flashy = ({
  backgroundColor,
  bold,
  children,
  color,
  italic,
  name,
  strikethrough,
  underline,
}: FlashyProps) => (
  <FlashyStyled
    backgroundColor={backgroundColor}
    bold={bold}
    color={color}
    data-test={name}
    italic={italic}
    strikethrough={strikethrough}
    underline={underline}
  >
    {children}
  </FlashyStyled>
);
