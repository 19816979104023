import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Checkbox as SCheckbox } from 'semantic-ui-react';
import { SchemaTypes } from '@axiom/types';

import { Tile } from '../../layout/Tiles/Tile';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

import { RawCheckboxPill } from './RawCheckboxPill';

const CheckboxWrapper = styled.div``;

export type RawCheckboxOptionType = string | number | boolean;
export type RawCheckboxValueType =
  | string
  | number
  | boolean
  | Array<string | number | boolean>;

const formatters = {
  [SchemaTypes.ZodArray]: {
    in: (value: RawCheckboxValueType, option: RawCheckboxOptionType) =>
      Array.isArray(value) && value.includes(option),
    out: (
      _isChecked: boolean,
      option: RawCheckboxOptionType,
      originalValue?: RawCheckboxValueType
    ) => {
      if (!Array.isArray(originalValue)) {
        originalValue = [];
      }

      return originalValue.includes(option)
        ? originalValue.filter(v => v !== option)
        : [...originalValue, option];
    },
  },
  [SchemaTypes.ZodString]: {
    in: (value: RawCheckboxValueType) => value !== null,
    out: (isChecked: boolean, option: RawCheckboxOptionType) =>
      isChecked ? option : null,
  },
  [SchemaTypes.ZodNumber]: {
    in: (value?: RawCheckboxValueType) => value !== null,
    out: (isChecked: boolean, option: RawCheckboxOptionType) =>
      isChecked ? option : null,
  },
  [SchemaTypes.ZodBoolean]: {
    in: (value?: RawCheckboxValueType) => value === true,
    out: (isChecked: boolean) => !!isChecked,
  },
} as unknown as {
  [key: string]: {
    in: (
      value?: RawCheckboxValueType,
      option?: RawCheckboxOptionType
    ) => boolean;
    out: (
      isChecked: boolean,
      option: RawCheckboxOptionType,
      value?: RawCheckboxValueType
    ) => RawCheckboxValueType;
  };
};

export type RawCheckboxProps = {
  dataType: string;
  disabled?: boolean;
  displayValue?: string | React.ReactNode;
  id?: string;
  invalid?: boolean;
  mode?: 'checkbox' | 'tile' | 'pill';
  name: string;
  onChange?: (value: RawCheckboxValueType, e: SyntheticEvent) => void;
  option: RawCheckboxOptionType;
  value?: RawCheckboxValueType;
};

export const RawCheckbox = ({
  dataType,
  disabled = false,
  displayValue = null,
  id,
  invalid = false,
  mode = 'checkbox',
  name,
  onChange,
  option,
  value = null,
}: RawCheckboxProps) => {
  if (formatters[dataType] === undefined) {
    throw new Error(`Unknown Schema Type: ${dataType}`);
  }
  const isChecked = formatters[dataType].in(value, option);
  const handleCheckClick = (e: SyntheticEvent) => {
    const checkedValue = formatters[dataType].out(!isChecked, option, value);
    onChange(checkedValue, e);
  };
  const displayVal =
    displayValue && typeof displayValue !== 'string' ? (
      <label>{displayValue}</label>
    ) : (
      displayValue
    );

  const CheckboxChunk = (
    <SCheckbox
      className={AttrsHelper.formatClassname(invalid && 'invalid')}
      checked={isChecked}
      disabled={disabled}
      id={id}
      label={displayVal}
      name={name}
      onChange={handleCheckClick}
    />
  );

  switch (mode) {
    case 'tile': {
      return (
        <Tile
          name={name}
          disabled={disabled}
          onClick={handleCheckClick}
          selected={isChecked}
        >
          {CheckboxChunk}
        </Tile>
      );
    }
    case 'pill': {
      return (
        <RawCheckboxPill
          name={name}
          disabled={disabled}
          onClick={handleCheckClick}
          selected={isChecked}
        >
          {CheckboxChunk}
        </RawCheckboxPill>
      );
    }
    default: {
      return (
        <CheckboxWrapper data-test={name}>{CheckboxChunk}</CheckboxWrapper>
      );
    }
  }
};
