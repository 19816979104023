import React from 'react';
import { shape, string, bool } from 'prop-types';
import styled from 'styled-components';
import { FormFeedback as RSFormFeedback } from 'reactstrap';

import { PALETTE } from '../../themes/constants';

const ErrorWrapper = styled(RSFormFeedback)`
  color: ${PALETTE.theme.danger};
  font-weight: bold;
  display: inline-block;
`;

export const isErroring = finalFormElementMeta =>
  !!(
    (finalFormElementMeta.error || finalFormElementMeta.submitError) &&
    finalFormElementMeta.touched
  );

export const FFError = ({ finalFormElementMeta, message }) => (
  <ErrorWrapper>
    {(finalFormElementMeta.error || finalFormElementMeta.submitError) &&
      finalFormElementMeta.touched && (
        <span>
          {message ||
            finalFormElementMeta.error ||
            finalFormElementMeta.submitError}
        </span>
      )}
  </ErrorWrapper>
);

FFError.defaultProps = {
  message: null,
};

FFError.propTypes = {
  finalFormElementMeta: shape({
    touched: bool,
    error: string,
    submitError: string,
  }).isRequired,
  message: string,
};
