import React from 'react';
import { number, string } from 'prop-types';

import { DataTestUtil } from '../../../utils/data-test-util';

export const StopwatchIcon = ({ alt, className, height, name, width }) => (
  <svg
    className={className}
    data-test={DataTestUtil.format(name)}
    focusable="false"
    height={height}
    role={alt ? 'img' : 'presentation'}
    viewBox="0 0 155 155"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    {alt && <title>{alt}</title>}
    <circle cx="77.5" cy="77.5" r="77.5" fill="#edf0f0" />
    <path fill="#fff" d="M39 81a39 39 0 1078 0 39 39 0 00-78 0" />
    <path
      fill="#7d8794"
      d="M78 77.6a3.4 3.4 0 100 6.8 3.4 3.4 0 000-6.8M78 88a7 7 0 110-14 7 7 0 010 14"
    />
    <path
      fill="#7d8794"
      d="M100.2 83H82.8c-1 0-.8-.9-.8-2s-.2-2 .8-2h17.4c1 0 1.8.9 1.8 2s-.8 2-1.8 2"
    />
    <path
      fill="#f15b35"
      d="M57 58a2 2 0 11-4 0 2 2 0 014 0M79 49a2 2 0 11-4 0 2 2 0 014 0M79 113a2 2 0 11-4 0 2 2 0 014 0M47 81a2 2 0 11-4 0 2 2 0 014 0M112 81a2 2 0 11-4 0 2 2 0 014 0M102 58a2 2 0 11-4 0 2 2 0 014 0M57 104a2 2 0 11-4 0 2 2 0 014 0M102 104a2 2 0 11-4 0 2 2 0 014 0"
    />
    <path
      fill="#7d8794"
      d="M67.1 27l-.1.7V36c0 1 .8 1.7 1.8 1.7h3.1V42h3.6v-6.1c0-1-.8-1.8-1.8-1.8h-3.1v-4.6h12.8v4.6h-3.2c-1 0-1.8.8-1.8 1.8V42H82v-4.4h3.2c1 0 1.8-.8 1.8-1.7v-8.2l-.1-.7zm-28.8 5a2 2 0 00-1.3.5l-6.5 6.6c-.7.7-.7 1.8 0 2.5l5 4.9c.3.3.7.5 1.2.5s1-.2 1.3-.5l2-2 3.6 3.5 2.4-2.5-3.6-3.5 2-2.1c.8-.7.8-1.8 0-2.5l-4.9-4.9c-.3-.3-.8-.5-1.2-.5zm77.1 0c-.3 0-.7.3-1 .5l-4.9 4.9c-.7.7-.7 1.8 0 2.5l2.6 2.6-3.1 3 2.4 2.5 3.1-3 1.5 1.5c.4.3.8.5 1.3.5s1-.2 1.3-.5l4.9-5c.7-.6.7-1.7 0-2.4l-6.5-6.6c-.3-.3-.8-.5-1.3-.5a1 1 0 00-.3 0zm-77.1 4.3l2.4 2.4-4 4-2.4-2.4zm77.4 0l4 4-2.4 2.4-4-4z"
    />
    <path
      fill="#acacaf"
      d="M77.5 44.5a37 37 0 100 74 37 37 0 000-74m0 77.5a40.5 40.5 0 110-81 40.5 40.5 0 010 81"
    />
    <path
      fill="#313944"
      d="M77.5 130C50.8 130 29 108 29 81.1a49.2 49.2 0 0134.6-46.8l1 3.4a45.6 45.6 0 00-32 43.4c0 25 20.1 45.4 44.9 45.4a45.4 45.4 0 0012.1-89l1-3.5a49 49 0 01-13.1 96"
    />
  </svg>
);

StopwatchIcon.propTypes = {
  alt: string,
  className: string,
  height: number,
  name: string,
  width: number,
};

StopwatchIcon.defaultProps = {
  alt: null,
  className: null,
  height: 155,
  name: 'STOPWATCH_ICON',
  width: 155,
};
