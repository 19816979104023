import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Gutter } from '@axiom/ui';

import { DefaultLayout } from '../layouts/DefaultLayout';
import ResetPassword from '../components/ResetPassword/ResetPassword';
import { FormResetPasswordStore } from '../stores/form-reset-password-store';
import { ErrorBlurb } from '../components/ErrorBlurb/ErrorBlurb';
import { Page } from '../components/Page/Page';

export const SetPasswordPage = () => {
  const [loadError, setLoadError] = useState(null);
  const { token } = useParams();
  useEffect(() => {
    FormResetPasswordStore.load(token).catch(e => setLoadError(e));
  }, [token]);
  return (
    <Page title="Axiom Law - Set Password">
      <DefaultLayout>
        <ResetPassword
          title={loadError ? 'Expired Link' : 'Set Your Password'}
          errorMessage={
            loadError && (
              <Gutter bottom="16px">
                <ErrorBlurb align="center" errorMessage={loadError.message} />
              </Gutter>
            )
          }
        />
      </DefaultLayout>
    </Page>
  );
};
