import React from 'react';
import styled from 'styled-components';
import { FormFieldProps as SemanticFieldProps } from 'semantic-ui-react';

import { FormField, FormFieldProps } from '../FormField/FormField';

import { RawRadio, RawRadioProps, RawRadioValueType } from './RawRadio';

const RadioRoot = styled.div`
  user-select: none;
`;

export type RadioProps<FormValue> = {
  description?: FormFieldProps<FormValue>['description'];
  disabled?: RawRadioProps['disabled'];
  displayValue?: RawRadioProps['displayValue'];
  id?: RawRadioProps['id'];
  label?: FormFieldProps<FormValue>['label'];
  mode?: RawRadioProps['mode'];
  name: RawRadioProps['name'];
  onChange?: RawRadioProps['onChange'];
  /**
   * Provide a value that represents radio as filled
   * For instance: {favoriteFood: steak}
   *
   * `<Radio
   *  name="favoriteFood"
   *  option="steak"
   *  />`
   *
   */
  option: RawRadioProps['option'];
  Tooltip?: FormFieldProps<FormValue>['Tooltip'];
};

export const Radio = <FormValue,>({
  description,
  disabled = false,
  displayValue,
  id,
  label,
  mode,
  name,
  onChange = v => v,
  option,
  Tooltip,
}: RadioProps<FormValue>) => {
  /**
   * In case id isn't passed in, and if this component shares the same data `name` with
   * another component, we can be assured the id is at least unique for
   * label=>form element connections within the DOM.
   *
   * Example:
   * Component1 option=Donut: foodChoice
   * Component2 option=Steak: foodChoice
   *
   * formattedId = id=foodChoiceDonut
   */
  const formattedId = id || `${name}-${option}`;
  return (
    <RadioRoot className="radio-root stretchable">
      <FormField
        {...{
          dataTest: `${name}-${option}`,
          description,
          disabled,
          id: formattedId,
          label,
          name,
          Tooltip,
        }}
        renderField={({
          value,
          setFieldValue,
          invalid,
        }: { value: RawRadioValueType } & SemanticFieldProps) => (
          <RawRadio
            disabled={disabled}
            displayValue={displayValue}
            id={formattedId}
            invalid={invalid}
            name={name}
            mode={mode}
            onChange={v => {
              onChange(v);
              setFieldValue(name, v);
            }}
            option={option}
            value={value}
          />
        )}
      />
    </RadioRoot>
  );
};
