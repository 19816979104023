import React from 'react';
import styled from 'styled-components';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

type HeaderType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'div'
  | 'span';

export interface CondensedHeaderProps {
  as?: HeaderType;
  children?: React.ReactNode;
  className?: string;
  name?: string;
  maxLines?: number;
}

interface H6Props {
  as: HeaderType;
  children: React.ReactNode;
  dataTest: string;
}

const H6 = ({ as, children, dataTest }: H6Props) =>
  React.createElement(as, { dataTest }, children);

const StyledH6 = styled(H6)``;

export const CondensedHeader = ({
  as = 'h6',
  children = null,
  className = '',
  name = null,
  maxLines = null,
}: CondensedHeaderProps) => (
  <StyledH6
    as={as}
    className={AttrsHelper.formatClassname(
      'condensed-header',
      maxLines && `max-lines-${maxLines}`,
      className
    )}
    data-test={name}
  >
    {children}
  </StyledH6>
);
