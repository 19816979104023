import { AjvUtil } from '../utils/ajv-util';
import { resetPassword, validateResetToken } from '../api/auth/auth';
import GenericStore from '../classes/generic-store';
import { ShowableError } from '../classes/showable-error';

const FormResetPasswordValidation = AjvUtil.compileSchema({
  required: ['token', 'password', 'passwordConfirm'],
  properties: {
    token: {
      title: 'Token',
      type: 'string',
      isNotEmpty: true,
    },
    password: {
      title: 'Password',
      type: 'string',
      isNotEmpty: true,
      meetsPasswordRequirement: true,
    },
    passwordConfirm: {
      title: 'Confirm Password',
      type: 'string',
      isNotEmpty: true,
      const: {
        $data: '1/password',
      },
    },
  },
});

class FormResetPasswordStoreClass extends GenericStore {
  load(token) {
    return this.setState(
      validateResetToken(token)
        .then(() => ({
          token,
        }))
        .catch(() => {
          throw new ShowableError(
            'That link has expired or has already been used.'
          );
        })
    );
  }

  validate(data) {
    return AjvUtil.formatValidationForFinalForm(
      FormResetPasswordValidation,
      data
    );
  }

  submit(formData) {
    return this.clearState(
      resetPassword(formData).catch(() => {
        const errorMsg =
          'An error occurred. Please try again or reach out to answers@axiomlaw.com.';
        throw new ShowableError(errorMsg);
      })
    );
  }
}

export const FormResetPasswordStore = new FormResetPasswordStoreClass();
